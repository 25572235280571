import * as React from "react";
import { Fragment } from "react";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import { FaRegCircleUser } from "react-icons/fa6";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import { Link, useNavigate } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineNotifications } from "react-icons/md";
import ListItemButton from "@mui/material/ListItemButton";
import { AppBar, Toolbar, Box, styled, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import { signOutMember } from "../../../redux/slices/memberSlice";

const navItems = [
  { text: "Home", link: pages.home.route },
  { text: "Appointment", link: pages.AppointmentBooking.route },
  { text: "History", link: pages.Transaction.route },
  { text: "Log Out", link: pages.home.route },
];

const StyledAppBar = styled(AppBar)({
  background: "linear-gradient(90deg, #ffffff, #ffffff)",
});

const MenuItems = styled("div")({
  display: "flex",
  alignItems: "center",
  "& a": {
    color: "#ffffff",
    fontWeight: 500,
    textDecoration: "none",
    fontSize: "16px",
    margin: "0 1rem",
    position: "relative",
    "&:hover": {
      color: "#ffde03",
      "&::before": {
        content: "''",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: "-2px",
        height: "3px",
        background: "#ffde03",
        transition: "width 0.3s ease",
        width: "100%",
      },
    },
  },
});

const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    backgroundColor: "rgb(255 255 255)",
    color: "#333",
    marginTop: "30px",
  },
});

export default function MenuNavbar(props: Props) {
  const { window } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      sx={{ textAlign: "center", marginTop: "0rem", padding: "1rem" }}
      onClick={handleDrawerToggle}
    >
      <List>
        {navItems.map((item) => (
          <Fragment key={item.text}>
            <ListItem
              disablePadding
              onClick={() => {
                navigate(item.link);
              }}
            >
              <ListItemButton
                onClick={() =>
                  item.text === "Log Out" && dispatch(signOutMember())
                }
              >
                <ListItemText
                  primary={
                    <Typography
                      component={Link}
                      sx={{
                        color:
                          sessionStorage.getItem("buttonBackground") ||
                          "rgb(84, 82, 238)",
                        fontWeight: 600,
                        textDecoration: "none",
                        fontSize: "1rem",
                        "&:hover": {
                          color:
                            sessionStorage.getItem("buttonBackground") ||
                            "rgb(57, 54, 239)",
                        },
                      }}
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleProfile = () => {
    if (!visitorData.ID) {
      navigate(pages.appointment.route + "login");
    } else {
      navigate(pages.Profile.route);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        position: "fixed",
        width: "100%",
        background: "white",
        zIndex: "9999",
      }}
    >
      <CssBaseline />
      <StyledAppBar position="static">
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon
                style={{
                  color:
                    sessionStorage.getItem("buttonBackground") || "#3936EF",
                }}
              />
            </IconButton>

            <Typography
              variant="h3"
              color={
                sessionStorage.getItem("buttonBackground") || "rgb(84, 82, 238)"
              }
              fontWeight={900}
              onClick={() => {
                navigate(pages.home.route);
              }}
            >
              Digipass
            </Typography>
          </Box>
          <MenuItems sx={{ display: { xs: "none", sm: "flex" } }}></MenuItems>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton color="inherit">
              <MdOutlineNotifications
                style={{
                  color:
                    sessionStorage.getItem("buttonBackground") || "#3936EF",
                  fontSize: "24px",
                }}
              />
            </IconButton>
            <IconButton color="inherit" onClick={() => handleProfile()}>
              <FaRegCircleUser
                style={{
                  color:
                    sessionStorage.getItem("buttonBackground") || "#3936EF",
                  fontSize: "24px",
                }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <Box component="nav">
        <StyledDrawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
        >
          {drawer}
        </StyledDrawer>
      </Box>
    </Box>
  );
}
