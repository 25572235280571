// import * as React from "react";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { border } from "@mui/system";
import { useEffect, useState } from "react";

const steps = [
  "Details",
  "Schedule Time",
  "Personal Details",
  "Add Members",
  "Review & Book",
];

const StageConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
    left: "calc(-50% + 6px)",
    right: "calc(50% + 6px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#3936EF", // Blue color for active step
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#3936EF", // Blue color for completed steps
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#bdbdbd", // Gray color for inactive steps
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const StageStepRoot = styled("div")(({ ownerState }) => ({
  display: "flex",
  height: 22,
  alignItems: "center",
  justifyContent: "center",
  "& .StageStepIcon-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: ownerState.active || ownerState.completed ? "#3936EF" : "",
    border:
      ownerState.active || ownerState.completed
        ? ""
        : "1px solid rgb(189, 189, 189)",
  },
}));

function StageIcon(props) {
  const { active, completed } = props;
  return (
    <StageStepRoot ownerState={{ active, completed }}>
      <div className="StageStepIcon-circle" />
    </StageStepRoot>
  );
}

export default function StageStepper({ stepperLevel }) {
  const [stage, setStage] = useState(stepperLevel); // Change this dynamically

  useEffect(() => {
    setStage(stepperLevel);
  }, [stepperLevel]);

  return (
    <Box sx={{ width: "100%", padding: "15px 0px" }}>
      <Stepper
        activeStep={stage}
        connector={<StageConnector />}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={StageIcon}
              sx={{
                "& .MuiStepLabel-label": {
                  color: index !== stage && "#9e9e9e",
                  fontWeight: index === stage ? "bold" : "normal",
                  fontSize: "14px",
                  "-webkit-text-stroke-width": ".45px",
                  fontSize: "12px",
                },
                "& .MuiStepLabel-root .Mui-completed": {
                  // color: "red",
                  // fontSize: "12px",
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "#9e9e9e",
                    "-webkit-text-stroke-width": ".45px",
                  },
                "& .MuiStepLabel-root .Mui-active": {
                  // color: "red !important",
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    // color: "#38a832",
                    "-webkit-text-stroke-width": ".45px",
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  // fill: "#0e64ab",
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
