import { Box } from "@mui/material";
import styled from "styled-components/macro";
import { useLocation, useOutlet } from "react-router-dom";

const Wrapper = styled(Box)`
  flex-grow: 1;
  z-index: -0;
`;

export default function MainContent() {
  const element = useOutlet();
  const location = useLocation();

  const heightPage = ["event/booking-verification"].filter((item) =>
    location.pathname.includes(item)
  );

  const marginPage = ["/profile"].filter((item) =>
    location.pathname.includes(item)
  );

  return (
    <Wrapper
      style={{
        ...(!marginPage.length && { marginTop: "56px" }),
        ...(heightPage.length && { height: "0" }),
      }}
    >
      {element}
    </Wrapper>
  );
}
