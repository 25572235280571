import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import DatePickerComponent from "../../custom fields/date dialog components/DatePicker";
import SwitchComponent from "../../custom fields/switch dialog components/SwitchComponent";
import TextFieldComponent from "../../custom fields/textfield dialog components/TextFieldComponent";
import DropDownComponent from "../../custom fields/dropdown dialog components/DropdownComponent";

export default function CustomField({
  title = false,
  formikProps,
  customFields,
  visitorDataIndex,
}) {
  return (
    <Fragment>
      {customFields.map((item, index) => {
        return (
          <Fragment>
            {title && (
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "900",
                  marginBottom: "7.5px",
                  textAlign: "start",
                }}
              >
                {item.tooltip}
              </Typography>
            )}
            <Grid item xs={12} sx={{ mt: index === 0 ? "0px" : "15px" }}>
              {item.control_id === "61dd2dfa1fb38be82e077ed7" && (
                <TextFieldComponent
                  name={`visitorData[${visitorDataIndex}].${item.name}`}
                  fieldDetails={item}
                  formikProps={formikProps}
                  disabled={!item.editable}
                />
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: index === 0 ? "0px" : "15px" }}>
              {item.control_id === "61dd2dfa1fb38be82e077ed9" && (
                <DatePickerComponent
                  formikProps={formikProps}
                  fieldDetails={item}
                  disabled={!item.editable}
                />
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: index === 0 ? "0px" : "15px" }}>
              {item.control_id === "61dd2dfa1fb38be82e077ed8" && (
                <DropDownComponent
                  formikProps={formikProps}
                  fieldDetails={item}
                  disabled={!item.editable}
                />
              )}
            </Grid>

            <Grid item xs={12} sx={{ mt: index === 0 ? "0px" : "15px" }}>
              {item.control_id === "61e13a0bf2c60729b06385f3" && (
                <SwitchComponent
                  formikProps={formikProps}
                  fieldDetails={item}
                  disabled={!item.editable}
                />
              )}
            </Grid>
          </Fragment>
        );
      })}
    </Fragment>
  );
}
