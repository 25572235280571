const pages = {
  home: {
    ID: "home",
    route: "/",
  },

  eventListPage: {
    ID: "EventList",
    route: "/event-list",
  },

  appointment: {
    ID: "appointment",
    route: "/event/",
  },

  TrackAppointment: {
    ID: "TrackAppointment",
    route: "/track-appointment",
  },

  ContactUs: {
    ID: "ContactUs",
    route: "/contact-us",
  },

  AboutUs: {
    ID: "AboutUs",
    route: "/about-us",
  },

  previewVideo: {
    ID: "previewVideo",
    route: "/event/previewVideo",
  },

  checkLiveliness: {
    ID: "liveliness",
    route: "/event/booking-verification",
  },

  Success: {
    ID: "Success",
    route: "/success",
  },

  Verification: {
    ID: "Verification",
    route: "/event/verification",
  },

  CampusVerification: {
    ID: "CampusVerification",
    route: "/campus/verification",
  },

  AppointmentVerification: {
    ID: "AppointmentVerification",
    route: "/appointment/verification",
  },

  AadharverificaionDetails: {
    ID: "AadharverificaionDetails",
    route: "/aadhar-verificaion-details",
  },

  AadharverificaionSuccess: {
    ID: "AadharverificaionSuccess",
    route: "/aadhar-verificaion-success",
  },

  DrivingLicenceDetails: {
    ID: "DrivingLicenceDetails",
    route: "/driving-licence-details",
  },

  UploadDocument: {
    ID: "UploadDocument",
    route: "/upload-document",
  },

  EventBooking: {
    ID: "EventBooking",
    route: "/event/event-booking",
  },

  AppointmentBooking: {
    ID: "AppointmentBooking",
    route: "/appointment/appointment-booking",
  },

  EventBookingDetails: {
    ID: "EventBookingDetails",
    route: "/event/event-booking-details",
  },

  AppointmentBookingDetail: {
    ID: "AppointmentBooking",
    route: "/appointment-booking-details",
  },

  BioVerificaion: {
    ID: "AppointmentBooking",
    route: "/bio-verification",
  },

  // MainGateEntryWithFrontCamera: {
  //   ID: "MainGateEntryWithFrontCamera",
  //   route: "/main-gate-entry-front",
  // },

  MainGateEntryWithBackCamera: {
    ID: "MainGateEntryWithBackCamera",
    route: "/main-gate-entry-back",
  },

  MainGateEntrySuccess: {
    ID: "MainGateEntrySuccess",
    route: "/main-gate-entry-success",
  },

  ManualEntry: {
    ID: "ManualEntry",
    route: "/manual-entry",
  },

  ManualVerification: {
    ID: "ManualVerification",
    route: "/manual-verification",
  },

  ResendNotification: {
    ID: "ResendNotification",
    route: "/resend-notification",
  },

  Consent: {
    ID: "Consent",
    route: "/event/consent",
  },

  EventSummary: {
    ID: "EventSummary",
    route: "/event/event-summary/",
  },

  AppointmentSummary: {
    ID: "AppointmentSummary",
    route: "/appointment/Appointment-summary",
  },

  BoardingPass: {
    ID: "BoardingPass",
    route: "/boarding-pass",
  },

  ShopsMore: {
    ID: "ShopsMore",
    route: "/shops-more",
  },

  Shops: {
    ID: "Shops",
    route: "/shops",
  },

  Hotels: {
    ID: "Hotels",
    route: "/hotels",
  },

  Shop: {
    ID: "Shops",
    route: "/shop",
  },

  Hotel: {
    ID: "Hotel",
    route: "/hotel",
  },

  Transaction: {
    ID: "Transaction",
    route: "/transaction",
  },

  Profile: {
    ID: "Profile",
    route: "/profile",
  },

  CampusSummary: {
    ID: "CampusSummary",
    route: "/campus/campus-summary",
  },

  HotelBookingPage: {
    ID: "HotelBookingPage",
    route: "/hotel-booking",
  },

  AddHotelBookingPage: {
    ID: "AddHotelBookingPage",
    path: "/add-hotel-booking",
  },

  HotelSummary: {
    ID: "HotelSummary",
    path: "/hotel/hotel-summary/",
  },

  EventPage: {
    ID: "Event",
    path: "/event",
  },

  InviteUserPage: {
    ID: "InviteUser",
    path: "/invite-user",
  },

  HotelVerification: {
    ID: "HotelVerification",
    route: "/hotel/verification",
  },

  HotelConsent: {
    ID: "HotelConsent",
    route: "/hotel/consent",
  },
};

export default pages;
