import * as Yup from "yup";
import { useFormik } from "formik";
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  TextField,
  OuterBox,
  SubheaderSection,
  LoadingButton,
  MandatoryNote,
  CaptchaDisplay,
  ButtonContainer,
} from "./../../../components";

import pages from "./../../../constants/pages";
import convertNormalDate from "./../../../utils/convertNormalDate";
import { useAlert, useAxiosV2, useAxios } from "./../../../hooks/";

import { MdDocumentScanner } from "react-icons/md";

import AadharCardBack from ".././assests/AadharCard.svg";
import EditButton from ".././assests/edit.svg";

const MyComponent = ({
  token,
  bookingType,
  visitorId,
  setVerificationFailedCount,
  setVerification,
  OuterBox2,
  OuterPaper,
  digipassDataKey,
}) => {
  const axios = useAxios();
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });

  const { alert } = useAlert();

  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [ocrLoading, setOCRLoading] = useState(false);
  const [CaptchaError, setCaptchaError] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  const type = queryParams.get("type");
  const popup = queryParams.get("popup");
  const eventId = queryParams.get("event_id");
  const campusId = queryParams.get("campus_id");
  const bookingId = queryParams.get("booking_id");
  const officerID = queryParams.get("officer_id");
  const studentId = queryParams.get("student_id");
  const employeeId = queryParams.get("employee_id");
  const appointmentID = queryParams.get("appointment_id");
  const docVerify = queryParams.get("doc_verify");
  const hotelID = queryParams.get("hotel_id");
  const docFace = queryParams.get("doc_face");

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCaptcha = "";
    for (let i = 0; i < 6; i++) {
      randomCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(randomCaptcha);
  };

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleGoBack = () => {
    setVerification(false);
  };

  function handleVerifyDrivingLicence(values) {
    if (values.captchaInput !== captcha) {
      setCaptchaError(true);
      return;
    }

    setLoading(true);
    axiosAadhar({
      url: "/verify/dl/",
      method: "POST",
      authToken: token,
      data: {
        dl_no: values.dl_number,
        dob: convertNormalDate(values.dob),
        consent: "Y",
        consent_text: "Hello This is Metawing ",
      },
    }).then((response) => {
      if (response.response_code === "101") {
        const name = response.result.name;
        const nameParts = name.split(" ");
        const first_name = nameParts[0];
        let last_name = nameParts.slice(1).join(" ");
        if (last_name.trim() === "") {
          last_name = first_name;
        }

        var payload = {
          doc_type: 2,
          dlNo: response.result.dlNumber,

          dob: response.result.dob,
          address:
            response.result?.address[0]?.completeAddress ||
            "" + response.result?.address[0]?.state ||
            "" + response.result?.address[0]?.pin,
          visitor_id: visitorId,
          booking_type: "event",
          first_name: first_name,
          last_name: last_name,

          doc_face_image: response.result.img,
        };

        const payloadString = JSON.stringify(payload);

        setLoading(false);

        dispatch(
          alert({
            type: "success",
            message: "DL Verified successfully.",
          })
        );

        if (docVerify) {
          let faceData = digipassDataKey.filter((item) => {
            return localStorage.getItem(item);
          });

          axios({
            url: `event/verify/booking`,
            method: "POST",
            disableRedirect: true,
            data: {
              ...{
                ...(!bookingId &&
                  !hotelID && {
                    booking_id:
                      bookingId || sessionStorage.getItem("bookingId"),
                  }),
                ...(appointmentID &&
                  appointmentID != -1 && {
                    appointment_id:
                      appointmentID || sessionStorage.getItem("appointmentID"),
                  }),
                ...(officerID &&
                  appointmentID != -1 && { officer_id: officerID }),
                ...(appointmentID && {
                  slot_ids: location?.state?.details.slot_id,
                }),
                ...(appointmentID && {
                  appointment_date: location?.state?.details.appointment_date,
                }),
                ...(campusId && {
                  campus_id: campusId,
                  ...(studentId && {
                    student_id: studentId,
                  }),
                  ...(employeeId && {
                    employee_id: employeeId,
                  }),
                }),
              },

              name: " ",
              aadhar_data: payload,
              visitor_id: visitorId,
              face_data: localStorage.getItem(faceData[0]),
              document_verification: true,
              is_encrypted_face: true,
              phone_number:
                visitorData.phoneNumber ||
                sessionStorage.getItem("mobileNumber"),
            },
          })
            .then((response) => {
              if (response.status) {
                let key = visitorId + "_face&docs";

                localStorage.setItem(key, response.data.encrypted_face_data);
                localStorage.setItem(`userPayload_${visitorId}`, payloadString);

                if (appointmentID == -1) {
                  navigate(pages.Profile.route);
                } else {
                  let url =
                    !appointmentID && !studentId
                      ? "event/visitor/check-booking" +
                        "?event_id=" +
                        eventId +
                        (visitorId ? "&visitor_id=" + visitorId : "") +
                        (!visitorId ? "&booking_id=" + bookingId : "")
                      : studentId
                      ? `/attendance/campus/check-campus-booking?student_id=${studentId}`
                      : "appointment/appointment/check-appointment";

                  let method =
                    !appointmentID && !studentId
                      ? "GET"
                      : studentId
                      ? "GET"
                      : "POST";

                  let data = !appointmentID
                    ? {}
                    : studentId
                    ? {}
                    : {
                        ...(officerID && { officer_id: officerID }),
                        ...(appointmentID && {
                          appointment_id: appointmentID,
                        }),
                      };

                  axios({
                    url: url,
                    disableRedirect: true,
                    method: method,
                    data: data,
                  }).then((response) => {
                    if (response.status) {
                      if (eventId) {
                        if (type == "primaryUser") {
                          navigate(
                            pages.InviteUserPage.path +
                              "/" +
                              response.data[0].event_id +
                              "/" +
                              response.data[0].visitor_id,
                            {
                              state: {
                                userData: response.data,
                              },
                            }
                          );
                        } else {
                          navigate(
                            pages.EventSummary.route +
                              response.data[0].event_id +
                              "/" +
                              response.data[0].visitor_id,
                            {
                              state: {
                                userData: response.data,
                              },
                            }
                          );
                        }
                      } else if (studentId) {
                        navigate(
                          pages.CampusSummary.route +
                            `?student_id=${studentId}`,
                          {
                            state: {
                              userData: response.data,
                            },
                          }
                        );
                      } else if (
                        appointmentID !== -1 &&
                        Boolean(appointmentID)
                      ) {
                        navigate(
                          pages.AppointmentSummary.route +
                            `?officer_id=${officerID}&appointment_id=${appointmentID}`,
                          {
                            state: {
                              userData: response.data,
                            },
                          }
                        );
                      }
                    }
                  });
                }
              }
            })
            .catch((error) => {
              console.error("Error in Aws upload:", error);
            });
        } else {
          localStorage.setItem(`userPayload_${visitorId}`, payloadString);

          navigate(
            pages.checkLiveliness.route +
              (bookingId ? "/?booking_id=" + bookingId : "") +
              (appointmentID ? "/?appointment_id=" + appointmentID : "") +
              (eventId ? "&event_id=" + eventId : "") +
              (officerID ? "&officer_id=" + officerID : "") +
              (campusId ? "?campus_id=" + campusId : "") +
              (studentId ? "&student_id=" + studentId : "") +
              (employeeId ? "&employee_id=" + employeeId : "") +
              (type ? "&type=" + type : "") +
              (popup ? "&popup=" + popup : "") +
              (hotelID ? "&hotel_id=" + hotelID : "") +
              (visitorId ? "&visitor_id=" + visitorId : ""),
            {
              state: {
                visitor_id: visitorId,
                id: eventId,
                appointmentData: location?.state?.details || {},
              },
            }
          );
        }
      } else {
        setLoading(false);
        setVerificationFailedCount((prevCount) => prevCount + 1);
        dispatch(
          alert({
            type: "error",
            message: "Details not found, please enter the correct details.",
          })
        );
      }
    });
  }

  const onSubmit = async (values) => {
    try {
      handleVerifyDrivingLicence(values);
    } catch (error) {
      dispatch(
        alert({
          type: "error",
          message: error,
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    dl_number: Yup.string().required("Dl Number is required"),
    dob: Yup.string().required("Dob is required"),
    captchaInput: Yup.string()
      .required("Captcha is required")
      .test("captcha-match", "Captcha does not match", function (value) {
        return value === captcha;
      }),
  });

  const formik = useFormik({
    initialValues: {
      dl_number: "",
      dob: "",
      captchaInput: "",
    },
    validationSchema,
    onSubmit,
  });

  {
    /* <Grid item container>
  <Grid item container justifyContent={"center"}>
    <Grid
      item
      container
      md={12}
      sl={6}
      sx={{
        justifyContent: "start",
        alignContent: "start",
      }}
    >
      <OuterBox2>
        <OuterPaper
          style={{ padding: "0px" }}
          elevation={3}
        ></OuterPaper>
      </OuterBox2>
    </Grid>
  </Grid>
  </Grid> */
  }

  return (
    <Fragment>
      <Grid item container>
        <Grid item container justifyContent={"center"}>
          <Grid
            item
            container
            md={12}
            sl={6}
            sx={{
              justifyContent: "start",
              alignContent: "start",
            }}
          >
            <OuterBox2>
              <OuterPaper style={{ padding: "0px" }} elevation={3}>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "35vh",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      position: "relative",
                      zIndex: "999999",
                      top: "47%",
                      left: "3%",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={900}
                      sx={{ marginTop: "7.5px" }}
                    >
                      {"Verify Profile Using Driving Licence"}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{ marginTop: "7.5px", color: "#00000099" }}
                    >
                      {"Please Enter your Driving Licence Details"}
                    </Typography>
                  </Grid>
                  <img
                    style={{
                      height: "35vh",
                      position: "absolute",
                      top: "0px",
                    }}
                    src={AadharCardBack}
                  />
                </div>
              </OuterPaper>
            </OuterBox2>
          </Grid>
        </Grid>
      </Grid>

      <Grid item padding={"25px"} alignContent={"center"}>
        <form>
          <Typography
            variant="h5"
            sx={{ fontWeight: "700", marginBottom: "17.5px" }}
          >
            Enter Driving Licence Number
            <span
              style={{
                color: "red",
                fontWeight: "700",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            fullWidth
            sx={{ marginBottom: "16px" }}
            variant="outlined"
            label={
              <Fragment>
                <Grid container>
                  <Grid item xs={10}>
                    Enter Driving Licence Number
                    {/* <span
                      style={{
                        color: "red",
                        float: "right",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span> */}
                  </Grid>
                </Grid>
              </Fragment>
            }
            placeholder="Enter Driving Licence Number"
            name="dl_number"
            value={formik.values.dl_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.dl_number && Boolean(formik.errors.dl_number)}
            InputProps={{
              endAdornment: (
                <Fragment>
                  <Tooltip title="Upload Document">
                    <input
                      accept="image/*,application/pdf"
                      style={{ display: "none" }}
                      id="file-upload"
                      type="file"
                      onChange={(event) => {
                        const fil = event.target.files[0];

                        setOCRLoading(true);

                        const data = new FormData();
                        data.append("file", fil);

                        axios({
                          url: "event/govt-api/ocr",
                          method: "POST",
                          data: data,
                          disableRedirect: true,
                        }).then((response) => {
                          formik.setFieldValue(
                            "dl_number",
                            response.data?.data?.number || ""
                          );
                          setOCRLoading(false);

                          if (!response.data?.data?.number) {
                            dispatch(
                              alert({
                                type: "error",
                                message: "Please share the right documents",
                              })
                            );
                          }
                        });
                      }}
                    />
                    <label htmlFor="file-upload">
                      {!ocrLoading ? (
                        <MdDocumentScanner
                          style={{
                            color: "#3936EF",
                            fontSize: "35px",
                            background: "white",
                            borderRadius: "7px",
                            padding: "3px 5px",
                            width: "100%",
                            cursor: "pointer",
                            // border: "1px solid rgb(161 130 74 / 55%)",
                          }}
                        />
                      ) : (
                        <CircularProgress
                          sx={{ color: "#3936EF", fontSize: "35px" }}
                        />
                      )}
                    </label>
                  </Tooltip>
                </Fragment>
              ),
            }}
          />

          <Grid item xs={12}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "700", marginBottom: "17.5px" }}
            >
              Select Date Of Birth
              <span
                style={{
                  color: "red",
                  fontWeight: "700",
                }}
              >
                *
              </span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  label={<>Select Date Of Birth</>}
                  autoComplete="off"
                  placeholder="Select Date Of Birth"
                  value={formik.values.dob}
                  onBlur={formik.handleBlur}
                  error={formik.touched.date && Boolean(formik.errors.dob)}
                  onChange={(newValue) => formik.setFieldValue("dob", newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={"dob"}
                      onBlur={formik.handleBlur}
                      error={formik.touched.dob && Boolean(formik.errors.dob)}
                      value={formik.values.dob}
                    />
                  )}
                  inputFormat="dd/MM/yyyy" // Set the desired date format here
                  maxDate={new Date()}
                />
              </FormControl>
            </LocalizationProvider>
          </Grid>

          <Grid container>
            <Grid item xs={7}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "700", marginTop: "17.5px" }}
              >
                Enter Captcha
                <span
                  style={{
                    color: "red",
                    fontWeight: "700",
                  }}
                >
                  *
                </span>
              </Typography>

              <TextField
                label={<Fragment>Enter Captcha</Fragment>}
                fullWidth
                margin="normal"
                variant="outlined"
                placeholder="Enter Captcha"
                name="captchaInput"
                value={formik.values.captchaInput}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.captchaInput &&
                  Boolean(formik.errors.captchaInput)
                }
                helperText={formik.errors.captchaInput}
                inputProps={{
                  maxLength: 6,
                }}
                autoComplete="off"
              />
            </Grid>

            <Grid xs={5} sx={{ alignSelf: "end", padding: "10px 15px" }}>
              <CaptchaDisplay
                captcha={captcha}
                generateCaptcha={generateCaptcha}
              />
            </Grid>
          </Grid>

          <Grid container>
            <LoadingButton
              loading={loading}
              onClick={formik.handleSubmit}
              style={{ margin: "10px 0px" }}
            >
              Verify DL
            </LoadingButton>

            {/* <LoadingButton onClick={handleGoBack} backgroundColor="gray">
              Back
            </LoadingButton> */}
          </Grid>

          {/* <MandatoryNote /> */}
        </form>
      </Grid>
    </Fragment>
  );
};

export default MyComponent;
