import React from "react";
import { Typography, Tooltip, Box } from "@mui/material";
import refresh from "./../../src/pages/home components/assets/refresh.svg";

const CaptchaDisplay = ({ captcha, generateCaptcha }) => (
  <Box
    sx={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "space-between",
      my: 2,
    }}
  >
    <Typography
      variant="h5"
      component="strong"
      sx={{
        userSelect: "none",
        background: "#EDEDED",
        border: "1px solid #EDEDED",
        borderRadius: "4px",
        padding: "10px",
        color: " #00000099",
      }}
    >
      {captcha}
    </Typography>
    <Tooltip title="Refresh Captcha">
      <img
        src={refresh}
        alt="Refresh Captcha"
        onClick={generateCaptcha}
        style={{
          cursor: "pointer",
          paddingLeft: "5px",
          width: "35px",
          height: "auto",
        }}
      />
    </Tooltip>
  </Box>
);

export default CaptchaDisplay;
