import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
// import Lock from "./assests/lock.png";
import { LoadingButton } from "../../components";
import { RiDeleteBin6Line } from "react-icons/ri";
import { fontSize, width } from "@mui/system";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "transparent",
    width: "calc(100% - 15px)",
    margin: "15px",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  textAlign: "start",
  alignContent: "center",
  alignItems: "center",
  borderRadius: "12.5px",
  background: "rgb(255, 255, 255)",
  padding: "25px",
  width: "100%",
}));

const DeleteDependentDialog = ({
  deleteDependentPopUp,
  handleDeleteDependent,
  setDeleteDependentPopUp,
}) => {
  return (
    <StyledDialog
      open={deleteDependentPopUp}
      onClose={() => setDeleteDependentPopUp(false)}
      maxWidth="sm"
      fullWidth
    >
      <Grid container sx={{ background: "transparent", padding: "10px 0px" }}>
        <Grid item container justifyContent={"end"}>
          <CloseIcon
            sx={{
              fontSize: "35px",
              padding: "4px",
              color: "white",
              borderRadius: "50%",
              background: "#FFFFFF38",
            }}
            onClick={() => setDeleteDependentPopUp(false)}
          />
        </Grid>
      </Grid>

      <DialogContent sx={{ padding: "5px 0px" }}>
        <ContentBox>
          <RiDeleteBin6Line
            style={{
              width: "55px",
              height: "55px",
              border: "1px solid #FDE9E9",
              background: "#FDE9E9",
              borderRadius: "50%",
              padding: "16px",
              color: "#942D2D",
              margin: "0px 0px 10px 0px",
            }}
          />
          <Typography
            variant="h6"
            fontWeight={700}
            textAlign={"left"}
            sx={{
              "-webkit-text-stroke-width": "0.1px",
              fontSize: "20px",
              marginBottom: "5px",
            }}
          >
            Delete Dependant?
          </Typography>
          <Grid container direction="column" alignItems="center">
            <Typography
              variant="h6"
              fontWeight={400}
              textAlign={"left"}
              sx={{ marginTop: 2, color: "#111111CC", marginBottom: "20px" }}
            >
              You’re about to delete the dependant? Are you sure?
            </Typography>
          </Grid>

          <Grid container justifyContent={"end"}>
            <LoadingButton
              onClick={() => {
                setDeleteDependentPopUp(false);
              }}
              style={{
                width: "100%",
                marginTop: "20px",
                padding: "12px 16px",
                background: "white",
                color: "#3936EF",
                border: "0.6px solid #0000001A",
                width: "45%",
                fontSize: "14px",
              }}
            >
              No, Cancel
            </LoadingButton>

            <LoadingButton
              onClick={() => {
                handleDeleteDependent();
              }}
              style={{
                width: "100%",
                marginTop: "20px",
                padding: "12px 16px",
                width: "45%",
                marginLeft: "10px",
                fontSize: "14px",
              }}
            >
              Yes, Delete
            </LoadingButton>
          </Grid>
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default DeleteDependentDialog;
