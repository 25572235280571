import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Tooltip,
  Divider,
} from "@mui/material";
import styled from "styled-components";
import { Fragment, useRef } from "react";
import { GiCheckMark } from "react-icons/gi";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import { LoadingButton } from "./../../components";
import { IoIosInformationCircleOutline } from "react-icons/io";

import Error from "../Error/Error";
import Instruction from "./Instruction";
import FaceVerification from "./assests/FaceVerification.svg";
import CameraStartPopUp from "./CameraStartPopUp";

const OuterBox2 = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  // padding: 0px 17px;
  box-sizing: border-box;
  height: 100%;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding: 20px;
  min-height: 20vh;
`;

const OuterPaper2 = styled(Paper)`
  width: 100%;
  // padding: 0px 20px 20px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
  position: relative;
`;

export default function UploadDocument() {
  const axios = useAxios();
  const navigate = useNavigate();

  const [mimeType, setMimeType] = useState("video/webm;codecs=vp8,opus");
  const axiosDigipass = useAxios({ baseURL: "digipass" });

  const location = useLocation();
  const { visitor_id, id } = location.state || {};

  const queryParams = new URLSearchParams(location.search);
  const bookingId = queryParams.get("booking_id");
  const eventId = queryParams.get("event_id");
  const visitorId = queryParams.get("visitor_id");
  const appointmentID = queryParams.get("appointment_id");
  const officerID = queryParams.get("officer_id");
  const campusId = queryParams.get("campus_id");
  const studentId = queryParams.get("student_id");
  const employeeId = queryParams.get("employee_id");
  const digiLockerID = queryParams.get("id");
  const type = queryParams.get("type");
  const popup = queryParams.get("popup");
  const doc_face = queryParams.get("doc_face");
  const hotelID = queryParams.get("hotel_id");

  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [cameraStartPopUp, setCameraStartPopUp] = useState(false);
  const [eventDetail, setEventDetail] = useState({});

  useEffect(() => {
    if (eventId) {
      sessionStorage.setItem("eventID", eventId);

      axiosDigipass({
        url: "admin/events/event-details?event_id=" + eventId,
        method: "GET",
        customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
      }).then((response) => {
        if (response.status) {
          response?.data?.button_color &&
            sessionStorage.setItem("buttonColor", response.data.button_color);
          response?.data?.background_color &&
            sessionStorage.setItem(
              "buttonBackground",
              response.data.background_color
            );
          setEventDetail(response.data);
        }
      });
    }

    if (window.navigator.userAgent.indexOf("Windows") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else if (window.navigator.userAgent.indexOf("Mac OS") !== -1) {
      setMimeType("video/mp4");
    } else if (window.navigator.userAgent.indexOf("Linux") !== -1) {
      setMimeType("video/webm;codecs=vp8,opus");
    } else {
      setMimeType("video/webm;codecs=vp8,opus");
    }

    if (Boolean(bookingId)) {
      // axiosDigipass({
      //   url: "event/verify/booking-status/",
      //   method: "POST",
      //   data: { booking_id: bookingId },
      //   disableRedirect: true,
      // }).then((response) => {
      //   if (response?.status) {
      //     !response.data.is_valid && setError(response.message.displayMessage);
      //   } else {
      //     setError(response?.displayMessage);
      //     setErrorOpen(true);
      //   }
      // });
    }
  }, []);

  const [startRecording, setStartRecording] = useState(false);

  const verificationContent = startRecording
    ? ""
    : "Face verification is required to confirm your identity by matching your live image with the one associated with your Aadhaar record";

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [stream, setStream] = useState(null);

  const localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorId);
  });

  useEffect(() => {
    let faceData = digipassDataKey.filter((item) => {
      return localStorage.getItem(item);
    });

    if (popup && faceData.length > 0) {
      window.close();
    }
  }, []);

  useEffect(() => {
    if (digiLockerID) {
      axios({
        url: "event/govt-api/get-user-document",
        method: "POST",
        data: {
          document_type: "aadhar",
          request_id: digiLockerID,
        },
      }).then((response) => {
        if (response.status) {
          const name = response.data.aadhaar.name;
          const nameParts = name.split(" ");
          const first_name = nameParts[0];
          let last_name = nameParts.slice(1).join(" ");
          if (last_name.trim() === "") {
            last_name = first_name;
          }

          let payload = {
            doc_type: 1,
            booking_type: "event",
            aadharNo: response.data.aadhaar.maskedNumber,
            visitor_id: visitorId,
            first_name: first_name,
            last_name: last_name,
            gender: response.data.aadhaar.gender,
            dob: response.data.aadhaar.dateOfBirth,
            address: response.data.aadhaar.address,
            doc_face_image: response.data.aadhaar.photo,
          };

          payload.is_verified = 1;
          const payloadString = JSON.stringify(payload);
          localStorage.setItem("userPayload", payloadString);
        }
      });
    }
  }, []);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 620,
          zoom: true,
        },
        audio: false,
      });
      setStream(stream);
      videoRef.current.srcObject = stream;
    } catch (error) {
      if (error.name === "NotReadableError") {
        console.log(
          "NotReadableError: The camera might be in use or not available"
        );
        alert(
          "Unable to access the camera. Please ensure it's not in use by another application and refresh the page."
        );
        window.location.reload();
      } else if (error.name === "OverconstrainedError") {
        console.log(
          "OverconstrainedError: The constraints don't match any installed camera"
        );
        alert("No suitable camera found. Please check your camera settings.");
      } else {
        alert(`Error accessing camera: ${error.message}`);
      }
    }
  };

  const stopCamera = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null); // or streamRef.current = null;
    }
  };

  const takePhoto = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (
      (canvas.width >= 150 || canvas.width <= 300) &&
      (canvas.height >= 600 || canvas.height <= 1200)
    ) {
      if (video && canvas) {
        const context = canvas.getContext("2d");

        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const captureSize = 350;
        const sourceX = (videoWidth - captureSize) / 2;
        const sourceY = (videoHeight - captureSize) / 2;

        context.drawImage(
          video,
          // sourceX,
          // sourceY,
          // captureSize,
          // captureSize,
          0,
          0,
          canvas.width,
          canvas.height
        );

        const imageDataURL = canvas.toDataURL("image/jpeg", 0.5);

        localStorage.setItem("image", imageDataURL);

        const userDocData = JSON.parse(
          localStorage.getItem(`userPayload_${visitorId}`)
        );

        if (userDocData?.doc_face_image && doc_face == false) {
          axios({
            url: "event/verify/face",
            method: "POST",
            data: {
              doc_face_image: userDocData?.doc_face_image,
              captured_face_image: imageDataURL.replace(
                /^data:image\/[a-z]+;base64,/,
                ""
              ),
            },
          }).then((response) => {
            if (response.status) {
              navigate(pages.previewVideo.route, {
                state: {
                  blob: imageDataURL || "",
                  visitor_id: visitor_id || visitorId || "",
                  bookingId: bookingId || "",
                  officerID: officerID || "",
                  appointmentID: appointmentID || "",
                  eventId: eventId || sessionStorage.getItem("eventID"),
                  matchPercentage: response.data?.match_percentage || "",
                  appointmentData: location.state?.appointmentData || "",
                  campusId: campusId || "",
                  studentId: studentId || "",
                  employeeId: employeeId || "",
                  digiLockerID: digiLockerID || "",
                  type: type || "",
                  popup: popup || "",
                  hotelID: hotelID || "",
                },
              });
              stopCamera();
            }
          });
        } else {
          navigate(pages.previewVideo.route, {
            state: {
              blob: imageDataURL || "",
              visitor_id: visitor_id || visitorId || "",
              bookingId: bookingId || "",
              officerID: officerID || "",
              appointmentID: appointmentID || "",
              eventId: eventId || sessionStorage.getItem("eventID"),
              matchPercentage:
                imageDataURL.replace(/^data:image\/[a-z]+;base64,/, "") || "",
              appointmentData: location.state?.appointmentData || "",
              campusId: campusId || "",
              studentId: studentId || "",
              employeeId: employeeId || "",
              digiLockerID: digiLockerID || "",
              type: type || "",
              popup: popup || "",
              hotelID: hotelID || "",
              docFace: doc_face || "",
            },
          });
          stopCamera();
        }
      }
    }
  };

  return (
    <Fragment>
      <Fragment>
        {!startRecording && (
          <Grid item container>
            <Grid item container justifyContent={"center"}>
              <Grid
                item
                container
                md={12}
                sl={6}
                sx={{
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <OuterBox2>
                  <OuterPaper style={{ padding: "0px" }} elevation={3}>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                        height: "35vh",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          position: "relative",
                          zIndex: "999999",
                          top: "47%",
                          left: "3%",
                        }}
                      >
                        <Typography
                          variant="h3"
                          fontWeight={900}
                          sx={{ marginTop: "7.5px" }}
                        >
                          {" "}
                          Face Verification{" "}
                          <Tooltip title="Instruction">
                            <IoIosInformationCircleOutline
                              style={{
                                verticalAlign: "middle",
                                color: "#AAA9F3",
                              }}
                              onClick={() => setOpen(true)}
                            />
                          </Tooltip>
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={500}
                          sx={{ marginTop: "7.5px", color: "#00000099" }}
                        >
                          To confirm your identity, we will match your live
                          image with your Aadhaar records.
                        </Typography>
                      </Grid>
                      <img
                        style={{
                          // width: "100%",
                          height: "35vh",
                          position: "absolute",
                          // left: "-25vw",
                          top: "0px",
                        }}
                        src={FaceVerification}
                      />
                    </div>
                  </OuterPaper>
                </OuterBox2>
              </Grid>
            </Grid>
          </Grid>
        )}

        <form style={{ ...(startRecording && { height: "100%" }) }}>
          {!startRecording ? (
            <React.Fragment>
              <Grid item container>
                <Grid item container justifyContent={"center"}>
                  <Grid
                    item
                    container
                    md={12}
                    sl={6}
                    sx={{
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <OuterBox2>
                      <OuterPaper elevation={3}>
                        <Grid
                          container
                          sx={{
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Divider
                            sx={{
                              width: "20%",
                              height: ".95px",
                              background: "#0000001A",
                            }}
                          />
                          <Typography
                            variant="h5"
                            fontWeight={400}
                            sx={{
                              textAlign: "center",
                              color: "#00000080",
                            }}
                          >
                            Before you Begin
                          </Typography>
                          <Divider
                            sx={{
                              width: "20%",
                              height: ".95px",
                              background: "#0000001A",
                            }}
                          />
                        </Grid>

                        <div style={{ marginTop: "10px" }}>
                          <div>
                            <List>
                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "25px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Make sure your camera, microphone, and location services are on."
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>

                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Check your internet connection is stable."
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Sit in a well-lit area, avoiding backlighting."
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "25px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Look directly into the camera, with your face clearly visible."
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>
                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Use a live video feed, not a static photo"
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>

                              <ListItem sx={{ padding: "2px" }}>
                                <GiCheckMark
                                  style={{
                                    color: "#211FA2",
                                    fontSize: "18px",
                                    marginRight: "10px",
                                  }}
                                />

                                <ListItemText
                                  primary="Ensure only your face is in the camera frame."
                                  sx={{ fontWeight: "700 !important" }}
                                />
                              </ListItem>
                            </List>
                          </div>
                        </div>

                        <LoadingButton
                          onClick={() => {
                            // startCamera();
                            // setStartRecording(true);
                            setCameraStartPopUp(true);
                          }}
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            padding: "12px 16px",
                            boxShadow: "none",
                          }}
                        >
                          Launch Camera
                        </LoadingButton>
                      </OuterPaper>
                    </OuterBox2>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <OuterBox2>
                <OuterPaper2 elevation={3}>
                  <video
                    id="video"
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "initial",
                      borderRadius: "0px !important",
                    }}
                    ref={videoRef}
                    muted
                    autoPlay
                    playsInline
                  ></video>

                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    style={{
                      position: "absolute",
                      bottom: "40px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="361"
                      height="414"
                      viewBox="0 0 361 254"
                      fill="none"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="360"
                        height="313"
                        rx="19.5"
                        stroke="white"
                        stroke-opacity="
93.95"
                      />
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="60"
                      viewBox="0 0 28 28"
                      fill="none"
                    >
                      <path
                        d="M2 8.88254V8.70607C2 5.00252 5.00232 2.0002 8.70587 2.0002V2.0002"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M2 19.1179V19.2943C2 22.9979 5.00232 26.0002 8.70587 26.0002V26.0002"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M25.8232 8.88254V8.70607C25.8232 5.00252 22.8209 2.0002 19.1174 2.0002V2.0002"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M25.8232 19.1179V19.2943C25.8232 22.9979 22.8209 26.0002 19.1174 26.0002V26.0002"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M18.8806 19.0522V19.0522C16.3528 20.1757 13.4673 20.1757 10.9395 19.0522V19.0522"
                        stroke="#3936EF"
                        stroke-opacity="0.4"
                        stroke-width="1.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M15.435 10.2287V14.0164C15.435 15.0128 14.7379 15.8733 13.7632 16.0801V16.0801"
                        stroke="#3936EF"
                        stroke-opacity="0.4"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M9.88086 10.2287L9.88086 12.3185"
                        stroke="#3936EF"
                        stroke-opacity="0.4"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M20.1162 10.2287L20.1162 12.3185"
                        stroke="#3936EF"
                        stroke-opacity="0.4"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M17.8816 19.0526V19.0526C15.3537 20.1761 12.4683 20.1761 9.94043 19.0526V19.0526"
                        stroke="white"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M14.436 10.2291V14.0168C14.436 15.0132 13.7389 15.8737 12.7642 16.0805V16.0805"
                        stroke="white"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8.88184 10.2291L8.88184 12.3189"
                        stroke="white"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                      <path
                        d="M19.1172 10.2291L19.1172 12.3189"
                        stroke="white"
                        stroke-width="2.4"
                        stroke-linecap="round"
                      />
                    </svg>
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "900",
                        color: "white",
                        margin: "5px 0px",
                      }}
                    >
                      Face Verification Initiated...
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        fontWeight: "900",
                        color: "white",
                        margin: "5px 0px",
                      }}
                    >
                      Please verify the image before submitting
                    </Typography>

                    <LoadingButton
                      id="click-photo"
                      type="button"
                      onClick={takePhoto}
                      style={{
                        padding: "10px 45px",
                        // margin: "0px 65px",
                        width: "95%",
                        boxShadow: "none",
                      }}
                    >
                      Click Photo
                    </LoadingButton>
                  </Grid>

                  <canvas
                    id="canvas"
                    width="480"
                    height="600"
                    ref={canvasRef}
                    style={{ display: "none" }}
                  ></canvas>
                </OuterPaper2>
              </OuterBox2>
            </React.Fragment>
          )}
        </form>
      </Fragment>

      <Instruction open={open} setOpen={setOpen} />

      <CameraStartPopUp
        startCamera={startCamera}
        cameraStartPopUp={cameraStartPopUp}
        setStartRecording={setStartRecording}
        setCameraStartPopUp={setCameraStartPopUp}
      />

      <Error
        open={errorOpen}
        setOpen={setErrorOpen}
        ErrorMessage={error}
        CloseButton={false}
      />
    </Fragment>
  );
}
