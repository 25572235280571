import { useFormik } from "formik";
import { Grid, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import Form from "./form components/Form";
import Form1 from "./form components/Form1";
import pages from "../../../constants/pages";
import { useAlert, useAxios } from "./../../../hooks/";
import { formData } from "./form components/appointmentBookingObject";
import { validationSchema } from "./form components/appointmentBookingObject";
import StageStepper from "./Stepper";
import AddMember from "./form components/AddMember";
import ReviewBook from "./form components/ReviewBook";
import RequestSend from "./form components/RequestSend";
import ScheduleTime from "./form components/ScheduleTime";

const MyComponent = () => {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [loading, setLoading] = useState(false);
  const [inviteList, setInviteList] = useState([]);
  const [stepperLevel, setStepperLevel] = useState(0);
  const [customFields, setCustomFields] = useState([]);

  const onSubmit = async (values) => {
    let ObjectValue = {
      reference_visitor_id: visitorData.ID,
    };

    let appointmentDate = new Date(values.date);

    ObjectValue.visitors = values.visitorData.map((item, index) => {
      return {
        ...item,
        officer_id: values.officer,
        purpose: values?.PurposeVisit,
        ...(index === 0 && { designation: values.designation || "" }),
        ...(index === 0 && { organization_name: values.organization || "" }),
        appointment_date: appointmentDate.getTime() / 1000,
        slot_id: [values["time"]],
        total_bookings: values.memberCount + 1,
      };
    });
    try {
      setLoading(true);

      const response = await axios({
        url: "/appointment/appointment/book-appointment",
        method: "POST",
        data: ObjectValue,
      });
      if (response.status) {
        setLoading(false);
        // setStepperLevel(4);
        dispatch(
          alert({
            type: "success",
            message: response.message.displayMessage,
          })
        );
        sessionStorage.setItem("appointmentID", response.data.appointment_id);

        navigate(
          `${pages.AppointmentSummary?.route}?officer_id=${response.data.officer_id}&appointment_id=${response.data.appointment_id}`
        );

        // navigate(
        //   `${pages.Verification?.route}?visitor_id=${response.data.visitor_id}&appointment_id=${response.data.appointment_id}&officer_id=${response.data.officer_id}`,
        //   {
        //     state: {
        //       details: response.data,
        //     },
        //   }
        // );
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in Appointment booking", error);
    }
  };

  const formik = useFormik({
    initialValues: formData,
    // validationSchema,
    onSubmit,
  });

  const [premises, setPremises] = useState([]);
  const [officials, setOfficials] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [subDepartments, setSubDepartments] = useState([]);
  const [memberCount, setMemberCount] = useState(formik.values.memberCount);

  useEffect(() => {
    axios({
      url: "appointment/appointment/get-premises",
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setPremises(response.data);
      }
    });

    formik.setFieldValue("visitorData[0].mobile", visitorData.phoneNumber);

    formik.setFieldValue("visitorData[0].ID", visitorData.ID);

    formik.setFieldValue(
      "visitorData[0].name",
      visitorData.firstName + " " + visitorData.lastName
    );
  }, []);

  return (
    <Fragment>
      {stepperLevel == 5 && (
        <Fragment>
          <Grid
            item
            sx={{
              background: "#1B996A",
              height: "17.5vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon
              sx={{
                fontSize: "35px",
                color: "white",
                marginBottom: "10px",
              }}
            />
            <Typography
              variant="h5"
              sx={{ color: "white", textAlign: "center", fontWeight: "800" }}
            >
              Appointment Requested
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              background: "rgb(198 185 255 / 60%)",
              height: "7vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "7.5px",
              margin: "12px 7.5px 0px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "black",
                textAlign: "center",
                fontWeight: "500",
                fontSize: "12px",
              }}
            >
              Reach the premises 15 minutes prior your appointment time for a
              hassle-free experience
            </Typography>
          </Grid>
        </Fragment>
      )}

      <Grid container padding={5}>
        {stepperLevel < 5 && (
          <Fragment>
            <Grid container className="subheaderSectionBox">
              <Typography
                variant={"h3"}
                sx={{
                  marginBottom: "5px",
                  textAlign: "left",
                  color: "black",
                  "-webkit-text-stroke-width": ".5px",
                }}
              >
                Book Appointment
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  alignContent: "center",
                  textAlign: "left",
                  color: "gray",
                  marginLeft: {
                    sl: "20px",
                  },
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: "Book your appointment in 5 easy steps" || "",
                  }}
                />
              </Typography>
            </Grid>

            <StageStepper stepperLevel={stepperLevel} />
          </Fragment>
        )}

        <form
          style={{ width: "100%" }}
          onSubmit={(event) => {
            event.preventDefault();
            formik.handleSubmit();
          }}
        >
          {stepperLevel == 0 && (
            <Form
              formik={formik}
              premises={premises}
              departments={departments}
              setDepartments={setDepartments}
              officials={officials}
              setCustomFields={setCustomFields}
              subDepartments={subDepartments}
              setSubDepartments={setSubDepartments}
              setStepperLevel={setStepperLevel}
            />
          )}

          {stepperLevel == 1 && (
            <ScheduleTime
              formik={formik}
              memberCount={memberCount}
              setMemberCount={setMemberCount}
              setStepperLevel={setStepperLevel}
            />
          )}

          {stepperLevel == 2 && (
            <Form1
              formik={formik}
              memberCount={memberCount}
              setMemberCount={setMemberCount}
              setStepperLevel={setStepperLevel}
              customFields={customFields}
            />
          )}

          {stepperLevel == 3 && (
            <AddMember
              formik={formik}
              setStepperLevel={setStepperLevel}
              customFields={customFields}
            />
          )}

          {stepperLevel == 4 && (
            <ReviewBook formik={formik} setStepperLevel={setStepperLevel} />
          )}
        </form>
        {stepperLevel == 5 && (
          <RequestSend formik={formik} setStepperLevel={setStepperLevel} />
        )}
      </Grid>
    </Fragment>
  );
};

export default MyComponent;
