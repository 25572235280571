import React, { Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Checkbox,
  DialogTitle,
} from "@mui/material";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { IoMdAdd } from "react-icons/io";
import CloseIcon from "@mui/icons-material/Close";

import {
  CommonTextField,
  SelectField,
  TextField,
} from "../../../../components";
import { useAxios } from "../../../../hooks";

import userSvg from ".././../assests/addmemberbackground.svg";
import CustomField from "./CustomField";
import * as Yup from "yup";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    background: "rgba(255, 255, 255, 0)",
    width: "100%",
    margin: "0px",
    height: "70vh",
    marginTop: "30vh",
    borderRadius: "0px",
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const AddMemberPopup = ({
  formik,
  addMemberPopup,
  setAddMemberPopup,
  customFields,
}) => {
  const axios = useAxios();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [dependentData, setDependentData] = useState([]);
  const [addMember, setAddMember] = useState(true);
  const [selectedMember, setSelectedMember] = useState(
    Array(visitorData.dependentList.length).fill(false)
  );
  const [formData, setFormData] = useState({
    mobile: "",
    name: "",
    gender: "",
    state: "",
  });

  // console.log(formik.values, "formik.valuekformik");

  const [selectedMemberList, setSelectedListMember] = useState([]);
  const [newMember, setNewMember] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const validationSchema = Yup.object({
    mobile: Yup.string()
      .matches(
        /^((\+\d{1,4}[-\s]*)?(\(?\d{2,4}\)?[-\s]*)?\d{3,4}[-\s]*\d{3,4})$/,
        "Invalid phone number"
      )
      .required("Mobile number is required"),
  });

  useEffect(() => {
    axios({
      url: "event/booking/states",
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          setStateOptions(response.data);
        }
      })
      .catch((error) => {
        console.error("Error in fetching states list", error);
      });
  }, []);

  function handleSelectMember(e, index, memberDetail, isDependent) {
    if (e.target.checked) {
      let obj = { ...memberDetail };
      obj["isDependent"] = isDependent;

      setSelectedListMember((prev) => {
        return [...prev, obj];
      });
    } else {
      let obj = { ...memberDetail };
      obj["isDependent"] = isDependent;

      let ind = selectedMemberList.findIndex(
        (item) => JSON.stringify(obj) === JSON.stringify(item)
      );

      let arr = selectedMemberList.filter((item, i) => i !== ind);

      let selectedVisitorData = formik.values.visitorData.filter(
        (item, index) => {
          return obj?._id ? item?.ID !== obj?._id : item?.mobile !== obj.mobile;
        }
      );

      setSelectedListMember(arr);

      formik.setFieldValue("visitorData", [...selectedVisitorData]);
    }

    let arr = [...selectedMember];
    arr[index] = e.target.checked;

    setSelectedMember(arr);
  }

  useEffect(() => {
    setSelectedMember(
      Array(visitorData.dependentList.length + newMember.length).fill(false)
    );
  }, []);

  return (
    <Fragment>
      <StyledDialog
        open={addMemberPopup}
        onClose={() => setAddMemberPopup(false)}
        fullWidth
        maxWidth="md"
      >
        {/* {console.log(formik.values, "formikformik")} */}
        <DialogTitle sx={{ background: "#ffffff00" }}>
          <Grid container justifyContent={"end"}>
            <CloseIcon
              sx={{
                fontSize: "35px",
                padding: "4px",
                color: "white",
                borderRadius: "50%",
                background: "#FFFFFF38",
                // marginLeft: "90%",
              }}
              onClick={() => {
                setAddMemberPopup(false);
              }}
            />
          </Grid>
        </DialogTitle>
        <DialogContent
          style={{
            padding: "0px",
            background: "white",
            borderTopRightRadius: "15.5px",
            borderTopLeftRadius: "15.5px",
          }}
        >
          <img src={userSvg} style={{ position: "absolute", right: "0" }}></img>

          <ContentBox>
            <Grid container>
              <Grid container className="subheaderSectionBox">
                <Typography
                  variant={"h4"}
                  sx={{
                    marginBottom: "5px",
                    textAlign: "left",
                    color: "black",
                    fontWeight: "700",
                    "-webkit-text-stroke-width": ".5px",
                  }}
                >
                  Add Member
                </Typography>
              </Grid>

              {addMember ? (
                <Fragment>
                  {!!visitorData.dependentList.length && (
                    <Fragment>
                      <Grid
                        container
                        className="subheaderSectionBox"
                        sx={{ marginTop: "15px" }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            alignContent: "center",
                            textAlign: "left",
                            color: "gray",
                            fontSize: "14px",
                          }}
                        >
                          {"Saved Dependent Members".toUpperCase()}
                        </Typography>
                      </Grid>
                      {visitorData.dependentList.map((item, index) => {
                        return (
                          <Grid
                            item
                            container
                            sx={{
                              border: "0.6px solid #0000001A",
                              borderRadius: "8px",
                              marginTop: "15px",
                            }}
                          >
                            <Grid item container p={"3.5px 0px"}>
                              <Grid item container p={"5px 10px 10px 15px"}>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Checkbox
                                    checked={selectedMember[index]}
                                    onClick={(e) =>
                                      handleSelectMember(e, index, item, true)
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                  onClick={() => {
                                    let dependent = {
                                      ID: visitorData.dependentList[index]._id,
                                      dependent: true,
                                      mobile:
                                        visitorData.dependentList[index]
                                          ?.phone_number,
                                      name:
                                        visitorData.dependentList[index]
                                          ?.first_name +
                                        " " +
                                        visitorData.dependentList[index]
                                          ?.last_name,
                                      gender:
                                        visitorData.dependentList[index]
                                          ?.gender,
                                    };
                                    setFormData(dependent);
                                    setAddMember(false);
                                    setDependentData(visitorData.dependentList);
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={"600"}
                                    fontSize={"14px"}
                                  >
                                    {item.first_name + " " + item.last_name}
                                  </Typography>

                                  <Typography
                                    variant="body1"
                                    fontWeight={400}
                                    fontSize={"12px"}
                                  >
                                    {item.phone_number}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Fragment>
                  )}

                  {!!newMember.length && (
                    <Fragment>
                      <Grid
                        container
                        className="subheaderSectionBox"
                        sx={{ marginTop: "15px" }}
                      >
                        <Typography
                          variant="h6"
                          sx={{
                            alignContent: "center",
                            textAlign: "left",
                            color: "gray",
                            fontSize: "14px",
                          }}
                        >
                          {"Other Members".toUpperCase()}
                        </Typography>
                      </Grid>

                      {newMember.map((item, index) => {
                        return (
                          <Grid
                            item
                            container
                            sx={{
                              border: "0.6px solid #0000001A",
                              borderRadius: "8px",
                              marginTop: "15px",
                            }}
                          >
                            <Grid item container p={"3.5px 0px"}>
                              <Grid item container p={"5px 10px 10px 15px"}>
                                <Grid
                                  item
                                  xs={2}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Checkbox
                                    checked={
                                      selectedMember[
                                        index + visitorData.dependentList.length
                                      ]
                                    }
                                    onClick={(e) =>
                                      handleSelectMember(
                                        e,
                                        index +
                                          visitorData.dependentList.length,
                                        item,
                                        false
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    fontWeight={"600"}
                                    fontSize={"14px"}
                                  >
                                    {item.name}
                                  </Typography>

                                  <Typography
                                    variant="body1"
                                    fontWeight={400}
                                    fontSize={"12px"}
                                  >
                                    {item.mobile}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Fragment>
                  )}

                  <Grid
                    item
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "5px",
                    }}
                  >
                    <LoadingButton
                      startIcon={<IoMdAdd />}
                      sx={{ color: "#3936EF", fontWeight: "bolder" }}
                      onClick={() => {
                        setFormData({
                          mobile: "",
                          name: "",
                          gender: "",
                          state: "",
                        });
                        setAddMember(false);
                      }}
                    >
                      Add New Members
                    </LoadingButton>
                  </Grid>
                </Fragment>
              ) : (
                <Fragment>
                  <Formik
                    initialValues={formData}
                    validationSchema={validationSchema}
                    onSubmit={(formData, { setSubmitting }) => {
                      if (formData.dependent) {
                        console.log(formData.ID, "eokkc");
                        let arr = [...dependentData];
                        let index = dependentData.findIndex(
                          (item) => item._id == formData.ID
                        );
                        arr[index] = formData;
                        setDependentData(arr);
                        setAddMember(true);
                      } else {
                        let arr = newMember;
                        arr[newMember.length] = formData;
                        setNewMember(arr);
                        setAddMember(true);
                      }
                    }}
                  >
                    {(formikProps) => {
                      const handleInputChange = (e) => {
                        const inputValue = e.target.value;
                        const regex = /^[A-Za-z\s]*$/;

                        if (regex.test(inputValue)) {
                          formikProps.handleChange(e);
                        }
                      };

                      // Function to fetch districts based on the selected state
                      const fetchDistricts = async (selectedState) => {
                        try {
                          const response = await axios({
                            url: `event/booking/districts?state_id=${selectedState}`,
                            method: "GET",
                          });
                          if (response.status) {
                            setDistrictOptions(response.data);
                          }
                        } catch (error) {
                          console.error(
                            "Error in fetching district list",
                            error
                          );
                        }
                      };

                      // Function to handle state selection change
                      const handleStateChange = (event) => {
                        setDistrictOptions([]);
                        const selectedState = event.target.value;
                        formikProps.setFieldValue(`state`, selectedState);
                        formikProps.setFieldValue(`district`, ""); // Reset selected district when state changes
                        fetchDistricts(selectedState);
                      };

                      return (
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            textTransform: "capitalize",
                            padding: "15px 15px 0px 5px !important",
                            borderRadius: "12.5px",
                            position: "relative",
                          }}
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: "900",
                                marginBottom: "5px",
                                textAlign: "start",
                              }}
                            >
                              Your Mobile No{"  "}
                              <span
                                style={{
                                  color: "red",
                                  "-webkit-text-stroke-width": ".5px",
                                }}
                              >
                                *
                              </span>
                            </Typography>

                            <TextField
                              name={`mobile`}
                              label={"Mobile No"}
                              formikProps={formikProps}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: "900",
                                marginBottom: "5px",
                                textAlign: "start",
                              }}
                            >
                              Your Name{"  "}
                              <span
                                style={{
                                  color: "red",
                                  "-webkit-text-stroke-width": ".5px",
                                }}
                              >
                                *
                              </span>
                            </Typography>

                            <TextField
                              name={`name`}
                              label={"Name"}
                              required
                              formikProps={formikProps}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <CustomField
                              formikProps={formik}
                              customFields={customFields}
                              visitorDataIndex={0}
                              title={true}
                            />
                          </Grid>

                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            xs={12}
                          >
                            <LoadingButton
                              startIcon={<IoMdAdd />}
                              variant="contained"
                              color="primary"
                              fullWidth
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                fontStyle: "normal",
                                borderRadius: "8px",
                                backgroundColor: "rgba(57, 54, 239, 1)",
                                color: "#FFF",
                                width: "100%",
                                "@media (min-width: 1000px)": {
                                  width: "100%",
                                },
                                "&:hover": {
                                  backgroundColor: "rgba(57, 54, 239, 1)",
                                  borderRadius: "8px",
                                },
                                marginBottom: "20px",
                              }}
                              onClick={formikProps.handleSubmit}
                            >
                              Add Members
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      );
                    }}
                  </Formik>
                </Fragment>
              )}
            </Grid>

            {addMember && (
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "45%",
                }}
                xs={12}
              >
                <LoadingButton
                  startIcon={<IoMdAdd />}
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontStyle: "normal",
                    borderRadius: "8px",
                    backgroundColor: "rgba(57, 54, 239, 1)",
                    color: "#FFF",
                    width: "100%",
                    "@media (min-width: 1000px)": {
                      width: "100%",
                    },
                    "&:hover": {
                      backgroundColor: "rgba(57, 54, 239, 1)",
                      borderRadius: "8px",
                    },
                    marginBottom: "20px",
                  }}
                  onClick={() => {
                    let arr = selectedMemberList.map((item, index) => {
                      return {
                        ID: item._id || Math.random(),
                        district: item.district || "",
                        gender: item.gender || "",
                        mobile: item.mobile || item.phone_number,
                        name:
                          item.name || item.first_name + " " + item.last_name,
                        isDependent: item.isDependent,
                      };
                    });

                    arr.filter((item, index) => {
                      formik.values.visitorData.findIndex((itm) =>
                        item.isDependent ? itm : item != item
                      );
                    });

                    formik.setFieldValue("visitorData", [
                      ...formik.values.visitorData,
                      ...arr,
                    ]);

                    setSelectedListMember([]);

                    setAddMemberPopup(false);
                  }}
                >
                  Add Members
                </LoadingButton>
              </Grid>
            )}
          </ContentBox>
        </DialogContent>
      </StyledDialog>
    </Fragment>
  );
};

export default AddMemberPopup;
