import { IoMdAdd } from "react-icons/io";
import { Fragment, useState } from "react";
import { LoadingButton } from "@mui/lab";
import AddMemberPopup from "./AddMemberPopup";
import { Grid, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { RiDeleteBin6Line } from "react-icons/ri";

import EditButton from ".././../assests/editButton.svg";
function AddMember({ formik, setStepperLevel, customFields }) {
  const [addMemberPopup, setAddMemberPopup] = useState(false);

  function handelRemoveMember(details) {
    let obj = { ...details };

    let ind = formik.values.visitorData.findIndex(
      (item) => JSON.stringify(obj) === JSON.stringify(item)
    );

    let arr = formik.values.visitorData.filter((item, i) => i !== ind);

    formik.setFieldValue("visitorData", arr);
  }
  return (
    <Fragment>
      <Grid container className="subheaderSectionBox">
        <Typography
          variant={"h4"}
          sx={{
            marginBottom: "5px",
            textAlign: "left",
            color: "black",
            "-webkit-text-stroke-width": ".5px",
          }}
        >
          Members Attending the Appointment
        </Typography>

        <Typography
          variant="body1"
          sx={{
            alignContent: "center",
            textAlign: "left",
            color: "gray",
            marginLeft: {
              sl: "20px",
            },
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: "Add any additional members if needed" || "",
            }}
          />
        </Typography>
      </Grid>
      {formik.values?.visitorData?.map((item) => {
        return (
          <Fragment>
            {item.isDependent === undefined && (
              <Grid
                item
                container
                sx={{
                  border: "0.6px solid #0000001A",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              >
                <Grid item container p={"7.5px 0px"}>
                  <Grid item container p={"5px 10px 10px 15px"}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <img
                        width={"20px"}
                        src={
                          "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                        }
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "45%",
                        }}
                      ></img>
                    </Grid>
                    <Grid
                      item
                      xs={8.5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={"bolder"}
                        fontSize={"14px"}
                      >
                        {item.name}
                      </Typography>

                      <Typography
                        variant="body1"
                        fontWeight={400}
                        fontSize={"12px"}
                      >
                        {item.mobile}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "4px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        setStepperLevel(2);
                      }}
                    >
                      <img
                        src={EditButton}
                        width={"20px"}
                        height={"20px"}
                        style={{}}
                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      })}

      {!!formik.values?.visitorData?.filter(
        (item) => item.isDependent === true && item.isDependent !== undefined
      ).length && (
        <Fragment>
          <Grid container sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{
                alignContent: "center",
                textAlign: "left",
                color: "gray",
                marginLeft: {
                  sl: "20px",
                },
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: "Dependent Members".toUpperCase() || "",
                }}
              />
            </Typography>
          </Grid>
          {formik.values?.visitorData
            ?.filter(
              (item) =>
                item.isDependent === true && item.isDependent !== undefined
            )
            .map((item) => {
              return (
                <Fragment>
                  <Grid
                    item
                    container
                    sx={{
                      border: "0.6px solid #0000001A",
                      borderRadius: "8px",
                      marginTop: "7px",
                    }}
                  >
                    <Grid item container p={"7.5px 0px"}>
                      <Grid item container p={"5px 10px 10px 15px"}>
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "center",
                          }}
                        >
                          <img
                            width={"20px"}
                            src={
                              "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "45%",
                            }}
                          ></img>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={"bolder"}
                            fontSize={"14px"}
                          >
                            {item.name}
                          </Typography>

                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={"12px"}
                          >
                            {item.mobile}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            handelRemoveMember(item);
                          }}
                        >
                          <RiDeleteBin6Line
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#919191",
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            handelRemoveMember(item);
                          }}
                        >
                          <img
                            src={EditButton}
                            width={"20px"}
                            height={"20px"}
                            style={{}}
                          ></img>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
        </Fragment>
      )}

      {!!formik.values?.visitorData?.filter(
        (item) => item.isDependent === false && item.isDependent !== undefined
      ).length && (
        <Fragment>
          <Grid container sx={{ marginTop: "20px" }}>
            <Typography
              variant="body1"
              sx={{
                alignContent: "center",
                textAlign: "left",
                color: "gray",
                marginLeft: {
                  sl: "20px",
                },
              }}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: "Other Members".toUpperCase() || "",
                }}
              />
            </Typography>
          </Grid>
          {formik.values?.visitorData
            ?.filter(
              (item) =>
                item.isDependent === false && item.isDependent !== undefined
            )
            ?.map((item) => {
              return (
                <Fragment>
                  <Grid
                    item
                    container
                    sx={{
                      border: "0.6px solid #0000001A",
                      borderRadius: "8px",
                      marginTop: "7px",
                    }}
                  >
                    <Grid item container p={"7.5px 0px"}>
                      <Grid item container p={"5px 10px 10px 15px"}>
                        <Grid
                          item
                          xs={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "center",
                          }}
                        >
                          <img
                            width={"20px"}
                            src={
                              "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                            }
                            style={{
                              width: "30px",
                              height: "30px",
                              borderRadius: "45%",
                            }}
                          ></img>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            variant="body1"
                            fontWeight={"bolder"}
                            fontSize={"14px"}
                          >
                            {item.name}
                          </Typography>

                          <Typography
                            variant="body1"
                            fontWeight={400}
                            fontSize={"12px"}
                          >
                            {item.mobile}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            handelRemoveMember(item);
                          }}
                        >
                          <RiDeleteBin6Line
                            style={{
                              width: "20px",
                              height: "20px",
                              color: "#919191",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            // window.location.replace(eventDetail?.address.map_link);
                          }}
                        >
                          <img
                            src={EditButton}
                            width={"20px"}
                            height={"20px"}
                            style={{}}
                          ></img>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            })}
        </Fragment>
      )}

      <Grid
        item
        sx={{ display: "flex", justifyContent: "end", marginTop: "5px" }}
      >
        <div
          style={{
            margin: "5px",
            // border: "1px solid black",
            padding: "10px 20px",
            borderRadius: "12.5px",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: 900,
                textAlign: "start",
              }}
            >
              Add Members
            </Typography>
            <Grid
              item
              sx={{
                display: "flex",
                // padding: "10px 20px",
                // border: "1px solid black",
                // borderRadius: "12.5px",
              }}
            >
              <RemoveIcon
                sx={{
                  margin: "0px 10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  formik.values.memberCount > 0 &&
                    formik.setFieldValue(
                      "memberCount",
                      formik.values.memberCount - 1
                    );
                }}
              />
              <Typography
                sx={{
                  fontWeight: 900,
                  textAlign: "start",
                  color: "rgb(57, 54, 239)",
                }}
              >
                {formik.values.memberCount || 0}
              </Typography>
              <AddIcon
                sx={{
                  margin: "0px 0px 0px 10px",
                  borderRadius: "5px",
                  border: "1px solid black",
                }}
                onClick={() => {
                  formik.setFieldValue(
                    "memberCount",
                    formik.values.memberCount + 1
                  );
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>

      {/* <Grid
        item
        sx={{ display: "flex", justifyContent: "end", marginTop: "5px" }}
      >
        <LoadingButton
          startIcon={<IoMdAdd />}
          sx={{ color: "#3936EF", fontWeight: "bolder" }}
          onClick={() => {
            setAddMemberPopup(true);
          }}
        >
          Add Members
        </LoadingButton>
      </Grid> */}

      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "45%",
        }}
        xs={12}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            fontStyle: "normal",
            borderRadius: "8px",
            backgroundColor: "rgba(57, 54, 239, 1)",
            color: "#FFF",
            width: "100%",
            "@media (min-width: 1000px)": {
              width: "100%",
            },
            "&:hover": {
              backgroundColor: "rgba(57, 54, 239, 1)",
              borderRadius: "8px",
            },
            marginBottom: "20px",
          }}
          onClick={() => {
            setStepperLevel(4);
          }}
        >
          Save & Review Details
        </LoadingButton>
      </Grid>

      <AddMemberPopup
        formik={formik}
        addMemberPopup={addMemberPopup}
        customFields={customFields}
        setAddMemberPopup={setAddMemberPopup}
      />
    </Fragment>
  );
}

export default AddMember;
