import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";
import { IoIosArrowForward } from "react-icons/io";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Button,
  Paper,
  Box,
  Divider,
} from "@mui/material";

import DrivingLicence from "./DrivingLicence/DrivingLicence";
import { useAxios, useAxiosV2, useComponent } from "../../hooks/";
import Aadharverificaion from "./Aadharverificaion/Aadharverificaion";

import { SubheaderSection, LoadingButton, Loader } from "./../../components";

import styled from "styled-components";
import Error from "../Error/Error";
import EventBookingSuccess from "../Success/EventBookingSuccess";
import pages from "../../constants/pages";
import aadhar from "./assests/Aadhar.svg";
import digilocker from "./assests/digilocker.jpg";
import dl from "./assests/DL.svg";
import icon from "./assests/headericon.svg";
import DIGILOCKER from "./assests/DIGILOCKER.svg";
import AadharCardBack from "./assests/AadharCard.svg";

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root ": {
    "& fieldset": {
      borderColor: "rgb(232, 222, 207) !important",
      borderRadius: "12.5px",
    },
    "&:hover fieldset": {
      borderColor: "rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: " rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
  },

  "&.MuiSelect-icon": {
    color: " rgb(161, 130, 74) !important",
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: "1px solid rgb(232, 222, 207)",
  },

  ".MuiInputLabel-root": {
    color: "rgb(161, 130, 74)",
  },
}));

const OuterBox2 = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  // padding: 0px 17px;
  box-sizing: border-box;
  height: 100%;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  padding: 20px;
  min-height: 20vh;
`;

const WelcomeScreen = ({ setAadharVerified }) => {
  const dispatch = useDispatch();
  const { loader } = useComponent();

  const axiosVerismart = useAxios({ baseURL: "mox_api_2" });
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [token, setToken] = React.useState("");
  const [verification, setVerification] = useState(false);
  const [identityType, setIdentityType] = useState("");
  const [eventDetail, setEventDetail] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [verificationFailedCount, setVerificationFailedCount] =
    React.useState(0);
  const [displayMessage, setDisplayMessage] = useState(undefined);

  const [redirectPageLink, setRedirectPageLink] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const eventId = queryParams.get("event_id");
  const visitorId = queryParams.get("visitor_id");
  const bookingId = queryParams.get("booking_id");
  const bookingType = queryParams.get("booking_type");
  const appointmentID = queryParams.get("appointment_id");
  const officerID = queryParams.get("officer_id");
  const campusId = queryParams.get("campus_id");
  const studentId = queryParams.get("student_id");
  const employeeId = queryParams.get("employee_id");
  const popup = queryParams.get("popup");
  const docVerify = queryParams.get("doc_verify");
  const digiLockerID = queryParams.get("id");

  if (bookingId !== null) {
    sessionStorage.setItem("bookingId", bookingId);
  }

  if (visitorId !== null) {
    sessionStorage.setItem("visitorId", visitorId);
  }

  if (eventId !== null) {
    sessionStorage.setItem("eventId", eventId);
  }

  const [bookingStatus, setBookingStatus] = useState({
    booking_already_verified: false,
    booking_exists: undefined,
    face_match: false,
    name_match: false,
    qr_link: "",
  });

  const localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return (
      item.includes(visitorId) &&
      !item.includes("userPayload") &&
      !item.includes("face&docs")
    );
  });

  useEffect(() => {
    const digipassData = Object.keys(localStorageData).filter((item) => {
      return (
        item.includes(visitorId) &&
        !item.includes("userPayload") &&
        !item.includes("face&docs")
      );
    });

    let faceData = digipassData.filter((item) => {
      return localStorage.getItem(item);
    });

    if (digiLockerID && docVerify) {
      loader.start(1);
      axiosVerismart({
        url: "event/govt-api/get-user-document",
        method: "POST",
        data: {
          document_type: "aadhar",
          request_id: digiLockerID,
        },
      }).then((response) => {
        if (response.status) {
          const name = response.data.aadhaar.name;
          const nameParts = name.split(" ");
          const first_name = nameParts[0];
          let last_name = nameParts.slice(1).join(" ");
          if (last_name.trim() === "") {
            last_name = first_name;
          }

          let payload = {
            doc_type: 1,
            booking_type: "event",
            aadharNo: response.data.aadhaar.maskedNumber,
            visitor_id: visitorId,
            first_name: first_name,
            last_name: last_name,
            gender: response.data.aadhaar.gender,
            dob: response.data.aadhaar.dateOfBirth,
            address: response.data.aadhaar.address,
            doc_face_image: response.data.aadhaar.photo,
          };

          payload.is_verified = 1;
          const payloadString = JSON.stringify(payload);

          axiosVerismart({
            url: `event/verify/booking`,
            method: "POST",
            disableRedirect: true,
            data: {
              name: " ",
              aadhar_data: payload,
              visitor_id: visitorId,
              face_data: localStorage.getItem(faceData[0]),
              document_verification: true,
              is_encrypted_face: true,
              phone_number:
                visitorData.phoneNumber ||
                sessionStorage.getItem("mobileNumber"),
            },
          })
            .then((response) => {
              if (response.status) {
                let key = visitorId + "_face&docs";

                localStorage.setItem(key, response.data.encrypted_face_data);
                localStorage.setItem(`userPayload_${visitorId}`, payloadString);

                navigate(pages.Profile.route);
              }

              loader.apiComplete(1);
            })
            .catch((error) => {
              console.error("Error in Aws upload:", error);
            });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!visitorData.ID && !popup) {
      navigate(pages.appointment.route + "login");
    }
  }, []);

  useEffect(() => {
    let faceData = digipassDataKey.filter((item) => {
      return localStorage.getItem(item);
    });

    if (popup && faceData.length > 0) {
      window.close();
    }

    // if (faceData.length !== 0 && appointmentID != -1) {
    //   axiosVerismart({
    //     url: `event/verify/booking-status`,
    //     method: "POST",
    //     disableRedirect: true,
    //     data: {
    //       name: "",
    //       face_data: localStorage.getItem(faceData[0]),
    //       ...(bookingId && { booking_id: bookingId }),
    //       ...(appointmentID && { appointment_id: appointmentID }),
    //     },
    //   }).then((response) => {
    //     if (response.status) {
    //       if (!response.data.is_valid) {
    //         setOpenError(response.data);
    //       }
    //       if (response.data.is_verified) {
    //         setOpenSuccess(true);
    //       }

    //       setRedirectPageLink(response?.data?.page_link);

    //       setDisplayMessage(response.message.displayMessage);
    //     } else {
    //       setOpenError(true);

    //       setDisplayMessage(response.displayMessage);
    //     }
    //   });
    // }

    axiosAadhar({
      url: `/login/`,
      method: "POST",
      data: {
        client_secret: "dmVyaXNtYXJ0",
      },
    })
      .then((response) => {
        if (response.success) {
          setToken(response.access_token);
        } else {
          dispatch(
            alert({
              type: "error",
              message: "Token generation failed",
            })
          );
        }
      })
      .catch((error) => {
        console.error("Error in token generation ", error);
      });

    if (Boolean(eventId)) {
      axiosVerismart({
        url: "event/booking/documents?event_id=" + eventId,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDocumentsList(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });

      axiosVerismart({
        url: "admin/events/event-details?event_id=" + eventId,
        method: "GET",
        customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          response?.data?.button_color &&
            sessionStorage.setItem("buttonColor", response.data.button_color);
          response?.data?.background_color &&
            sessionStorage.setItem(
              "buttonBackground",
              response.data.background_color
            );
          setEventDetail(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });
    } else {
      axiosVerismart({
        url: "event/booking/documents?appointment_id=" + appointmentID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDocumentsList(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });
    }
  }, []);

  const handleProceed = (value) => {
    if (value === 3) {
      axiosVerismart({
        url: `/event/govt-api/get-redirect-url?type_id=${
          eventId
            ? 2
            : appointmentID != -1 && appointmentID
            ? 3
            : campusId
            ? 4
            : 1
        }`,
        method: "POST",
        data: {
          ...(eventId && {
            booking_id: bookingId,
            event_id: eventId,
            visitor_id: visitorId,
          }),
          ...(appointmentID && {
            appointment_id: appointmentID,
            officer_id: officerID,
            visitor_id: visitorId,
          }),
          ...(!appointmentID && {
            appointment_id: -1,
            officer_id: -1,
            visitor_id: visitorId,
          }),
          ...(studentId && { campus_id: campusId, student_id: studentId }),
          ...(employeeId && { campus_id: campusId, employee_id: employeeId }),
        },
      }).then((response) => {
        if (response.status) {
          window.location.replace(response.data.redirect_url);
        }
      });
    }
    setVerification(true);
  };
  const handleIdentityTypeChange = (value) => {
    console.log("9652416", value);
    setIdentityType(value);

    handleProceed(value);
  };

  if (verificationFailedCount > 4) {
    window.location.reload();
  }

  return (
    <Fragment>
      <Loader>
        <Grid container className="verificationOuterBox">
          {identityType == "" && (
            <Grid item container>
              <Grid item container justifyContent={"center"}>
                <Grid
                  item
                  container
                  md={12}
                  sl={6}
                  sx={{
                    justifyContent: "start",
                    alignContent: "start",
                  }}
                >
                  <OuterBox2>
                    <OuterPaper style={{ padding: "0px" }} elevation={3}>
                      <div
                        style={{
                          width: "100%",
                          position: "relative",
                          height: "35vh",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            position: "relative",
                            zIndex: "999999",
                            top: "47%",
                            left: "3%",
                          }}
                        >
                          <Typography
                            variant="h3"
                            fontWeight={900}
                            sx={{ marginTop: "7.5px" }}
                          >
                            {identityType == 1
                              ? "Verify Profile Using Aadhar"
                              : "Let’s Verify Your Profile"}
                          </Typography>
                          <Typography
                            variant="body1"
                            fontWeight={500}
                            sx={{ marginTop: "7.5px", color: "#00000099" }}
                          >
                            {identityType == 1
                              ? "Please Enter your Aadhar Details"
                              : "Digipass needs to verify your profile for xyz reasons"}
                          </Typography>
                        </Grid>
                        <img
                          style={{
                            height: "35vh",
                            position: "absolute",
                            top: "0px",
                          }}
                          src={identityType == 1 ? AadharCardBack : icon}
                        />
                      </div>
                    </OuterPaper>
                  </OuterBox2>
                </Grid>
              </Grid>
            </Grid>
          )}

          {!Boolean(verification) && (
            <Fragment>
              <Grid item container>
                <Grid item container justifyContent={"center"}>
                  <Grid
                    item
                    container
                    md={12}
                    sl={6}
                    sx={{
                      justifyContent: "start",
                      alignContent: "start",
                    }}
                  >
                    <OuterBox2>
                      <OuterPaper style={{ padding: "0px" }} elevation={3}>
                        {
                          <Fragment>
                            <Grid
                              item
                              // sl={6}
                              padding={"25px"}
                              alignContent={"center"}
                            >
                              <form>
                                <CustomFormControl
                                  fullWidth
                                  variant="outlined"
                                  sx={{ marginBottom: "16px" }}
                                >
                                  <Grid item container>
                                    <Grid
                                      item
                                      container
                                      sx={{
                                        justifyContent: "center",
                                        padding: "15px",
                                        borderRadius: "8px",
                                        alignItems: "center",
                                        boxShadow: "0px 1px 4px 0px #00000033",
                                      }}
                                      onClick={() =>
                                        handleIdentityTypeChange(3)
                                      }
                                    >
                                      <img
                                        src={DIGILOCKER}
                                        width={"65px"}
                                        height={"35px"}
                                      ></img>
                                      <Typography
                                        variant="body1"
                                        sx={{ fontWeight: "600" }}
                                      >
                                        Proceed with Digilocker
                                      </Typography>{" "}
                                    </Grid>

                                    <Grid
                                      container
                                      sx={{
                                        alignItems: "center",
                                        justifyContent: "space-evenly",
                                        margin: "45px 10px 25px",
                                      }}
                                    >
                                      <Divider
                                        sx={{
                                          width: "20%",
                                          height: ".95px",
                                          background: "#0000001A",
                                        }}
                                      />
                                      <Typography
                                        variant="h5"
                                        fontWeight={400}
                                        sx={{
                                          textAlign: "center",
                                          color: "#00000080",
                                        }}
                                      >
                                        Or Verify Using
                                      </Typography>
                                      <Divider
                                        sx={{
                                          width: "20%",
                                          height: ".95px",
                                          background: "#0000001A",
                                        }}
                                      />
                                    </Grid>

                                    <Grid
                                      item
                                      container
                                      sx={{
                                        justifyContent: "space-between",
                                        // border: "1.5px solid #00000069",
                                        padding: "15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 1px 4px 0px #00000033",
                                        alignItems: "center",
                                        margin: "10px 0px",
                                      }}
                                      onClick={() =>
                                        handleIdentityTypeChange(1)
                                      }
                                    >
                                      <Grid
                                        item
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={aadhar}
                                          width={"65px"}
                                          height={"35px"}
                                        ></img>
                                        <Typography
                                          variant="body1"
                                          sx={{ fontWeight: "600" }}
                                        >
                                          Aadhar Card
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <IoIosArrowForward
                                          fontSize={"20px"}
                                          color="#919191"
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      item
                                      container
                                      sx={{
                                        justifyContent: "space-between",
                                        // border: "1.5px solid #00000069",
                                        padding: "15px",
                                        borderRadius: "8px",
                                        boxShadow: "0px 1px 4px 0px #00000033",
                                        alignItems: "center",
                                      }}
                                      onClick={() =>
                                        handleIdentityTypeChange(2)
                                      }
                                    >
                                      <Grid
                                        item
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={dl}
                                          width={"65px"}
                                          height={"35px"}
                                        ></img>
                                        <Typography
                                          variant="body1"
                                          sx={{ fontWeight: "600" }}
                                        >
                                          Driving Licence
                                        </Typography>{" "}
                                      </Grid>
                                      <Grid item>
                                        <IoIosArrowForward
                                          fontSize={"20px"}
                                          color="#919191"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </CustomFormControl>
                              </form>
                            </Grid>
                          </Fragment>
                        }
                      </OuterPaper>
                    </OuterBox2>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}

          {Boolean(identityType === 1 && token && verification) && (
            <Aadharverificaion
              setAadharVerified={setAadharVerified}
              token={token}
              eventId={eventId}
              OuterBox2={OuterBox2}
              OuterPaper={OuterPaper}
              visitorId={visitorId}
              bookingType={bookingType}
              setVerification={setVerification}
              digipassDataKey={digipassDataKey}
              verificationFailedCount={verificationFailedCount}
              setVerificationFailedCount={setVerificationFailedCount}
            />
          )}

          {Boolean(identityType === 2 && token && verification) && (
            <DrivingLicence
              token={token}
              eventId={eventId}
              visitorId={visitorId}
              OuterBox2={OuterBox2}
              OuterPaper={OuterPaper}
              bookingType={bookingType}
              digipassDataKey={digipassDataKey}
              setVerification={setVerification}
              verificationFailedCount={verificationFailedCount}
              setVerificationFailedCount={setVerificationFailedCount}
            />
          )}

          <Error
            open={openError}
            CloseButton={false}
            setOpen={setOpenError}
            ErrorMessage={displayMessage}
          />

          <EventBookingSuccess
            open={openSuccess}
            setOpen={setOpenSuccess}
            SuccessMessage={displayMessage}
            handleOnClick={() => {
              navigate(redirectPageLink);
            }}
            buttonContent={redirectPageLink !== "" ? "Next" : "Next Step"}
          />
        </Grid>
      </Loader>
    </Fragment>
  );
};

export default WelcomeScreen;
