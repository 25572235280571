import styled from "styled-components";
import { useDispatch } from "react-redux";

import { DatePicker } from "@mui/x-date-pickers";
import { Grid, Typography } from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Autocomplete,
  LoadingButton,
  MandatoryNote,
  SelectField,
  TextField,
} from "../../../../components";
import { useAlert, useAxios } from "../../../../hooks";
import { debounce } from "lodash";

const TextFieldWithCSS = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 12.5px;
  }

  .MuiOutlinedInput-root {
    font-size: 15px;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.38);
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #f5f5f5;
    font-size: 15px;
    border-radius: 12.5px;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

function Form({
  formik,
  premises,
  departments,
  subDepartments,
  setCustomFields,
  setDepartments,
  setStepperLevel,
  setSubDepartments,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [timeOptions, setTimeOptions] = useState([]);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [disabledDate, setDisabledDate] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const fetchDates = async (newValue) => {
    try {
      const response = await axios({
        url: `/appointment/appointment/get-time-slots?department_id=${formik.values.department}&date=${newValue}&officer_id=${formik.values.officer}`,
        method: "GET",
      });

      if (response.status && response.data.length > 0) {
        const dates = response.data;
        setTimeOptions(dates);
      } else {
        dispatch(
          alert({
            type: "error",
            message: "Booking dates not available",
          })
        );
      }
    } catch (error) {
      console.error("Error in fetching available dates", error);
    }
  };

  const handleOfficerChange = (value) => {
    const selectedOfficer = value?.id;

    if (selectedOfficer) {
      axios({
        url:
          "admin/custom-fields/get-appointment-fields?business_id=" +
          officerOptions.filter((item) => item._id == selectedOfficer)[0]
            ?.business_id,
        disableError: true,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setCustomFields(response.data);
        }
      });

      formik.setFieldValue("officer", selectedOfficer);

      formik.setFieldValue(
        "department",
        officerOptions.filter((item) => item._id == selectedOfficer)[0]
          ?.department_name
      );

      formik.setFieldValue(
        "departmentID",
        officerOptions.filter((item) => item._id == selectedOfficer)[0]
          ?.department_id
      );

      formik.setFieldValue(
        "subDepartment",
        officerOptions.filter((item) => item._id == selectedOfficer)[0]
          .sub_department_name
      );
    }
  };

  const handleDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    formik.setFieldValue("premises", selectedDepartment);

    try {
      const response = await axios({
        url: `/appointment/appointment/get-departments?premises=${selectedDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setDepartments(response.data);
      }
    } catch (error) {
      console.error("Error in fetching office list", error);
    }
  };

  const handleOfficeChange = (event) => {
    const selectedSubDepartment = event.target.value;
    formik.setFieldValue("subDepartment", selectedSubDepartment);

    fetchOfficers(selectedSubDepartment);
  };

  const fetchOfficers = async (selectedSubDepartment) => {
    try {
      const response = await axios({
        url: `/appointment/appointment/get-officials?sub_department_id=${selectedSubDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setOfficerOptions(response.data);
      }
    } catch (error) {
      console.error("Error in fetching officer list", error);
    }
  };

  const handleDateChange = (newValue) => {
    formik.setFieldValue("time", "");
    setTimeOptions([]);

    let time = new Date(newValue);

    fetchDates(time.getTime() / 1000);

    formik.setFieldValue("date", newValue);
  };

  const handleSubDepartmentChange = async (event) => {
    const selectedDepartment = event.target.value;
    formik.setFieldValue("department", selectedDepartment);

    try {
      const response = await axios({
        url: `/appointment/appointment/get-sub-departments?department_id=${selectedDepartment}`,
        method: "GET",
      });
      if (response.status) {
        setSubDepartments(response.data);
      }
    } catch (error) {
      console.error("Error in fetching office list", error);
    }
  };

  const handleOfficer = async (searchKey = "") => {
    if (!formik.values.premises) return;

    axios({
      url: `/appointment/appointment/get-premises-officers?premises_id=${
        formik.values.premises
      }&search=${searchKey || ""}`,
      disableError: true,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setOfficerOptions(response.data);
      }
    });
  };

  useEffect(() => {
    if (formik.values.time.length > 0) {
      let arr = timeOptions.filter((time) => {
        return (
          time.slot_id === formik.values.time[0] ||
          time.slot_id === formik.values.time[formik.values.time.length - 1]
        );
      });

      formik.setFieldValue("slot1", arr[0]?.slot_time?.split(" ")[0]);
      formik.setFieldValue("slot2", arr[1]?.slot_time?.split(" ")[0]);
    }
  }, []);

  useEffect(() => {
    if (formik.values.premises) {
      handleOfficer();
    }
  }, [formik.values.premises]);

  const debouncedFetchOfficers = debounce((searchKey) => {
    handleOfficer(searchKey);
  }, 500);

  useEffect(() => {
    if (searchInput !== "") {
      debouncedFetchOfficers(searchInput);
    }
  }, [searchInput]);

  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "17.5px",
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{ fontWeight: "900", marginBottom: "15px" }}
          >
            Select Premises{"  "}
            <span style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}>
              *
            </span>
          </Typography>
          <SelectField
            label="Select Premises"
            name="premises"
            value={formik.values.premises}
            options={premises.map((premise) => ({
              id: premise._id,
              name: premise.name,
            }))}
            onChange={handleDepartmentChange}
            onBlur={formik.handleBlur}
            error={formik.touched.premises && Boolean(formik.errors.premises)}
            xs={12}
          />
        </Grid>

        {formik.values.premises && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", marginBottom: "15px" }}
            >
              Select Officer{"  "}
              <span
                style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}
              >
                *
              </span>
            </Typography>

            <Autocomplete
              name="officer"
              formikProps={formik}
              label="Select Officer"
              onBlur={formik.handleBlur}
              value={
                officerOptions.find(
                  (item) => item._id === formik.values.officer
                ) || null
              }
              onInputChange={(_, value, reason) => {
                if (reason === "clear") {
                  setSearchInput("");
                  formik.setFieldValue("officer", "");
                  handleOfficer("");
                } else {
                  setSearchInput(value);
                }
              }}
              defaults={{
                primaryKey: "id",
                displayLabel: "name",
              }}
              options={officerOptions.map((officer) => ({
                id: officer._id,
                name: officer.name,
              }))}
              onChange={(_, value) => {
                handleOfficerChange(value);
              }}
              onOpen={() => {
                if (officerOptions?.length === 0) {
                  handleOfficer();
                }
              }}
              error={formik.touched.officer && Boolean(formik.errors.officer)}
            />
          </Grid>
        )}

        {Boolean(formik.values.officer !== "") && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", marginBottom: "15px" }}
            >
              Select Department{"  "}
              <span
                style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}
              >
                *
              </span>
            </Typography>

            <TextField
              disabled={true}
              name="department"
              label="Select Department"
              value={formik.values.department}
              formikProps={formik}
            ></TextField>
          </Grid>
        )}

        {formik.values.department && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", marginBottom: "15px" }}
            >
              Select Sub-Department{"  "}
              <span
                style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}
              >
                *
              </span>
            </Typography>

            <TextField
              disabled={true}
              label="Select Sub-Department"
              name="subDepartment"
              value={formik.values.subDepartment}
              formikProps={formik}
            ></TextField>
          </Grid>
        )}
      </Grid>

      {formik.values.subDepartment && (
        <Fragment>
          <MandatoryNote />

          <LoadingButton
            onClick={() => {
              setStepperLevel(1);
            }}
          >
            Save & Proceed
          </LoadingButton>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Form;
