import {
  Button,
  Grid,
  Paper,
  Box,
  Typography,
  Divider,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import pages from "../../constants/pages";
import { AddCircle } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Fragment, useEffect, useState } from "react";
import { useAxios } from "../../hooks";
import { useSelector } from "react-redux";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 20vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const RecordCard = styled(Box)`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 10px;

  &:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
`;

export default function HotelBooking() {
  const axios = useAxios();
  const navigate = useNavigate();

  const { visitorData } = useSelector((state) => state.memberDetails);

  const [bookingList, setBookingList] = useState([]);

  const localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return (
      (item.includes(visitorData.ID) && item.includes("userPayload")) ||
      (item.includes(visitorData.ID) && item.includes("face&docs"))
    );
  });

  useEffect(() => {
    axios({
      url: "hotel/booking",
      method: "GET",
      disableError: true,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setBookingList(response.data);
      }
    });
  }, []);
  return (
    <>
      <OuterBox>
        <OuterPaper>
          <Grid container padding={"25px 0px"}>
            <Grid item container>
              <Typography variant="h3" margin={"10px 5px"} fontWeight={800}>
                Your Bookings
              </Typography>
              <Typography variant="body1" margin={"10px 5px 25px"}>
                View, manage, or cancel your bookings below.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  !digipassDataKey[0]
                    ? navigate(pages.Profile.route)
                    : navigate(pages.AddHotelBookingPage.path);
                }}
                startIcon={Boolean(digipassDataKey[0]) && <AddCircle />}
                sx={{
                  color: " #3936EF",
                  background: "white",
                  border: "2px solid #3936EF",
                  "&:hover": {
                    background: "white",
                  },
                }}
              >
                {!digipassDataKey[0] ? "Verify Profile" : "Add Booking"}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Typography
              variant="h5"
              margin={"10px 5px"}
              fontWeight={400}
              color={" #00000080"}
            >
              Bookings List
            </Typography>
          </Grid>
          <Grid container>
            <Fragment>
              {bookingList.map((item, index) => {
                return (
                  <RecordCard>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={2}>
                        <img
                          src="https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1739266773834_%E2%80%94Pngtree%E2%80%94no%20image%20vector%20illustration%20isolated_4979075.png"
                          width={"25px"}
                          height={"25px"}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          sx={{ marginBottom: "5px" }}
                        >
                          Booking Id:{" "}
                          <Tooltip title={item.booking_id}>
                            {item.booking_id.substring(0, 10) + "......"}
                          </Tooltip>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          <span style={{ color: "black" }}>
                            <Tooltip title={item.guest_name || ""}>
                              {item.guest_name?.substring(0, 10) + "...."}
                            </Tooltip>
                          </span>
                          &nbsp; &#10072; &nbsp;
                          <Tooltip title={item.hotel_name}>
                            {" "}
                            {item.hotel_name.substring(0, 10) + "...."}
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={2} textAlign="right">
                        <ArrowForwardIosIcon
                          sx={{ color: "#919191", fontSize: "15px" }}
                        />
                      </Grid>
                    </Grid>
                  </RecordCard>
                );
              })}
            </Fragment>
          </Grid>
        </OuterPaper>
      </OuterBox>
    </>
  );
}
