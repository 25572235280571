import { Fragment } from "react";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoIosArrowForward } from "react-icons/io";

import EditButton from ".././../assests/editButton.svg";
import User from "../../../Event Components/assests/user.svg";
import Docs from "../../../Event Components/assests/docs.svg";
import Date from "../../../Event Components/assests/date.svg";
import Chats from "../../../Event Components/assests/chats.svg";
import Error from "../../../Event Components/assests/error.svg";
import Location from "../../../Event Components/assests/location.svg";
import convertDateDayMonthYear from "../../../../utils/convertDateDayMonthYear";

function ReviewBook({ formik, setStepperLevel }) {
  const { visitorData } = useSelector((state) => state.memberDetails);
  return (
    <Fragment>
      <Grid container display={"flex"}>
        <Grid item xs={10}>
          <Typography variant="h6" fontWeight={600} sx={{ marginTop: "15px" }}>
            Review Details
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          display={"flex"}
          onClick={() => {
            setStepperLevel(2);
          }}
        >
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{ marginTop: "15px", color: "#3936EF" }}
          >
            Edit
          </Typography>
          <img
            src={EditButton}
            width={"20px"}
            height={"20px"}
            style={{ marginTop: "15px", marginLeft: "5px" }}
          ></img>
        </Grid>
      </Grid>

      <Grid
        item
        container
        sx={{
          border: "0.6px solid #0000001A",
          borderRadius: "8px",
          marginTop: "15px",
        }}
      >
        <Grid item container p={"7.5px 0px"}>
          {!!formik.values?.visitorData[0]?.organization && (
            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  alignItems: "center",
                }}
              >
                <img
                  src={Location}
                  width={"20px"}
                  height={"20px"}
                  style={{}}
                ></img>
              </Grid>

              <Grid
                item
                xs={10.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={"600"}
                  fontSize={"14px"}
                  sx={{ color: "rgb(0 0 0 / 66%)" }}
                >
                  {formik.values?.visitorData[0]?.organization}
                  {formik.values?.visitorData[0]?.designation}{" "}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!!formik.values?.slot2 && (
            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  alignItems: "center",
                }}
              >
                <img src={Date} width={"20px"} height={"20px"} style={{}}></img>
              </Grid>

              <Grid
                item
                xs={10.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={"600"}
                  fontSize={"14px"}
                  sx={{ color: "rgb(0 0 0 / 66%)" }}
                >
                  {convertDateDayMonthYear(
                    formik.values?.date?.getTime() / 1000
                  )}
                  {"  "}
                  at {"  "}
                  {formik.values?.slot1}
                  {"  "}-{"  "}
                  {formik.values?.slot2}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!!visitorData?.firstName && (
            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  alignItems: "center",
                }}
              >
                <img src={User} width={"20px"} height={"20px"} style={{}}></img>
              </Grid>

              <Grid
                item
                xs={10.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={"600"}
                  fontSize={"14px"}
                  sx={{ color: "rgb(0 0 0 / 66%)" }}
                >
                  {visitorData?.firstName} {visitorData?.lastName}
                </Typography>
              </Grid>
            </Grid>
          )}

          {!!formik.values.PurposeVisit && (
            <Grid item container p={"5px 10px 10px 15px"}>
              <Grid
                item
                xs={1.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  alignItems: "center",
                }}
              >
                <img
                  src={Error}
                  width={"17px"}
                  height={"17px"}
                  style={{}}
                ></img>
              </Grid>

              <Grid
                item
                xs={10.5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  fontWeight={"600"}
                  fontSize={"14px"}
                  sx={{ color: "rgb(0 0 0 / 66%)" }}
                >
                  {formik.values.PurposeVisit}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: "20px" }}>
        <Typography variant="h6" fontWeight={600} sx={{ marginTop: "15px" }}>
          {"Members Visiting".toUpperCase()}
        </Typography>
      </Grid>

      {formik.values?.visitorData?.map((item) => {
        return (
          <Fragment>
            {item.isDependent === undefined && (
              <Grid
                item
                container
                sx={{
                  border: "0.6px solid #0000001A",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              >
                <Grid item container p={"7.5px 0px"}>
                  <Grid item container p={"5px 10px 10px 15px"}>
                    <Grid
                      item
                      xs={2}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <img
                        width={"20px"}
                        src={
                          "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                        }
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "45%",
                        }}
                      ></img>
                    </Grid>
                    <Grid
                      item
                      xs={8.5}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={"bolder"}
                        fontSize={"14px"}
                      >
                        {item.name}
                      </Typography>

                      <Typography
                        variant="body1"
                        fontWeight={400}
                        fontSize={"12px"}
                      >
                        {item.mobile}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        borderRadius: "4px",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        // window.location.replace(eventDetail?.address.map_link);
                      }}
                    >
                      <img
                        src={EditButton}
                        width={"20px"}
                        height={"20px"}
                        style={{}}
                        onClick={() => {
                          setStepperLevel(2);
                        }}
                      ></img>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Fragment>
        );
      })}

      {formik.values?.visitorData?.map((item, index) => {
        return (
          <Fragment>
            {item.isDependent === true && item.isDependent !== undefined && (
              <Fragment>
                <Grid container sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      alignContent: "center",
                      textAlign: "left",
                      color: "gray",
                      marginLeft: {
                        sl: "20px",
                      },
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: "Dependent Members".toUpperCase() || "",
                      }}
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  sx={{
                    border: "0.6px solid #0000001A",
                    borderRadius: "8px",
                    marginTop: "7px",
                  }}
                >
                  <Grid item container p={"7.5px 0px"}>
                    <Grid item container p={"5px 10px 10px 15px"}>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      >
                        <img
                          width={"20px"}
                          src={
                            "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                          }
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "45%",
                          }}
                        ></img>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={"bolder"}
                          fontSize={"14px"}
                        >
                          {item.name}
                        </Typography>

                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize={"12px"}
                        >
                          {item.mobile}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        <RiDeleteBin6Line
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#919191",
                          }}
                          onClick={() => {
                            let arr = [...formik.values.visitorData];
                            arr.splice(index, 1);
                            formik.setFieldValue("visitorData", arr);
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={EditButton}
                          width={"20px"}
                          height={"20px"}
                          style={{}}
                          onClick={() => {
                            setStepperLevel(3);
                          }}
                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        );
      })}

      {formik.values?.visitorData?.map((item, index) => {
        return (
          <Fragment>
            {item.isDependent === false && item.isDependent !== undefined && (
              <Fragment>
                <Grid container sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      alignContent: "center",
                      textAlign: "left",
                      color: "gray",
                      marginLeft: {
                        sl: "20px",
                      },
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: "Other Members".toUpperCase() || "",
                      }}
                    />
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  sx={{
                    border: "0.6px solid #0000001A",
                    borderRadius: "8px",
                    marginTop: "7px",
                  }}
                >
                  <Grid item container p={"7.5px 0px"}>
                    <Grid item container p={"5px 10px 10px 15px"}>
                      <Grid
                        item
                        xs={2}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      >
                        <img
                          width={"20px"}
                          src={
                            "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                          }
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "45%",
                          }}
                        ></img>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={"bolder"}
                          fontSize={"14px"}
                        >
                          {item.name}
                        </Typography>

                        <Typography
                          variant="body1"
                          fontWeight={400}
                          fontSize={"12px"}
                        >
                          {item.mobile}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                      >
                        <RiDeleteBin6Line
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "#919191",
                          }}
                          onClick={() => {
                            let arr = [...formik.values.visitorData];
                            arr.splice(index, 1);
                            formik.setFieldValue("visitorData", arr);
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1.5}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: "4px",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setStepperLevel(3);
                        }}
                      >
                        <img
                          src={EditButton}
                          width={"20px"}
                          height={"20px"}
                          style={{}}
                        ></img>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </Fragment>
        );
      })}

      <Typography variant="h6" fontWeight={600} sx={{ marginTop: "15px" }}>
        More Info
      </Typography>

      <Grid item container marginBottom={"30px"}>
        <Grid item container>
          <Grid
            item
            container
            p={"5px 10px 10px 15px"}
            sx={{
              border: "0.6px solid #0000001A",
              borderRadius: "8px",
              marginTop: "15px",
            }}
          >
            <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
              <img width={"20px"} src={Docs}></img>
            </Grid>
            <Grid item xs={9.5} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                Terms & Conditions
              </Typography>
            </Grid>

            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                justifyContent: "center",

                borderRadius: "4px",
                padding: "7px 0px",
              }}
            >
              <IoIosArrowForward fontSize={"20px"} color="#919191" />
            </Grid>
          </Grid>

          <Grid
            item
            container
            p={"5px 10px 10px 15px"}
            sx={{
              border: "0.6px solid #0000001A",
              borderRadius: "8px",
              marginTop: "15px",
            }}
          >
            <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
              <img width={"20px"} src={Chats}></img>
            </Grid>
            <Grid item xs={9.5} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" fontWeight={400} fontSize={"14px"}>
                Frequently Asked Questions{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{
                display: "flex",
                justifyContent: "center",

                borderRadius: "4px",
                padding: "7px 0px",
              }}
            >
              <IoIosArrowForward fontSize={"20px"} color="#919191" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        xs={12}
      >
        <LoadingButton
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            fontStyle: "normal",
            borderRadius: "8px",
            backgroundColor: "rgba(57, 54, 239, 1)",
            color: "#FFF",
            width: "100%",
            "@media (min-width: 1000px)": {
              width: "100%",
            },
            "&:hover": {
              backgroundColor: "rgba(57, 54, 239, 1)",
              borderRadius: "8px",
            },
            marginBottom: "20px",
          }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          Request Appointment
        </LoadingButton>
      </Grid>
    </Fragment>
  );
}

export default ReviewBook;
