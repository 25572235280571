import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";

import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Table, TableBody, TableCell, TableRow, Paper } from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import convertDate from "../../utils/convertDate";

import LoadingButton from "../../components/LoadingButton";
import { useSelector } from "react-redux";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const TableCells = styled(TableCell)`
  // display: flex;
`;

const EventBox = styled(Grid)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  margin: 0px !important;
  width: 100%;
  height: 25vh !important;
  border-top-left-radius: 12.5px;
  border-top-right-radius: 12.5px;
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12.5px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 50%,
    rgb(255 255 255 / 93%) 100%
  );
`;

export default function HotelSummary() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { hotelID, bookingID } = useParams();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [guestDetails, setGuestDetails] = useState([]);
  const [userData, setUserData] = useState(state);

  const [badges, setBadges] = useState([]);

  useEffect(() => {
    axios({
      url: `/hotel/booking/guest/` + bookingID,
      disableRedirect: true,
      disableError: true,
    }).then((response) => {
      if (response.status) {
        setGuestDetails(response.data);
      }
    });

    // axios({
    //   url: `admin/events/event-details?event_id=${eventID}`,
    //   method: "GET",
    //   customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
    // }).then((response) => {
    //   if (response.status) {
    //     response?.data?.button_color &&
    //       sessionStorage.setItem("buttonColor", response.data.button_color);
    //     response?.data?.background_color &&
    //       sessionStorage.setItem(
    //         "buttonBackground",
    //         response.data.background_color
    //       );
    //     setguestDetails(response.data);
    //   }
    // });

    // if (!Boolean(state?.match_percentage)) {
    //   axios({
    //     url:
    //       "event/visitor/check-booking" +
    //       "?event_id=" +
    //       eventID +
    //       "&visitor_id=" +
    //       id,
    //     disableRedirect: true,
    //     method: "GET",
    //   }).then((response) => {
    //     if (response.status) {
    //       setUserData({ userData: response.data });
    //       axios({
    //         url: `event/verify/booking-status`,
    //         method: "POST",
    //         disableRedirect: true,
    //         data: {
    //           name: "",
    //           face_data: "",
    //           booking_id: response.data[0].booking_id,
    //         },
    //       });
    //     }
    //   });
    // }
  }, [axios]);

  const getButtonText = (item) => {
    if (!Boolean(item)) return "Verification Pending";
    if (item?.is_doc_verified === false) return "Verification Pending";
    if (item?.consent_status === false) return "Consent Pending";

    return false;
  };

  const handleClick = (item) => {
    const { page_link = null } = item || {};

    navigate(page_link);
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCells sx={{ width: "40%" }}>
        <Typography
          variant="body1"
          style={{ color: "rgb(150 150 150)" }}
          fontWeight={900}
        >
          {label}
        </Typography>
      </TableCells>
      <TableCells sx={{ width: "60%" }}>
        <Tooltip title={value}>
          <Typography
            variant="h6"
            fontWeight={900}
            sx={{
              color: value === "pending" ? "red" : "black",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "1rem",
              "@media (max-width: 425px)": {
                fontSize: "0.875rem",
              },
            }}
          >
            {value?.toUpperCase()}
          </Typography>
        </Tooltip>
      </TableCells>
    </TableRow>
  );

  return (
    <OuterBox>
      {Array(guestDetails[0]?.guests_count)
        .fill(true)
        .map((item, index) => {
          return (
            <OuterPaper
              sx={{
                padding: { xs: "30px 0px 0px 0px", sm: "30px 80px 0px 80px" },
              }}
            >
              <Grid
                container
                flexDirection="column"
                padding={{ xs: "0px", sm: "0px", md: "0px" }}
                sx={{ height: "100%" }}
              >
                <Grid
                  item
                  container
                  sx={{
                    height: "100%",
                    position: "relative",
                    borderRadius: "12.5px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    justifyContent: "center",
                  }}
                >
                  <Grid item padding="20px">
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: "bolder",
                        "-webkit-text-stroke-width": ".5px",
                      }}
                    >
                      Hotel Summary
                    </Typography>
                  </Grid>

                  {guestDetails[index]?.hotel_name && (
                    <Grid item padding="20px">
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: "bolder",
                          "-webkit-text-stroke-width": ".5px",
                        }}
                      >
                        {guestDetails[index]?.hotel_name}
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{
                          color: "#a6a6a6",
                          fontWeight: "500",
                          "-webkit-text-stroke-width": ".25px",
                        }}
                      >
                        Address :- {guestDetails[index]?.full_address.address}
                      </Typography>
                    </Grid>
                  )}

                  <Grid item sx={{ width: "100%" }} padding="30px">
                    <Grid
                      item
                      container
                      justifyContent="center"
                      flexDirection={"column"}
                      sx={{ overflow: "hidden" }}
                    >
                      <Grid
                        item
                        container
                        xs={12}
                        sx={{
                          alignContent: "center",
                          justifyContent: "space-between",
                          textAlign: { xs: "center", sm: "start" },
                        }}
                      >
                        <Grid item xs={12}>
                          <Table>
                            <TableBody>
                              {renderInfoItem(
                                "Name",
                                guestDetails[index]?.first_name || "No Data"
                              )}
                              {renderInfoItem(
                                "Phone Number",
                                guestDetails[index]?.phone_number || "No Data"
                              )}
                              {renderInfoItem(
                                "Email ID",
                                guestDetails[index]?.email_id || "No Data"
                              )}
                            </TableBody>
                          </Table>
                        </Grid>

                        {Boolean(
                          guestDetails[index]?.qr_link &&
                            !guestDetails[index]?.send_consent
                        ) && (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              justifyContent:
                                !guestDetails[index].data_qr_link && "center",
                              border: " 1px solid rgb(232, 222, 207)",
                              borderRadius: "12.5px",
                              overflowY: "scroll",
                              scrollbarWidth: "none",
                              flexDirection: "row",
                              padding: "10px 5px !important",
                            }}
                            xs={12}
                            width={0}
                          >
                            <img
                              src={guestDetails[index]?.qr_link}
                              className="QrCodeImage"
                              style={{ margin: "0px 20px", height: "80px" }}
                            />
                            {guestDetails[index].data_qr_link && (
                              <img
                                src={guestDetails[index].data_qr_link}
                                className="QrCodeImage"
                                style={{ margin: "0px 20px", height: "80px" }}
                              />
                            )}
                          </Grid>
                        )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: { xs: "center", sm: "start" } }}
                      >
                        <Table>
                          <TableBody>
                            {renderInfoItem(
                              "Booking ID",
                              guestDetails[index]?.booking_id || "No Data"
                            )}
                            {renderInfoItem(
                              "Check-In Time",
                              convertDate(
                                guestDetails[index]?.checkin_datetime
                              ) || "No Data"
                            )}
                            {renderInfoItem(
                              "Check-Out Time",
                              convertDate(
                                guestDetails[index]?.checkout_datetime
                              ) || "No Data"
                            )}
                            {renderInfoItem(
                              "Booked By",

                              guestDetails[index]?.booked_by || "No Data"
                            )}
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="body1"
                          sx={{
                            borderRadius: "10px",
                            textAlign: "center",
                            padding: "5px",
                            background: badges.filter((item) => {
                              return (
                                guestDetails[index]?.badge_id === item?.badge_id
                              );
                            })[0]?.color,
                          }}
                        >
                          {
                            badges.filter((item) => {
                              return (
                                guestDetails[index]?.badge_id == item?.badge_id
                              );
                            })[0]?.name
                          }
                        </Typography>
                      </Grid>

                      {getButtonText(guestDetails[index]) && (
                        <Grid
                          item
                          xs={12}
                          display={"flex"}
                          justifyContent={"center"}
                          mt={2}
                        >
                          <LoadingButton
                            variant="contained"
                            onClick={() => handleClick(guestDetails[index])}
                            disabled={
                              visitorData.ID !== guestDetails[index]?.visitor_id
                            }
                          >
                            {guestDetails[index]?.user_status
                              .replace("_", " ")
                              .toUpperCase()}
                          </LoadingButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </OuterPaper>
          );
        })}
    </OuterBox>
  );
}
