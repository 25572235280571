import React from "react";
import { Grid, Typography } from "@mui/material";

const SubheaderSection = ({
  textAlign,
  title,
  titleProps,
  content,
  contentStyle,
  titleColor = "#1E1E1E",
  variant = "h2",
}) => {
  return (
    <Grid container className="subheaderSectionBox">
      <Typography
        variant={variant}
        sx={{
          marginBottom: "5px",
          textAlign: textAlign || "left",
          color: titleColor,
          // fontFamily: "'Poppins'",
          ...titleProps,
        }}
      >
        {title || ""}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          alignContent: "center",
          textAlign: textAlign || "left",
          color: "gray",
          marginLeft: {
            ...(textAlign !== "start" &&
              textAlign !== "left" && {
                sl: "20px",
              }),
          },
          ...contentStyle,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: content || "" }} />
      </Typography>
    </Grid>
  );
};

export default SubheaderSection;
