import { Formik } from "formik";
import { Fragment } from "react";
import styled from "styled-components/macro";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { TableCell } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAlert, useAxios } from "../../hooks";
import DependentPopup from "./Dependent";
import pages from "../../constants/pages";
import { TextField } from "../../components";
import CustomField from "./CustomField";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 17px;
  box-sizing: border-box;
  background: #ffffff;
`;

const OuterPaper = styled(Box)`
  width: 100%;
  border-radius: 15px;
  min-height: 50vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
`;

const TableCells = styled(TableCell)`
  // display: flex;
`;

const EventBox = styled(Grid)`
  background-image: url(${(props) => props.backgroundImage});
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  margin: 0px !important;
  width: 100%;
  height: 25vh !important;
  border-top-lefimport pages from './../../constants/pages';
t-radius: 12.5px;
  border-top-right-radius: 12.5px;
`;

const GradientOverlay = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12.5px;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 50%,
    rgb(255 255 255 / 93%) 100%
  );
`;

export default function InviteUser() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { alert } = useAlert();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { eventID, id } = useParams();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const queryParams = new URLSearchParams(location.search);

  const type = queryParams.get("type");
  const hotelID = queryParams.get("hotelid");
  const guestsCount = queryParams.get("guestno");
  const officerId = queryParams.get("officer_id");
  const appointmentId = queryParams.get("appointment_id");

  const [userData, setUserData] = useState([]);
  const [badgeOptions, setBadgeOptions] = useState([]);
  const [dependentList, setDependentList] = useState([]);
  const [dependentPopUp, setDependentPopUp] = useState(false);
  const [visitorVaild, setVisitorVaild] = useState([]);
  const [formData, setFormData] = useState({
    visitors: [
      {
        name: "",
        email_id: "",
        phone_number: "",
        badge: "",
        is_verified: false,
        edit: false,
        guestType: undefined,
      },
    ],
  });
  const [customFields, setCustomFields] = useState([]);

  useEffect(() => {
    axios({
      url:
        "event/visitor/get-dependent-list?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDependentList(response.data || []);
      }
    });
    if (type == "hotel") {
      let data = Array(parseInt(guestsCount))
        .fill(true)
        .map((item, index) => {
          return {
            name:
              index == 0 ? visitorData?.firstName + visitorData?.lastName : "",
            email_id: index == 0 ? visitorData?.emailID : "",
            phone_number: index == 0 ? visitorData?.phoneNumber : "",
            badge_id: "",
            is_verified: visitorData?.isVerified || false,
            edit: index == 0 ? true : false,
            guestType: undefined,
          };
        });

      let array = Array(parseInt(guestsCount)).fill(true);
      array[0] = false;
      setVisitorVaild(array);

      setFormData({ visitors: data });

      // axios({
      //   url: `event/booking/get-visitor-badges?visitor_id=${id}&event_id=${eventID}`,
      //   method: "GET",
      //   disableRedirect: true,
      // })
      //   .then((response) => {
      //     if (response.status) {
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error in fetching events list", error);
      //   });
    } else if (type == "appointment") {
      axios({
        url: "appointment/appointment/check-appointment",
        disableRedirect: true,
        method: "POST",
        data: {
          officer_id: officerId,
          appointment_id: appointmentId,
        },
      }).then((response) => {
        if (response.status) {
          axios({
            url:
              "admin/custom-fields/get-appointment-fields?business_id=" +
              response.data[0]?.journey_status?.business_id,
            disableError: true,
            disableRedirect: true,
          }).then((response) => {
            if (response.status) {
              setCustomFields(response.data);
            }
          });
          setUserData(response.data);
          let dataLength = response.data[0]?.total_bookings;

          let data = Array(dataLength)
            .fill(true)
            .map((item, index) => {
              return {
                name:
                  response.data[index]?.visitor_info.first_name +
                    response.data[index]?.visitor_info.last_name || "",
                email_id: response.data[index]?.visitor_info.email_id || "",
                phone_number:
                  response.data[index]?.visitor_info.phone_number || "",
                badge_id: "",
                is_verified:
                  response.data[index]?.visitor_info?.is_verified || false,

                edit: response.data[index]?.visitor_info?.first_name
                  ? true
                  : false,
                guestType:
                  response.data[index]?.visitor_info?.phone_number ===
                  visitorData?.phoneNumber
                    ? 1
                    : 0,
                ...(response.data[index]?.visitor_info?.phone_number ===
                  visitorData?.phoneNumber && {
                  dependent: response.data[index]?.visitor_id,
                }),
              };
            });
          let array = Array(dataLength).fill(true);
          array[0] = false;
          setVisitorVaild(array);

          setFormData({ visitors: data });
        }
      });
    } else {
      axios({
        url: `event/booking/get-visitor-badges?visitor_id=${id}&event_id=${eventID}`,
        method: "GET",
        disableRedirect: true,
      })
        .then((response) => {
          if (response.status) {
            setBadgeOptions(response.data);
          }
        })
        .catch((error) => {
          console.error("Error in fetching events list", error);
        });

      axios({
        url: "admin/custom-fields/get-event-fields?event_id=" + eventID,
        disableError: true,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setCustomFields(response.data);
        }
      });

      axios({
        url:
          "event/visitor/check-booking" +
          "?event_id=" +
          eventID +
          "&visitor_id=" +
          id,
        disableRedirect: true,
        method: "GET",
      }).then((response) => {
        if (response.status) {
          setUserData(response.data);
          let dataLength = response.data[0]?.total_number_bookings;

          let data = Array(dataLength)
            .fill(true)
            .map((item, index) => {
              return {
                name:
                  response.data[index]?.visitor_info.first_name +
                    response.data[index]?.visitor_info.last_name || "",
                email_id: response.data[index]?.visitor_info.email_id || "",
                phone_number:
                  response.data[index]?.visitor_info.phone_number || "",
                badge_id: response.data[index]?.badge_id || "",
                is_verified:
                  response.data[index]?.visitor_info?.is_verified || false,

                edit: response.data[index]?.visitor_info?.first_name
                  ? true
                  : false,
                guestType:
                  response.data[index]?.visitor_info?.phone_number ===
                  visitorData?.phoneNumber
                    ? 1
                    : 0,
                ...(response.data[index]?.visitor_info?.phone_number ===
                  visitorData?.phoneNumber && {
                  dependent: response.data[index]?.visitor_id,
                }),
              };
            });
          let array = Array(dataLength).fill(true);
          array[0] = false;
          setVisitorVaild(array);

          setFormData({ visitors: data });
        }
      });
    }
  }, [axios, eventID]);

  function handleUpdateBadge(ID) {
    let badgeDetails = badgeOptions.filter((item) => item.badge_id === ID);

    axios({
      url: `/event/booking/update-visitor-badge`,
      method: "POST",
      data: {
        booking_id: userData[0].booking_id,
        badge_id: badgeDetails[0].badge_id,
        amount: badgeDetails[0].amount,
      },
    }).then((response) => {
      if (response.status) {
        axios({
          url:
            "event/visitor/check-booking" +
            "?event_id=" +
            eventID +
            "&visitor_id=" +
            id,
          disableRedirect: true,
          method: "GET",
        }).then((response) => {
          if (response.status) {
            // setUserData({ userData: response.data });
          }
        });
      }
    });
  }

  window.addEventListener("storage", () => {
    if (localStorage.getItem("windowClosed") === "true") {
      axios({
        url:
          "event/visitor/get-dependent-list?phone_number=" +
          visitorData.phoneNumber,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDependentList(response.data || []);
        }
      });
      localStorage.setItem("windowClosed", false);
    }
  });

  const validationSchema = Yup.object({
    visitors: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Name is required"),
        email_id: Yup.string()
          // .email("Invalid email")
          .required("Email is required"),
        phone_number: Yup.string()
          .matches(
            /^[0-9]{10,12}$/, // This regex checks for exactly 10 digits
            "Phone number must be exactly 10 digits"
          )
          .required("Phone number is required"),
        ...(type == null && {
          badge_id: Yup.string().required("Badge is required"),
        }),
      })
    ),
  });

  return (
    <OuterBox>
      <Formik
        initialValues={formData}
        validationSchema={validationSchema}
        validateOnMount
        enableReinitialize
        onSubmit={(formData) => {
          if (type == null) {
            const visitor = formData.visitors
              .map((item, index) => {
                return (
                  index !== 0 && {
                    name: item.name,
                    phone_number:
                      item.phone_number.length == 10
                        ? "91" + item.phone_number
                        : item.phone_number.length > 10
                        ? item.phone_number.substring(2)
                        : item.phone_number,
                    email_id: item.email_id,
                    is_dependent: item.guestType == 0 ? false : true,
                    badge_id: item.badge_id,
                    ...(Boolean(
                      formData?.customField?.length != 0 &&
                        formData?.customField
                    ) && {
                      ...formData?.customField[index],
                    }),
                  }
                );
              })
              .filter((item) => item && item);

            axios({
              url: "event/booking/book-visitor-event",
              method: "POST",
              data: {
                visitors: visitor,
                event_id: userData[0].event_id,
                total_price: userData[0].total_amount,
                reference_visitor_id: userData[0].visitor_info?._id,
                total_bookings: userData[0]?.total_number_bookings,
                reference_id: userData[0].booking_reference_id,
                transaction_id: userData[0].transaction_id,
                order_id: userData[0].order_id,
              },
            }).then((response) => {
              if (response.status) {
                axios({
                  url:
                    "event/visitor/check-booking" +
                    "?event_id=" +
                    userData[0].event_id +
                    "&visitor_id=" +
                    userData[0].visitor_info?._id,
                  disableRedirect: true,
                  method: "GET",
                }).then((response) => {
                  if (response.status) {
                    navigate(
                      pages.EventSummary.route +
                        response.data[0].event_id +
                        "/" +
                        response.data[0].visitor_id,
                      {
                        state: {
                          userData: response.data,
                        },
                      }
                    );
                  }
                });
              }
            });
          } else if (type == "hotel") {
            const guestList = formData.visitors
              .map((item, index) => {
                return (
                  index !== 0 && {
                    name: item.name,
                    phone_number:
                      item.phone_number.length == 10
                        ? "91" + item.phone_number
                        : item.phone_number.length > 10
                        ? item.phone_number.substring(2)
                        : item.phone_number,
                    email_id: item.email_id,
                    is_dependent: item.guestType == 0 ? false : true,
                  }
                );
              })
              .filter((item) => item && item);

            axios({
              url: "hotel/booking/guest/add",
              method: "POST",
              data: {
                booking_ref_id: eventID,
                hotel_id: hotelID,
                guests: guestList,
              },
            }).then((response) => {
              if (response.status) {
                navigate(pages.HotelSummary.path + hotelID + "/" + eventID, {
                  state: { guestData: response.data },
                });
              }
            });
          } else if (type == "appointment") {
            const guestList = formData.visitors
              .map((item, index) => {
                return (
                  index !== 0 && {
                    name: item.name,
                    mobile:
                      item.phone_number.length == 10
                        ? "91" + item.phone_number
                        : item.phone_number.length > 10
                        ? item.phone_number.substring(2)
                        : item.phone_number,
                    email_id: item.email_id,
                    is_dependent: item.guestType == 0 ? false : true,
                    appointment_date: 1743445800,
                    slot_id: userData[0]?.slot_id,
                    total_bookings: userData[0].total_bookings,
                    officer_id: userData[0].officer_id,
                    ...(Boolean(
                      formData?.customField?.length != 0 &&
                        formData?.customField
                    ) && {
                      ...formData?.customField[index],
                    }),
                  }
                );
              })
              .filter((item) => item && item);

            axios({
              url: "appointment/appointment/add-appointment-guest",
              method: "POST",
              data: {
                reference_visitor_id: userData[0].visitor_id,
                reference_appointment_id: userData[0].appointment_id,
                visitors: guestList,
              },
            }).then((response) => {
              if (response.status) {
                navigate(
                  `${pages.AppointmentSummary?.route}?officer_id=${userData[0].officer_id}&appointment_id=${userData[0].appointment_id}`
                );
              }
            });
          }
        }}
      >
        {(formikProps) => {
          return (
            <Fragment>
              {Array(formData.visitors.length)
                .fill(true)
                ?.map((item, index) => {
                  function handleAddDependent() {
                    formikProps.setFieldValue(
                      `visitors[${index}].phone_number`,
                      visitorData.phoneNumber
                    );
                    axios({
                      url: "event/visitor/add-dependent",
                      method: "POST",
                      data: {
                        phone_number: visitorData.phoneNumber,
                        name: formikProps.values?.visitors[index]?.name,
                        email_id: formikProps.values?.visitors[index]?.email_id,
                      },
                    })
                      .then((response) => {
                        if (response.status) {
                          dispatch(
                            alert({
                              type: "success",
                              message: response.message.displayMessage,
                            })
                          );

                          axios({
                            url:
                              "event/visitor/get-dependent-list?phone_number=" +
                              visitorData.phoneNumber,
                            disableRedirect: true,
                          }).then((response) => {
                            if (response.status) {
                              setDependentList(response.data || []);
                            }
                          });
                        }
                        formikProps.setFieldValue(
                          `visitors[${index}].dependent`,
                          response.data._id
                        );
                        localStorage.setItem("windowClosed", false);
                        window.open(
                          userData[0]?.document_verification
                            ? `${pages.Verification?.route}?visitor_id=${
                                response.data._id
                              }&appointment_id=${-1}&officer_id=${-1}&popup=true`
                            : `${pages.checkLiveliness?.route}?visitor_id=${
                                response.data._id
                              }&appointment_id=${-1}&officer_id=${-1}&popup=true&doc_face=false`,
                          "",
                          "width=400,height=600"
                        );

                        setLoading(false);
                      })
                      .catch((error) => {
                        setLoading(false);
                      });
                  }

                  function handleSaveDependent() {
                    localStorage.setItem("windowClosed", false);
                    window.open(
                      userData[0]?.document_verification
                        ? `${pages.Verification?.route}?visitor_id=${
                            formikProps.values.visitors[index].dependent
                          }&appointment_id=${-1}&officer_id=${-1}&popup=true`
                        : `${pages.checkLiveliness?.route}?visitor_id=${
                            formikProps.values.visitors[index].dependent
                          }&appointment_id=${-1}&officer_id=${-1}&popup=true&doc_face=false`,
                      "",
                      "width=400,height=600"
                    );
                  }

                  const localStorageData = { ...localStorage };

                  const visitorDataKey = Object.keys(localStorageData).filter(
                    (item) => {
                      return item.includes(
                        formikProps.values?.visitors[index]?.dependent &&
                          window.btoa(
                            formikProps.values?.visitors[index]?.dependent
                          )
                      );
                    }
                  );

                  return (
                    <Grid item container>
                      <Fragment>
                        <Grid
                          container
                          spacing={2}
                          sx={{
                            border: "1px solid black",
                            padding: "10px",
                            borderRadius: "8px",
                            margin: "10px 0px",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: "0px",
                            }}
                          >
                            <Typography variant="body1" fontWeight={700}>
                              Person {index + 1}
                            </Typography>

                            {formikProps.values?.visitors[index]?.name && (
                              <Fragment>
                                {formikProps.values?.visitors[index]
                                  ?.is_verified ||
                                (dependentList[index - 1]?.is_verified &&
                                  localStorage.getItem(visitorDataKey[0]) &&
                                  formikProps.values?.visitors[index]
                                    .dependent != 0) ? (
                                  <Tooltip title="Verified">
                                    <VerifiedIcon sx={{ color: "green" }} />
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Not Verified User">
                                    <NewReleasesIcon sx={{ color: "red" }} />
                                  </Tooltip>
                                )}
                              </Fragment>
                            )}
                          </Grid>

                          {index !== 0 && (
                            <Grid item xs={12}>
                              <Typography variant="body1" fontWeight={700}>
                                Select Guest Type
                              </Typography>

                              <TextField
                                select
                                size="small"
                                name={`visitors[${index}].guestType`}
                                placeholder="Select Guest Type"
                                formikProps={formikProps}
                                onChange={(e) => {
                                  formikProps.setFieldValue(
                                    `visitors[${index}].guestType`,
                                    e.target.value
                                  );

                                  formikProps.setFieldValue(
                                    `visitors[${index}].dependent`,
                                    null
                                  );

                                  formikProps.setFieldValue(
                                    `visitors[${index}].is_dependent`,
                                    true
                                  );

                                  formikProps.setFieldValue(
                                    `visitors[${index}].name`,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    `visitors[${index}].email_id`,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    `visitors[${index}].phone_number`,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    `visitors[${index}].is_verified`,
                                    false
                                  );
                                }}
                                disabled={
                                  formikProps.values?.visitors[index]?.edit
                                }
                              >
                                <MenuItem value={0}>New User</MenuItem>
                                <MenuItem value={1}>Dependent</MenuItem>
                              </TextField>
                            </Grid>
                          )}

                          <Grid item xs={12}>
                            {visitorData?.dependentList &&
                              formikProps.values?.visitors[index]?.guestType ===
                                1 &&
                              index !== 0 && (
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <TextField
                                    select
                                    size="small"
                                    name={`visitors[${index}].dependent`}
                                    placeholder="Select Dependent"
                                    label="Select Dependent"
                                    formikProps={formikProps}
                                    onChange={(e) => {
                                      if (e.target.value === 0) {
                                        formikProps.setFieldValue(
                                          `visitors[${index}].dependent`,
                                          e.target.value
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].is_dependent`,
                                          true
                                        );

                                        formikProps.setFieldValue(
                                          `visitors[${index}].name`,
                                          ""
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].email_id`,
                                          ""
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].phone_number`,
                                          visitorData.phoneNumber
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].is_verified`,
                                          false
                                        );
                                      } else {
                                        let dependent = dependentList.filter(
                                          (item, index) =>
                                            item._id == e.target.value
                                        );

                                        formikProps.setFieldValue(
                                          `visitors[${index}].dependent`,
                                          e.target.value
                                        );

                                        formikProps.setFieldValue(
                                          `visitors[${index}].is_dependent`,
                                          true
                                        );

                                        formikProps.setFieldValue(
                                          `visitors[${index}].name`,
                                          dependent[0]?.first_name +
                                            " " +
                                            dependent[0]?.last_name
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].email_id`,
                                          dependent[0]?.email_id
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].phone_number`,
                                          dependent[0]?.phone_number
                                        );
                                        formikProps.setFieldValue(
                                          `visitors[${index}].is_verified`,
                                          dependent[0]?.is_verified
                                        );
                                      }
                                    }}
                                    disabled={
                                      formikProps.values?.visitors[index]?.edit
                                    }
                                  >
                                    {dependentList.length < 4 && (
                                      <MenuItem value={0}>
                                        Add New Dependent
                                      </MenuItem>
                                    )}
                                    {dependentList.map((item, index) => {
                                      return (
                                        <MenuItem key={index} value={item._id}>
                                          {item.first_name}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                  {/* <AddIcon
                                    onClick={() => {
                                      setDependentPopUp(true);
                                    }}
                                    sx={{
                                      fontSize: "25px",
                                      border: "1px solid black",
                                      borderRadius: "8px",
                                    }}
                                  /> */}
                                </Grid>
                              )}
                          </Grid>

                          {(index != 0
                            ? formikProps.values.visitors[index]?.guestType !==
                              undefined
                            : true) && (
                            <Fragment>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  Enter Name
                                </Typography>

                                <TextField
                                  name={`visitors[${index}].name`}
                                  placeholder="Enter Name"
                                  size="small"
                                  formikProps={formikProps}
                                  disabled={
                                    formikProps.values?.visitors[index]?.edit
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="body1">
                                  Enter Email
                                </Typography>
                                <TextField
                                  size="small"
                                  name={`visitors[${index}].email_id`}
                                  placeholder="Enter Email"
                                  formikProps={formikProps}
                                  disabled={
                                    formikProps.values?.visitors[index]?.edit
                                  }
                                />
                              </Grid>
                              <Grid item xs={type == null ? 6 : 12}>
                                <Typography variant="body1">
                                  Enter Phone Number
                                </Typography>
                                <TextField
                                  size="small"
                                  name={`visitors[${index}].phone_number`}
                                  placeholder="Enter Phone Number"
                                  formikProps={formikProps}
                                  disabled={
                                    formikProps.values?.visitors[index]?.edit
                                  }
                                />
                              </Grid>

                              {type == null && (
                                <Grid item xs={6}>
                                  <Typography variant="body1">
                                    Select Badge
                                  </Typography>
                                  <TextField
                                    select
                                    size="small"
                                    name={`visitors[${index}].badge_id`}
                                    placeholder="Select Badge"
                                    formikProps={formikProps}
                                    onChange={(e) => {
                                      formikProps.setFieldValue(
                                        `visitors[${index}].badge_id`,
                                        e.target.value
                                      );

                                      index === 0 &&
                                        handleUpdateBadge(e.target.value);
                                    }}
                                  >
                                    {badgeOptions.map((item, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={item.badge_id}
                                        >
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextField>
                                </Grid>
                              )}

                              {index !== 0 && (
                                <CustomField
                                  formikProps={formikProps}
                                  customFields={customFields}
                                  name={`customField[${index}].`}
                                />
                              )}

                              {index !== 0 &&
                                visitorVaild[index] &&
                                !formikProps.values?.visitors[index]?.edit &&
                                formikProps.values?.visitors[index]
                                  ?.guestType == 1 &&
                                !formikProps.values?.visitors[index]
                                  .is_verified && (
                                  <Grid item xs={12}>
                                    {formikProps.values.visitors[index]
                                      .dependent !== 0 ? (
                                      <Fragment>
                                        {localStorage.getItem(
                                          visitorDataKey[0]
                                        ) && (
                                          <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                              handleSaveDependent(index);
                                            }}
                                          >
                                            Save And Verify
                                          </Button>
                                        )}
                                      </Fragment>
                                    ) : (
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        onClick={() => {
                                          handleAddDependent(index);
                                        }}
                                      >
                                        Add Dependent
                                      </Button>
                                    )}
                                  </Grid>
                                )}
                            </Fragment>
                          )}
                        </Grid>
                      </Fragment>
                    </Grid>
                  );
                })}

              {formikProps.values?.visitors[1]?.guestType === 1 ||
              formikProps.values?.visitors[1]?.guestType === 0 ? (
                <Grid container xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={!formikProps.isValid}
                    onClick={formikProps.handleSubmit}
                  >
                    Send Invite
                  </Button>
                </Grid>
              ) : type == "appointment" ? (
                <Grid container xs={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    disabled={!formikProps.isValid}
                    onClick={formikProps.handleSubmit}
                  >
                    Send Invite
                  </Button>
                </Grid>
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  disabled={!formikProps.isValid}
                  onClick={() => {
                    navigate(
                      pages.EventSummary.route +
                        userData[0].event_id +
                        "/" +
                        userData[0].visitor_id,
                      {
                        state: {
                          userData: userData,
                        },
                      }
                    );
                  }}
                >
                  Save Badge
                </Button>
              )}
            </Fragment>
          );
        }}
      </Formik>

      <DependentPopup
        setDependentList={setDependentList}
        dependentPopUp={dependentPopUp}
        setDependentPopUp={setDependentPopUp}
      />
    </OuterBox>
  );
}
