import { Formik } from "formik";
import { useState } from "react";
import { Grid, Typography, Drawer, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { useAlert, useAxios } from "../../hooks";
import { LoadingButton, SelectField, TextField } from "../../components";

import UserBackGround from "./assests/UserBackGround.svg";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "transparent",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  textAlign: "left",
  alignContent: "center",
  alignItems: "center",
  borderRadius: "12.5px 12.5px 0 0",
  height: "auto",
  background: "rgb(255, 255, 255)",
  width: "100%",
}));

export default function Dependent({
  openDependent,
  setOpenDependent,
  setDependentList,
}) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [loading, setLoading] = useState(false);

  function onClose() {
    setOpenDependent(false);
  }
  return (
    <StyledDrawer anchor="bottom" open={openDependent} onClose={onClose}>
      <Grid container sx={{ background: "transparent", padding: "15px 15px" }}>
        <Grid item container justifyContent={"end"} onClick={onClose}>
          <CloseIcon
            sx={{
              fontSize: "35px",
              padding: "4px",
              color: "white",
              borderRadius: "50%",
              background: "#FFFFFF38",
              // marginLeft: "90%",
            }}
          />
        </Grid>
      </Grid>

      <ContentBox>
        <img
          src={UserBackGround}
          style={{ position: "absolute", right: 0 }}
        ></img>
        <div style={{ padding: 20 }}>
          <Typography
            variant="h4"
            fontWeight={900}
            m={"15px 0px 25px"}
            gutterBottom
          >
            Add Dependant Details
          </Typography>
          <Formik
            initialValues={{
              phoneNumber: visitorData.phoneNumber,
              name: "",
              emailId: "",
              gender: "",
            }}
            onSubmit={(formData, { setSubmitting, resetForm }) => {
              setLoading(true);
              axios({
                url: "event/visitor/add-dependent",
                method: "POST",
                data: {
                  phone_number: formData.phoneNumber,
                  name: formData.name,
                  email_id: formData.emailId,
                  gender: formData.gender,
                },
              })
                .then((response) => {
                  if (response.status) {
                    dispatch(
                      alert({
                        type: "success",
                        message: response.message.displayMessage,
                      })
                    );

                    axios({
                      url:
                        "event/visitor/get-dependent-list?phone_number=" +
                        visitorData.phoneNumber,
                      disableRedirect: true,
                    }).then((response) => {
                      if (response.status) {
                        setDependentList(response.data || []);
                      }
                    });
                  }
                  setLoading(false);
                  onClose();
                })
                .catch(() => {
                  resetForm();
                  setLoading(false);
                });
            }}
          >
            {(formikProps) => (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "900", marginBottom: "7.5px" }}
                  >
                    Mobile Number
                    <span
                      style={{
                        color: "red",
                        "-webkit-text-stroke-width": ".5px",
                      }}
                    >
                      *
                    </span>
                  </Typography>

                  <TextField
                    name="phoneNumber"
                    placeholder="Mobile Number"
                    disabled
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "900", marginBottom: "7.5px" }}
                  >
                    Your Name
                    <span
                      style={{
                        color: "red",
                        "-webkit-text-stroke-width": ".5px",
                      }}
                    >
                      *
                    </span>
                  </Typography>

                  <TextField
                    name="name"
                    placeholder="Full Name"
                    formikProps={formikProps}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "900", marginBottom: "7.5px" }}
                  >
                    Your Email Id
                    <span
                      style={{
                        color: "red",
                        "-webkit-text-stroke-width": ".5px",
                      }}
                    >
                      *
                    </span>
                  </Typography>

                  <TextField
                    name="emailId"
                    placeholder="Email Id"
                    formikProps={formikProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "900", marginBottom: "7.5px" }}
                  >
                    Select Your Gender
                    <span
                      style={{
                        color: "red",
                        "-webkit-text-stroke-width": ".5px",
                      }}
                    >
                      *
                    </span>
                  </Typography>

                  <SelectField
                    label="Select Your Gender"
                    name="gender"
                    value={formikProps.values.gender}
                    options={[
                      { id: "Male", name: "Male" },
                      { id: "Female", name: "Female" },
                    ]}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    required
                    xs={12}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    onClick={formikProps.handleSubmit}
                    fullWidth
                    backgroundColor="#3936EF"
                    style={{ fontWeight: 800, color: "white" }}
                  >
                    Submit
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </Formik>
        </div>
      </ContentBox>
    </StyledDrawer>
  );
}
