import { Grid, Typography } from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";
import React, { Fragment, useEffect, useState } from "react";

import {
  CommonTextField,
  LoadingButton,
  MandatoryNote,
  SelectField,
  TextField,
} from "../../../../components";
import { useAxios, useComponent } from "../../../../hooks";
import { useSelector } from "react-redux";
import CustomField from "./CustomField";

function Form1({
  formik,
  memberCount,
  setStepperLevel,
  setMemberCount,
  customFields,
}) {
  const axios = useAxios();
  const { loader } = useComponent();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "event/booking/states",
      method: "GET",
    })
      .then((response) => {
        if (response.status) {
          setStateOptions(response.data);
        }
        loader.apiComplete();
      })
      .catch((error) => {
        console.error("Error in fetching states list", error);
      });
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[A-Za-z\s]*$/;

    if (regex.test(inputValue)) {
      formik.handleChange(e);
    }
  };

  // Function to fetch districts based on the selected state
  const fetchDistricts = async (selectedState) => {
    try {
      const response = await axios({
        url: `event/booking/districts?state_id=${selectedState}`,
        method: "GET",
      });
      if (response.status) {
        setDistrictOptions(response.data);
      }
    } catch (error) {
      console.error("Error in fetching district list", error);
    }
  };

  // Function to handle state selection change
  const handleStateChange = (event, index) => {
    setDistrictOptions([]);
    const selectedState = event.target.value;
    formik.setFieldValue(`visitorData[${index}].state`, selectedState);
    formik.setFieldValue(`visitorData[${index}].district`, ""); // Reset selected district when state changes
    fetchDistricts(selectedState);
  };

  return (
    <Fragment>
      <Grid container>
        <Grid
          container
          spacing={2}
          sx={{
            textTransform: "capitalize",
            padding: "15px 15px 20px 5px !important",
            borderRadius: "12.5px",
            position: "relative",
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", marginBottom: "15px" }}
            >
              Your Mobile No{"  "}
              <span
                style={{
                  color: "red",
                  "-webkit-text-stroke-width": ".5px",
                }}
              >
                *
              </span>
            </Typography>

            <TextField
              name={`visitorData[${0}].mobile`}
              label={"Mobile No"}
              formikProps={formik}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{ fontWeight: "900", marginBottom: "7.5px" }}
            >
              Your Name{"  "}
              <span
                style={{
                  color: "red",
                  "-webkit-text-stroke-width": ".5px",
                }}
              >
                *
              </span>
            </Typography>

            <TextField
              name={`visitorData[${0}].name`}
              label={"Name"}
              required
              disabled={visitorData.firstName}
              formikProps={formik}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CustomField
            formikProps={formik}
            customFields={customFields}
            visitorDataIndex={0}
            title={true}
          />
        </Grid>
      </Grid>

      {formik.values.subDepartment && (
        <Fragment>
          <MandatoryNote />

          <LoadingButton
            onClick={() => {
              setStepperLevel(3);
            }}
          >
            Save & Proceed
          </LoadingButton>
        </Fragment>
      )}
    </Fragment>
  );
}

export default Form1;
