import React, { Fragment } from "react";
import {
  Box,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Lock from "./assests/lock.png";
import { LoadingButton } from "../../components";

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    background: "transparent",
  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  borderRadius: "12.5px 12.5px 0 0",
  height: "auto",
  background: "rgb(255, 255, 255)",
  padding: "0px 25px 30px 25px",
  width: "100%",
}));

const CameraStartPopUp = ({
  cameraStartPopUp,
  setCameraStartPopUp,
  startCamera,
  setStartRecording,
}) => {
  const theme = useTheme();

  return (
    <Fragment>
      <StyledDrawer
        anchor={"bottom"}
        open={cameraStartPopUp}
        onClose={() => setCameraStartPopUp(false)}
      >
        <Grid
          container
          sx={{ background: "transparent", padding: "15px 15px" }}
        >
          <Grid
            item
            container
            justifyContent={"end"}
            onClick={() => {
              setCameraStartPopUp(false);
            }}
          >
            <CloseIcon
              sx={{
                fontSize: "35px",
                padding: "4px",
                color: "white",
                borderRadius: "50%",
                background: "#FFFFFF38",
                // marginLeft: "90%",
              }}
            />
          </Grid>
        </Grid>

        <ContentBox>
          <Grid
            container
            direction="column"
            alignItems="left"
            justifyContent="left"
            sx={{ padding: "10px 3%" }}
          >
            <img src={Lock} style={{ width: "110px", height: "130px" }}></img>

            <Typography
              variant="h5"
              fontWeight={900}
              textAlign="left"
              sx={{
                fontSize: {
                  xs: "1.55rem",
                  sm: "1.75rem",
                  md: "1.95rem",
                },
                "-webkit-text-stroke-width": ".25px",
                fontWeight: "bold",
              }}
            >
              Your Privacy is Protected
            </Typography>
          </Grid>

          <Typography
            variant="h6"
            fontWeight={400}
            pt={3}
            pb={3}
            textAlign="left"
            sx={{
              fontSize: {
                xs: "0.95rem",
                sm: "1.1rem",
              },
              padding: "20px 3%",
            }}
          >
            We do not store your face images. They are used solely for
            verification and are immediately discarded after the process.
            <br />
            <br />
            Temporarily, your image is stored on your device and is not used for
            any purpose beyond verification.
          </Typography>

          <LoadingButton
            onClick={() => {
              startCamera();
              setStartRecording(true);
              setCameraStartPopUp(false);
            }}
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "12px 16px",
              boxShadow: "none",
            }}
          >
            Okay, Got It
          </LoadingButton>
        </ContentBox>
      </StyledDrawer>
    </Fragment>
  );
};

export default CameraStartPopUp;
