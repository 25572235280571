import React, { useEffect, useRef, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";

import { styled } from "@mui/system";

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root ": {
    "& fieldset": {
      borderColor: "#0000001A !important",
      borderRadius: "12.5px",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 0, 0, 0.38)",
      borderRadius: "12.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 0, 0, 0.38)",
      borderRadius: "12.5px",
    },
  },

  "&.MuiSelect-icon": {
    color: " rgba(0, 0, 0, 0.67) !important",
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: "1px solid #0000001A",
  },

  ".MuiInputLabel-root": {
    color: "rgba(0, 0, 0, 0.38)",
  },
}));

const SelectField = ({
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  error,
  xs = 6,
  disabled = false,
  multiple = false,
}) => {
  const firstSelected = useRef(-1);
  const lastSelected = useRef(-1);

  const handleRangeSelect = (e) => {
    const start = Math.min(firstSelected.current, lastSelected.current);
    const end = Math.max(firstSelected.current, lastSelected.current);

    const newValue = options
      .slice(start != -1 ? start : end, end + 1)
      .map((option) => option.id);

    onChange({ target: { name, value: newValue } });
  };

  const handleClick = (value) => {
    if (firstSelected.current == -1 && lastSelected.current == -1) {
      firstSelected.current = value;
    } else if (firstSelected.current != -1 && lastSelected.current == -1) {
      lastSelected.current = value;
    } else {
      if (value < firstSelected.current) {
        firstSelected.current = value;
      } else if (lastSelected.current < value) {
        lastSelected.current = value;
      } else if (lastSelected.current == value) {
        firstSelected.current = value;
      } else {
        lastSelected.current = value;
      }
    }
  };

  useEffect(() => {
    firstSelected.current = -1;
    lastSelected.current = -1;
  }, []);

  return (
    <Grid item xs={xs}>
      <CustomFormControl
        fullWidth
        variant="outlined"
        sx={{ marginBottom: "16px" }}
        error={error && Boolean(error)}
      >
        <InputLabel
          id={`demo-simple-select-${name}`}
          sx={{ paddingRight: "20px" }}
        >
          {label}
          <span style={{ color: "red", float: "right", paddingLeft: "5px" }}>
            *
          </span>
        </InputLabel>
        <Select
          label={label}
          labelId={`demo-simple-select-${name}`}
          id={`demo-simple-${name}`}
          // displayEmpty
          multiple={multiple}
          name={name}
          value={value}
          onChange={multiple ? handleRangeSelect : onChange}
          onBlur={onBlur}
          disabled={options.length === 0 || disabled}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{ justifyContent: "space-between" }}
              onClick={() => handleClick(index)}
            >
              <span>{option.name} </span>
            </MenuItem>
          ))}
        </Select>
      </CustomFormControl>
    </Grid>
  );
};

export default SelectField;
