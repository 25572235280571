import React from "react";

import { MenuItem, InputAdornment, Tooltip } from "@mui/material";
import { Info } from "@mui/icons-material";
import TextField from "./../../../../components/TextField";

export default function SwitchComponent({
  formikProps,
  disabled,
  fieldDetails,
  name = undefined,
  ...props
}) {
  return (
    <TextField
      select
      required={fieldDetails.mandatory}
      name={name || fieldDetails.name}
      label={fieldDetails.label}
      disabled={disabled}
      formikProps={formikProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {!!fieldDetails.tooltip && (
              <Tooltip
                style={{ marginRight: "22px" }}
                title={fieldDetails.tooltip}
              >
                <Info size="small" />
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
    >
      <MenuItem value={true}>Yes</MenuItem>
      <MenuItem value={false}>No</MenuItem>
    </TextField>
  );
}
