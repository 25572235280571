import { useSelector } from "react-redux";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import React, { Fragment, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { useAxios } from "../../hooks/";
import pages from "../../constants/pages";
import {
  OuterBox,
  SubheaderSection,
  LoadingButton,
  ButtonContainer,
} from "./../../components";
import EventBookingSuccess from "./../Success/EventBookingSuccess";

export default function VideoPreview({ userData }) {
  const axios = useAxios({ baseURL: "mox_api_2" });

  const axiosDigipass = useAxios({
    baseURL: "digipass",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { visitorData } = useSelector((state) => state.memberDetails);
  const {
    visitor_id,
    id,
    ref_id,
    hotelID,
    booking_type,
    bookingId,
    eventId,
    officerID,
    appointmentData,
    appointmentID,
    matchPercentage,
    campusId,
    studentId,
    employeeId,
    digiLockerID,
    type,
    popup,
    docFace,
  } = location.state || {};

  const [loading, setLoading] = useState(false);
  const [livelinessError, setLivelinessError] = useState({});

  const [openSuccess, setOpenSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [success, setSuccess] = useState(false);

  function captureAgain() {
    navigate(-1);
  }

  const checkingLiveliness = async () => {
    setLoading(true);

    const fileurl = userData.state.blob;
    const userDocData = JSON.parse(
      localStorage.getItem(`userPayload_${visitor_id}`)
    );
    console.log(userDocData, "userDocData");

    axiosDigipass({
      url: `event/verify/booking`,
      method: "POST",
      disableRedirect: true,
      data: {
        name: " ",
        face_data: fileurl.replace(/^data:image\/[a-z]+;base64,/, ""),
        ...(!bookingId &&
          userDocData &&
          !hotelID && {
            booking_id: bookingId || sessionStorage.getItem("bookingId"),
          }),
        ...(appointmentID &&
          appointmentID != -1 && {
            appointment_id:
              appointmentID || sessionStorage.getItem("appointmentID"),
          }),
        aadhar_data: userDocData,
        ...(officerID && appointmentID != -1 && { officer_id: officerID }),
        ...(appointmentID && {
          slot_ids: appointmentData.slot_id,
        }),
        ...(appointmentID && {
          phone_number: appointmentData.phone_number || visitorData.phoneNumber,
        }),
        ...(visitor_id && {
          visitor_id: visitor_id,
        }),
        ...(appointmentID && {
          appointment_date: appointmentData.appointment_date,
        }),
        ...(campusId && {
          campus_id: campusId,
          ...(studentId && {
            student_id: studentId,
          }),
          ...(employeeId && {
            employee_id: employeeId,
          }),
        }),
      },
    })
      .then((response) => {
        if (response.status) {
          if (popup) {
            localStorage.setItem("windowClosed", true);
            window.close();
          }

          if (type == "hotel") {
            let key = visitor_id + "_face&docs";

            localStorage.setItem(key, response.data.encrypted_face_data);

            axiosDigipass({
              url: `hotel/booking/guest/verify`,
              method: "POST",
              disableRedirect: true,
              data: {
                booking_ref_id: bookingId,
                // booking_id: bookingId,
                hotel_id: hotelID,
                visitor_data: {
                  visitor_id: visitor_id,
                  booking_type: "event",
                  ...userDocData,
                },
              },
            }).then((response) => {
              if (response.status) {
                navigate(pages.HotelSummary.path + hotelID + "/" + bookingId);
              }
            });
          } else if (appointmentID == -1) {
            let key = visitor_id + "_" + (bookingId || appointmentID);

            localStorage.setItem(key, response.data.encrypted_face_data);
            setSuccess(true);
          } else if (docFace == "true") {
            let key = visitor_id + "_" + (bookingId || appointmentID);

            localStorage.setItem(key, response.data.encrypted_face_data);

            navigate(
              `${pages.Verification.route}?visitor_id=${visitor_id}${
                bookingId ? "&booking_id=" + bookingId : ""
              }${eventId ? "&event_id=" + eventId : ""}${
                appointmentID ? "&appointment_id=" + appointmentID : ""
              }${officerID ? "&officer_id=" + officerID : ""}&doc_verify=true`
            );
          } else {
            let key =
              visitor_id + "_" + (bookingId || appointmentID || studentId);
            localStorage.setItem(key, response.data.encrypted_face_data);

            let url =
              !appointmentID && !studentId
                ? "event/visitor/check-booking" +
                  "?event_id=" +
                  eventId +
                  (visitor_id ? "&visitor_id=" + visitor_id : "") +
                  (!visitor_id ? "&booking_id=" + bookingId : "")
                : studentId
                ? `/attendance/campus/check-campus-booking?student_id=${studentId}`
                : "appointment/appointment/check-appointment";

            let method =
              !appointmentID && !studentId ? "GET" : studentId ? "GET" : "POST";

            let data = !appointmentID
              ? {}
              : studentId
              ? {}
              : {
                  ...(officerID && { officer_id: officerID }),
                  ...(appointmentID && {
                    appointment_id: appointmentID,
                  }),
                };

            axios({
              url: url,
              disableRedirect: true,
              method: method,
              data: data,
            }).then((response) => {
              if (response.status) {
                if (
                  Object.keys(response.data).length > 0 &&
                  !response.data[0].appointment_date &&
                  !studentId
                ) {
                  if (type == "primaryUser") {
                    navigate(
                      pages.InviteUserPage.path +
                        "/" +
                        response.data[0].event_id +
                        "/" +
                        response.data[0].visitor_id,
                      {
                        state: {
                          userData: response.data,
                          match_percentage: matchPercentage,
                          face_data: fileurl,
                        },
                      }
                    );
                  } else {
                    navigate(
                      pages.EventSummary.route +
                        response.data[0].event_id +
                        "/" +
                        response.data[0].visitor_id,
                      {
                        state: {
                          userData: response.data,
                          match_percentage: matchPercentage,
                          face_data: fileurl,
                        },
                      }
                    );
                  }
                } else if (studentId) {
                  navigate(
                    pages.CampusSummary.route + `?student_id=${studentId}`,
                    {
                      state: {
                        userData: response.data,
                        match_percentage: matchPercentage,
                        face_data: fileurl,
                      },
                    }
                  );
                } else {
                  navigate(
                    pages.AppointmentSummary.route +
                      `?officer_id=${officerID}&appointment_id=${appointmentID}`,
                    {
                      state: {
                        userData: response.data,
                        match_percentage: matchPercentage,
                        face_data: fileurl,
                      },
                    }
                  );
                }
              }
            });
          }
        } else {
          setLivelinessError(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        console.error("Error in Aws upload:", error);
      });
  };

  return (
    <Fragment>
      {!success && (
        <Fragment>
          {" "}
          {livelinessError?.name_match !== undefined &&
          !livelinessError?.name_match &&
          !livelinessError?.face_match ? (
            <OuterBox>
              <SubheaderSection
                textAlign="center"
                title="Verification Failed"
              />

              <form>
                <Box>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                      padding: "10px",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <CancelRoundedIcon
                      sx={{
                        color: "red",
                        fontSize: "60px",
                        marginBottom: "15px",
                      }}
                    />
                    <Typography variant="h6" sx={{ color: "red" }}>
                      {livelinessError?.booking_already_verified
                        ? "Booking already verified"
                        : "Details Not Match"}
                      <br />
                    </Typography>
                  </Grid>
                </Box>

                <ButtonContainer>
                  <LoadingButton
                    onClick={() =>
                      navigate(
                        pages.Verification.route +
                          `?visitor_id=${visitor_id}&booking_type=no&ref_id=DIGI&id=0`
                      )
                    }
                  >
                    Try Again
                  </LoadingButton>
                </ButtonContainer>
              </form>
            </OuterBox>
          ) : (
            <Grid container flexDirection={"column"} style={{ height: "100%" }}>
              <Grid item sx={{ height: "100%" }}>
                <form style={{ height: "100%" }}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    sx={{ height: "100%", position: "relative" }}
                  >
                    <Grid
                      item
                      style={{
                        textAlign: "center",
                        height: "100%",
                      }}
                      className="videoBox"
                      position={"relative"}
                    >
                      <img
                        id="video"
                        width="100%"
                        height="100%"
                        style={{ objectFit: "initial" }}
                        src={userData.state.blob}
                        alt="Preview"
                        style={{ borderRadius: "0px" }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                      style={{
                        position: "absolute",
                        bottom: "40px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                    </Grid>

                    <div
                      style={{
                        position: "absolute",
                        bottom: "0px",
                        width: "85%",
                      }}
                    >
                      <Typography
                        variant="h5"
                        style={{
                          fontWeight: "900",
                          color: "white",
                          margin: "5px 0px",
                          textAlign: "center",
                        }}
                      >
                        Face Verification
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: "900",
                          color: "white",
                          margin: "5px 0px",
                          textAlign: "center",
                        }}
                      >
                        Before proceeding, check the image preview
                      </Typography>

                      {!Boolean(livelinessError?.name_match) && (
                        <LoadingButton
                          loading={loading}
                          className="proceedNextBtn"
                          onClick={checkingLiveliness}
                          style={{ marginTop: "5px", boxShadow: "none" }}
                        >
                          Proceed Next
                        </LoadingButton>
                      )}

                      <LoadingButton
                        onClick={captureAgain}
                        disabled={loading}
                        className="captureAgainBtn"
                        backgroundColor="rgb(245 219 162)"
                        style={{ margin: "10px 0px", boxShadow: "none" }}
                      >
                        Capture Again
                      </LoadingButton>
                    </div>
                  </Box>
                </form>
              </Grid>
            </Grid>
          )}
        </Fragment>
      )}

      {success && (
        <Fragment>
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
              background: "#1B996A",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="89"
              height="89"
              viewBox="0 0 89 89"
              fill="none"
            >
              <path
                d="M13.3335 30.5185V20.6667C13.3335 16.0029 17.1142 12.2222 21.7779 12.2222H31.1607"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M13.3335 57.7286V67.5804C13.3335 72.2442 17.1142 76.0249 21.7779 76.0249H31.1607"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M76.667 30.5185V20.6667C76.667 16.0029 72.8863 12.2222 68.2225 12.2222H58.8398"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M76.667 57.7286V67.5804C76.667 72.2442 72.8863 76.0249 68.2225 76.0249H58.8398"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M55.5559 57.5553L54.146 58.182C48.3235 60.7697 41.6772 60.7697 35.8547 58.182L34.4448 57.5553"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M46.3951 34.0986V45.6709C46.3951 47.4435 45.1549 48.9745 43.4208 49.3423L41.9507 49.6541"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M31.6299 34.0986L31.6299 39.6541"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
              <path
                d="M58.8398 34.0986L58.8398 39.6541"
                stroke="white"
                stroke-width="3.33333"
                stroke-linecap="round"
              />
            </svg>
            <Typography
              variant="h4"
              style={{
                fontWeight: "900",
                color: "white",
                margin: "10px 0px",
              }}
            >
              Face Verification Successful
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: "400",
                color: "#FFFFFFB2",
              }}
            >
              Your profile is now verified
            </Typography>
          </Grid>
        </Fragment>
      )}

      <EventBookingSuccess
        open={openSuccess}
        setOpen={setOpenSuccess}
        SuccessMessage={displayMessage}
      />
    </Fragment>
  );
}
