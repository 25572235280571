import * as Yup from "yup";
import { useFormik } from "formik";
import { sha256 } from "js-sha256";
import { useDispatch, useSelector } from "react-redux";
// import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, Fragment } from "react";

import pages from "./../../../constants/pages";
import {
  TextField,
  OuterBox,
  SubheaderSection,
  LoadingButton,
  MandatoryNote,
  OTPInput,
  ButtonContainer,
  ResendOTPButton,
  Loader,
  CaptchaDisplay,
} from "./../../../components";
import {
  useAlert,
  useAxiosV2,
  useAxios,
  useComponent,
} from "./../../../hooks/";
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { MdDocumentScanner } from "react-icons/md";
import AadharCardBack from ".././assests/AadharCard.svg";
import EditButton from ".././assests/edit.svg";

const MyComponent = ({
  token,
  visitorId,
  setVerificationFailedCount,
  numberOfInputs = 6,
  setVerification,
  digipassDataKey,
  OuterBox2,
  OuterPaper,
}) => {
  const axios = useAxios();
  const inputRefs = useRef([]);
  const { alert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [uuid, setUuid] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [ocrLoading, setOCRLoading] = useState(false);
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharOtpGenerated, setAadharOtpGenerated] = useState(false);

  const validationSchema = Yup.object().shape({
    aadhar_number: Yup.string()
      .required("Aadhar Number is required")
      .matches(/^\d{12}$/, "Aadhar Number must be exactly 12 digits"),
    captchaInput: Yup.string()
      .required("Aadhar Captcha Input is required")
      .test("captchaMatch", "Captcha does not match", function (value) {
        return value === captcha;
      }),
  });

  const queryParams = new URLSearchParams(location.search);

  const ref_id = queryParams.get("ref_id");
  const eventId = queryParams.get("event_id");
  const bookingId = queryParams.get("booking_id");
  const visitor_id = queryParams.get("visitor_id");
  const booking_type = queryParams.get("booking_type");
  const appointmentID = queryParams.get("appointment_id");
  const campusId = queryParams.get("campus_id");
  const studentId = queryParams.get("student_id");
  const employeeId = queryParams.get("employee_id");
  const type = queryParams.get("type");
  const popup = queryParams.get("popup");
  const docVerify = queryParams.get("doc_verify");
  const officerID = queryParams.get("officer_id");
  const hotelID = queryParams.get("hotel_id");
  const docFace = queryParams.get("doc_face");

  const [countdown, setCountdown] = useState(0);
  const [disabledResendOTP, setDisabledResendOTP] = useState(false);

  const handleResendOTP = () => {
    setDisabledResendOTP(true);
    setCountdown(60);

    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setDisabledResendOTP(false);
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleGoBack = () => {
    setVerification(false);
  };

  const handleOtpGoBack = () => {
    setAadharOtpGenerated(false);
  };

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < numberOfInputs - 1) {
      newOTP[index + 1] = "";
      setOTP(newOTP);
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
  };

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCaptcha = "";
    for (let i = 0; i < 6; i++) {
      randomCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(randomCaptcha);
  };

  function handleFirstOtp(values) {
    setOTP(new Array(numberOfInputs).fill(""));
    setLoading(true);
    var hashID = sha256(`META5488|KijfgGFw3eMeta|PassMeta`);
    axiosAadhar({
      url: "/enter/aadhar/v2/",
      method: "POST",
      authToken: token,
      data: {
        headers: {
          client_code: "META5488",
          sub_client_code: "META5488",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          sub_client_code: "META5488",
          aadhaar: values.aadhar_number,
          hash: hashID,
        },
      },
    }).then((response) => {
      if (
        response?.data?.code === "333" &&
        response?.data?.message === "Too many requests"
      ) {
        dispatch(
          alert({
            type: "error",
            message: "Please try again in five minutes",
          })
        );
      }

      if (response.response_code === 101) {
        setLoading(false);
        if (response.data.response_status.code === "000") {
          dispatch(
            alert({
              type: "success",
              message: "OTP sent on the registered number.",
            })
          );

          setAadharOtpGenerated(true);
          const data = {
            visitor_id: visitorId,
            aadhar_otp_generated: 1,
            doc_type: "aadhar",
          };
          setUuid(response.data.response_data.uuid);
        } else if (response.data.response_status.code === "471007") {
          dispatch(
            alert({
              type: "error",
              message: "Please enter a valid captcha.",
            })
          );
        } else if (
          response.data?.response_status?.code === "422005" ||
          response.data.response_status.code === "470046"
        ) {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
        }

        if (response.data?.response_status?.status === "FAIL") {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
        }
      } else {
        setLoading(false);
        dispatch(
          alert({
            type: "error",
            message: "Error in sending OTP",
          })
        );
      }
    });
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleVerifyOTP = () => {
    const otpString = otp.join("");
    const numericOtpString = otpString.replace(/\D/g, "");
    const numericOtp = parseInt(numericOtpString, 10);

    if (isNaN(numericOtp) || numericOtpString.length !== 6) {
      dispatch(
        alert({
          type: "error",
          message: "Invalid OTP. Please enter a 6-digit numeric code.",
        })
      );
      return;
    }
    setLoading(true);
    axiosAadhar({
      url: "/enter/otp/v2/",
      method: "POST",
      authToken: token,
      data: {
        headers: {
          client_code: "META5488",
          sub_client_code: "META5488",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          uuid: uuid,
          otp: numericOtp.toString(),
        },
      },
    }).then((response) => {
      if (response.response_code === 101) {
        if (
          response.data?.response_status?.code === "422002" ||
          response.data?.response_status?.code === "422003" ||
          response.data?.response_status?.code === "333" ||
          response.data?.response_status?.code === "470021"
        ) {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
          setVerificationFailedCount((prevCount) => prevCount + 1);
          setLoading(false);
        } else if (response.data?.response_status?.code === "000") {
          const name = response.data.response_data.name;
          const nameParts = name.split(" ");
          const first_name = nameParts[0];
          let last_name = nameParts.slice(1).join(" ");
          if (last_name.trim() === "") {
            last_name = first_name;
          }
          const initialDate = response.data.response_data.dob;
          const [day, month, year] = initialDate.split("/");
          const formattedDate = `${day}-${month}-${year}`;
          let payload = {
            doc_type: 1,
            booking_type: "event",
            aadharNo: response.data.response_data.document_id,
            visitor_id: visitorId,
            first_name: first_name,
            last_name: last_name,
            gender: response.data.response_data.gender,
            dob: formattedDate,
            address: response.data.response_data.address,
            doc_face_image: response.data.response_data.doc_face,
          };
          const step = 1;

          if (step) {
            payload.is_verified = 1;
            const payloadString = JSON.stringify(payload);

            if (docVerify) {
              let faceData = digipassDataKey.filter((item) => {
                return localStorage.getItem(item);
              });

              axios({
                url: `event/verify/booking`,
                method: "POST",
                disableRedirect: true,
                data: {
                  name: " ",
                  aadhar_data: payload,
                  visitor_id: visitorId,
                  face_data: localStorage.getItem(faceData[0]),
                  document_verification: true,
                  is_encrypted_face: true,
                  phone_number:
                    visitorData.phoneNumber ||
                    sessionStorage.getItem("mobileNumber"),
                  ...(bookingId &&
                    docFace !== "false" &&
                    !hotelID && {
                      booking_id:
                        bookingId || sessionStorage.getItem("bookingId"),
                    }),
                  ...(appointmentID &&
                    appointmentID != -1 && {
                      appointment_id:
                        appointmentID ||
                        sessionStorage.getItem("appointmentID"),
                    }),
                  ...(officerID &&
                    appointmentID != -1 && { officer_id: officerID }),
                  ...(appointmentID && {
                    slot_ids: location?.state?.details.slot_id,
                  }),
                  ...(appointmentID && {
                    appointment_date: location?.state?.details.appointment_date,
                  }),
                  ...(campusId && {
                    campus_id: campusId,
                    ...(studentId && {
                      student_id: studentId,
                    }),
                    ...(employeeId && {
                      employee_id: employeeId,
                    }),
                  }),
                },
              })
                .then((response) => {
                  if (response.status) {
                    let key = visitorId + "_face&docs";

                    localStorage.setItem(
                      key,
                      response.data.encrypted_face_data
                    );

                    localStorage.setItem(
                      `userPayload_${visitorId}`,
                      payloadString
                    );

                    if (appointmentID == -1) {
                      navigate(pages.Profile.route);
                    } else {
                      let url =
                        !appointmentID && !studentId
                          ? "event/visitor/check-booking" +
                            "?event_id=" +
                            eventId +
                            (visitorId ? "&visitor_id=" + visitorId : "") +
                            (!visitorId ? "&booking_id=" + bookingId : "")
                          : studentId
                          ? `/attendance/campus/check-campus-booking?student_id=${studentId}`
                          : "appointment/appointment/check-appointment";

                      let method =
                        !appointmentID && !studentId
                          ? "GET"
                          : studentId
                          ? "GET"
                          : "POST";

                      let data = !appointmentID
                        ? {}
                        : studentId
                        ? {}
                        : {
                            ...(officerID && { officer_id: officerID }),
                            ...(appointmentID && {
                              appointment_id: appointmentID,
                            }),
                          };

                      axios({
                        url: url,
                        disableRedirect: true,
                        method: method,
                        data: data,
                      }).then((response) => {
                        if (response.status) {
                          if (eventId) {
                            if (type == "primaryUser") {
                              navigate(
                                pages.InviteUserPage.path +
                                  "/" +
                                  response.data[0].event_id +
                                  "/" +
                                  response.data[0].visitor_id,
                                {
                                  state: {
                                    userData: response.data,
                                  },
                                }
                              );
                            } else {
                              navigate(
                                pages.EventSummary.route +
                                  response.data[0].event_id +
                                  "/" +
                                  response.data[0].visitor_id,
                                {
                                  state: {
                                    userData: response.data,
                                  },
                                }
                              );
                            }
                          } else if (studentId) {
                            navigate(
                              pages.CampusSummary.route +
                                `?student_id=${studentId}`,
                              {
                                state: {
                                  userData: response.data,
                                },
                              }
                            );
                          } else if (
                            appointmentID !== -1 &&
                            Boolean(appointmentID)
                          ) {
                            navigate(
                              pages.AppointmentSummary.route +
                                `?officer_id=${officerID}&appointment_id=${appointmentID}`,
                              {
                                state: {
                                  userData: response.data,
                                },
                              }
                            );
                          }
                        }
                      });
                    }
                  }
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                  console.error("Error in Aws upload:", error);
                });
            } else {
              localStorage.setItem(`userPayload_${visitorId}`, payloadString);

              navigate(
                pages.checkLiveliness.route +
                  (bookingId ? "/?booking_id=" + bookingId : "") +
                  (appointmentID ? "/?appointment_id=" + appointmentID : "") +
                  (eventId ? "&event_id=" + eventId : "") +
                  (officerID ? "&officer_id=" + officerID : "") +
                  (campusId ? "?campus_id=" + campusId : "") +
                  (studentId ? "&student_id=" + studentId : "") +
                  (employeeId ? "&employee_id=" + employeeId : "") +
                  (type ? "&type=" + type : "") +
                  (popup ? "&popup=" + popup : "") +
                  (hotelID ? "&hotel_id=" + hotelID : "") +
                  (visitorId ? "&visitor_id=" + visitorId : ""),
                {
                  state: {
                    visitor_id: visitorId,
                    id: eventId,
                    appointmentData: location?.state?.details || {},
                  },
                }
              );
            }
          }
        } else {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
          setVerificationFailedCount((prevCount) => prevCount + 1);
          setLoading(false);
        }
      }
    });
  };

  const [otp, setOTP] = useState(new Array(numberOfInputs).fill(""));

  const onSubmit = async (values) => {
    try {
      handleFirstOtp(values);
    } catch (error) {
      dispatch(
        alert({
          type: "error",
          message: error,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      aadhar_number: "",
      captchaInput: "",
    },
    validationSchema,
    onSubmit,
  });

  const handleAadharNumberChange = (event) => {
    const inputNumber = event.target.value;
    const cleanedInput = inputNumber.replace(/\D/g, "");
    const truncatedInput = cleanedInput.slice(0, 12);
    formik.handleChange(event);
    formik.setFieldValue("aadhar_number", truncatedInput);
    setAadharNumber(truncatedInput);
  };

  if (!aadharOtpGenerated) {
    return (
      <Fragment>
        <Grid item container>
          <Grid item container justifyContent={"center"}>
            <Grid
              item
              container
              md={12}
              sl={6}
              sx={{
                justifyContent: "start",
                alignContent: "start",
              }}
            >
              <OuterBox2>
                <OuterPaper style={{ padding: "0px" }} elevation={3}>
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      height: "35vh",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        position: "relative",
                        zIndex: "999999",
                        top: "47%",
                        left: "3%",
                      }}
                    >
                      <Typography
                        variant="h3"
                        fontWeight={900}
                        sx={{ marginTop: "7.5px" }}
                      >
                        {"Verify Profile Using Aadhar"}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        sx={{ marginTop: "7.5px", color: "#00000099" }}
                      >
                        {"Please Enter your Aadhar Details"}
                      </Typography>
                    </Grid>
                    <img
                      style={{
                        height: "35vh",
                        position: "absolute",
                        top: "0px",
                      }}
                      src={AadharCardBack}
                    />
                  </div>
                </OuterPaper>
              </OuterBox2>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item container justifyContent={"center"}>
            <Grid
              item
              container
              md={12}
              sl={6}
              sx={{
                justifyContent: "start",
                alignContent: "start",
              }}
            >
              <OuterBox2 style={{ width: "100%" }}>
                <OuterPaper style={{ padding: "0px" }} elevation={3}>
                  <Grid item padding={"25px"} alignContent={"center"}>
                    <form>
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: "700", marginBottom: "17.5px" }}
                      >
                        Enter Aadhar Number
                        <span
                          style={{
                            color: "red",
                            fontWeight: "700",
                          }}
                        >
                          *
                        </span>
                      </Typography>
                      <TextField
                        fullWidth
                        sx={{ marginBottom: "16px" }}
                        variant="outlined"
                        label={
                          <Fragment>
                            <Grid container>
                              <Grid item xs={10}>
                                Enter 12 Digit Aadhar Number
                              </Grid>
                            </Grid>
                          </Fragment>
                        }
                        placeholder="Enter Aadhar Card Number"
                        name="aadhar_number"
                        value={formik.values.aadhar_number}
                        onChange={handleAadharNumberChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.aadhar_number &&
                          Boolean(formik.errors.aadhar_number)
                        }
                        InputProps={{
                          endAdornment: (
                            <Fragment>
                              <Tooltip title="Upload Document">
                                <input
                                  accept="image/*,application/pdf"
                                  style={{ display: "none" }}
                                  id="file-upload"
                                  type="file"
                                  onChange={(event) => {
                                    const fil = event.target.files[0];
                                    setOCRLoading(true);
                                    const data = new FormData();
                                    data.append("file", fil);
                                    axios({
                                      url: "event/govt-api/ocr",
                                      method: "POST",
                                      data: data,
                                    }).then((response) => {
                                      formik.setFieldValue(
                                        "aadhar_number",
                                        response.data?.data?.number || ""
                                      );

                                      setOCRLoading(false);
                                      if (!response.data?.data?.number) {
                                        dispatch(
                                          alert({
                                            type: "error",
                                            message:
                                              "Please share the right documents",
                                          })
                                        );
                                      }
                                    });
                                  }}
                                />
                                <label htmlFor="file-upload">
                                  {!ocrLoading ? (
                                    <MdDocumentScanner
                                      style={{
                                        color: "#3936EF",
                                        fontSize: "35px",
                                        background: "white",
                                        borderRadius: "7px",
                                        padding: "3px 5px",
                                        width: "100%",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : (
                                    <CircularProgress
                                      sx={{
                                        color: "#3936EF",
                                        fontSize: "35px",
                                      }}
                                    />
                                  )}
                                </label>
                              </Tooltip>
                            </Fragment>
                          ),
                        }}
                      />
                      <Grid container>
                        <Grid item xs={7}>
                          <Typography variant="h5" sx={{ fontWeight: "700" }}>
                            Enter Captcha
                            <span
                              style={{
                                color: "red",
                                fontWeight: "700",
                              }}
                            >
                              *
                            </span>
                          </Typography>

                          <TextField
                            label={<Fragment>Enter Captcha</Fragment>}
                            // fullWidth
                            margin="normal"
                            variant="outlined"
                            placeholder="Enter Captcha"
                            name="captchaInput"
                            value={formik.values.captchaInput}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.captchaInput &&
                              Boolean(formik.errors.captchaInput)
                            }
                            helperText={formik.errors.captchaInput}
                            inputProps={{
                              maxLength: 6,
                            }}
                            autoComplete="off"
                          />
                        </Grid>

                        <Grid
                          xs={5}
                          sx={{ alignSelf: "end", padding: "10px 15px" }}
                        >
                          <CaptchaDisplay
                            captcha={captcha}
                            generateCaptcha={generateCaptcha}
                          />
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: "35px" }}>
                        <LoadingButton
                          loading={loading}
                          onClick={formik.handleSubmit}
                          style={{ marginBottom: "15px" }}
                        >
                          Send OTP
                        </LoadingButton>

                        {/* <LoadingButton
                onClick={handleGoBack}
                backgroundColor="#F5F0E5"
                style={{ color: "black" }}
              >
                Back
              </LoadingButton> */}
                      </Grid>

                      {/* <MandatoryNote /> */}
                    </form>
                  </Grid>
                </OuterPaper>
              </OuterBox2>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  return (
    <Loader>
      <Grid item container>
        <Grid item container justifyContent={"center"}>
          <Grid
            item
            container
            md={12}
            sl={6}
            sx={{
              justifyContent: "start",
              alignContent: "start",
            }}
          >
            <OuterBox2>
              <OuterPaper style={{ padding: "0px" }} elevation={3}>
                <div
                  style={{
                    width: "100%",
                    position: "relative",
                    height: "35vh",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      position: "relative",
                      zIndex: "999999",
                      top: "47%",
                      left: "3%",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight={900}
                      sx={{ marginTop: "7.5px" }}
                    >
                      {"Enter OTP "}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      sx={{
                        marginTop: "7.5px",
                        color: "#00000099",
                      }}
                    >
                      {"sent to mobile number linked with Aadhar number "}
                      <span style={{ fontWeight: 900, color: "rgb(0, 0, 0)" }}>
                        {" "}
                        {` xxxx xxxx ${aadharNumber.slice(8)}`}
                        <img
                          src={EditButton}
                          width="25px"
                          height="25px"
                          onClick={handleOtpGoBack}
                          style={{ verticalAlign: "bottom" }}
                        ></img>
                      </span>
                    </Typography>
                  </Grid>
                  <img
                    style={{
                      height: "35vh",
                      position: "absolute",
                      top: "0px",
                    }}
                    src={AadharCardBack}
                  />
                </div>
              </OuterPaper>
            </OuterBox2>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item container justifyContent={"center"}>
          <Grid
            item
            container
            md={12}
            sl={6}
            sx={{
              justifyContent: "start",
              alignContent: "start",
            }}
          >
            <OuterBox2>
              <OuterPaper style={{ padding: "0px" }} elevation={3}>
                <Grid item padding={"25px"}>
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                        marginTop: "20px",
                      }}
                    >
                      {otp.map((value, index) => (
                        <OTPInput
                          key={index}
                          index={index}
                          value={value}
                          otp={otp}
                          inputRefs={inputRefs}
                          otpLength={numberOfInputs}
                          onChange={(e) => handleChange(index, e.target.value)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                        />
                      ))}
                    </div>

                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Grid item container xs={12} mb={2}>
                        <Typography
                          variant="body2"
                          sx={{ paddingRight: "6px", marginTop: "17.5px" }}
                        >
                          {disabledResendOTP ? (
                            `Resend OTP in (${countdown}s)`
                          ) : (
                            <Fragment>
                              Didn’t Receive OTP yet?
                              <span
                                style={{
                                  color: "#3936EF",
                                  fontWeight: "bolder !important",
                                }}
                                onClick={() => {
                                  formik.handleSubmit();
                                  handleResendOTP();
                                }}
                              >
                                Resend again.
                              </span>
                            </Fragment>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container mt={22.5}>
                      <LoadingButton
                        loading={loading}
                        onClick={handleVerifyOTP}
                        style={{ margin: "10px 0px" }}
                      >
                        Verify Aadhar
                      </LoadingButton>

                      {/* <LoadingButton onClick={handleOtpGoBack} backgroundColor="gray">
              Back
            </LoadingButton> */}
                    </Grid>

                    {/* <ResendOTPButton onClick={formik.handleSubmit} disabled={loading} /> */}
                  </form>
                </Grid>
              </OuterPaper>
            </OuterBox2>
          </Grid>
        </Grid>
      </Grid>
    </Loader>
  );
};

export default MyComponent;
{
  /* <Grid item container>
<Grid item container justifyContent={"center"}>
  <Grid
    item
    container
    md={12}
    sl={6}
    sx={{
      justifyContent: "start",
      alignContent: "start",
    }}
  >
    <OuterBox2>
      <OuterPaper
        style={{ padding: "0px" }}
        elevation={3}
      ></OuterPaper>
    </OuterBox2>
  </Grid>
</Grid>
</Grid> */
}
