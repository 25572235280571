import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAlert, useAxios } from "../../hooks";
import { Fragment, useState } from "react";
import styled from "styled-components";
import { OuterBox, TextField } from "../../components";
import { FaUser } from "react-icons/fa";
import { useEffect } from "react";
import { signOutMember } from "../../redux/slices/memberSlice";
import pages from "../../constants/pages";
import Dependent from "./Dependent";
import VerifiedIcon from "@mui/icons-material/Verified";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import icon from "./assests/Group.svg";
import ErrorIcon from "@mui/icons-material/Error";
import face from "./assests/face.svg";
import location from "./assests/location.svg";
import verify from "./assests/verify.svg";

import UserBackGround from "./assests/UserBackGround.svg";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import StageStepper from "./Stepper";
import { RiDeleteBin6Line } from "react-icons/ri";
import EditButton from ".././Booking/assests/editButton.svg";
import emptyImg from "./assests/emptyImg.svg";
import verifiedIcon from "./assests/VerifiedIcon.svg";
import DeleteDependentDialog from "./DeleteDependent";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import DeleteDetailsDialog from "./DeleteDetails";

const PageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  // background: linear-gradient(to bottom right, #e3f2fd, #f9fbe7);
  // padding: 20px;
  align-items: baseline !important;
`;

const ProfileContainer = styled(Card)`
 maxWidth: '800px',
  width: '100%',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  // background: 'linear-gradient(to bottom right, #e3f2fd, #f9fbe7)',
  backdropFilter: 'blur(10px)',
  // marginBottom: '10vh'
`;

const HeaderButton = styled(Button)`
  // color: white;
  // background-color: red;
  // border: 1px solid red;
  // border-radius: 10px;
  // padding: 8px 16px;
  // font-weight: bold;
  // transition: all 0.3s ease;

  &:hover {
    // background-color: #ff7961;
    // border-color: #ff7961;
  }
`;

const ProfileImage = styled.img`
  width: 50%;
  height: 25vh;
  border-radius: 100%;
  border: 2px solid #3936ef;
  // box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  z-index: 9999;
`;

const EmptyImgIcon = styled.img`
  z-index: 999999;
  border-radius: 100%;
  border: 2px solid #3936ef;
  margin-top: 35px;
`;

const InfoLabel = styled(Typography)`
  font-weight: bold;
  color: #757575;
`;

const InfoValue = styled(Typography)`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${({ isPending }) => (isPending ? "red" : "#424242")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Prevent overflowing the container */
  display: block;
  word-wrap: break-word; /* Allows text to break onto a new line if necessary */
  word-break: break-all; /* Break long words */
  padding-right: 10px; /* Add some padding to avoid text being cut off */
`;

const InfoTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  font-size: 1rem;
  text-align: left;
`;

export default function Profile() {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { visitorData } = useSelector((state) => state.memberDetails);
  const [userImage, setUserImage] = useState("");
  const [userData, setUserData] = useState({});
  const [dependentList, setDependentList] = useState(visitorData.dependentList);
  const [parkingDetailsList, setParkingDetailsList] = useState([]);
  const [openDependent, setOpenDependent] = useState(false);
  const [deleteDependentPopUp, setDeleteDependentPopUp] = useState(false);
  const [selectDependentId, setSelectDependentId] = useState(false);

  const [deleteDetailsPopUp, setDeleteDetailsPopUp] = useState(false);

  let localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorData.ID) && !item.includes("userPayload");
  });

  useEffect(() => {
    axios({
      url: "admin/profile/decrypt-face-data",
      method: "POST",
      disableRedirect: true,
      data: {
        face_data: localStorage.getItem(digipassDataKey[0]),
      },
    }).then((response) => {
      if (response.status) {
        setUserImage(response.data);
      }
    });

    axios({
      url:
        "event/visitor/get-dependent-list?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDependentList(response.data || []);
      }
    });
  }, [visitorData.ID, axios, dispatch]);

  useEffect(() => {
    if (
      userData?.student_details?.student_id ||
      userData?.employee_details?.employee_id
    ) {
      axios({
        url: `/admin/parking/user-parking-details?booking_id=${
          userData?.student_details?.student_id ||
          userData?.employee_details?.employee_id
        }`,
        disableRedirect: true,
        disableError: true,
      }).then((response) => {
        if (response.status) {
          setParkingDetailsList(response.data);
        }
      });
    }
  }, [visitorData.ID, userData, axios, dispatch]);

  useEffect(() => {
    axios({
      url: "admin/profile/get-visitor-detail?visitor_id=" + visitorData.ID,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setUserData(response.data);
      }
    });
  }, []);

  const handleDeleteData = () => {
    axios({
      url: `admin/profile/delete-face-data?visitor_id=${visitorData.ID}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        dispatch(signOutMember());
        localStorage.clear();
        navigate(pages.home.route);
      }
      setDeleteDetailsPopUp(false);
    });
  };

  const handleVerifyProfile = (ID) => {
    navigate(
      `${
        pages.Verification?.route
      }?visitor_id=${ID}&appointment_id=${-1}&officer_id=${-1}`
    );
  };

  const handleDeleteDependent = () => {
    axios({
      url: `event/visitor/delete-dependent?visitor_id=${selectDependentId}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        dispatch(
          alert({ message: response.message.displayMessage, type: "success" })
        );
        axios({
          url:
            "event/visitor/get-dependent-list?phone_number=" +
            visitorData.phoneNumber,
          disableRedirect: true,
        }).then((response) => {
          if (response.status) {
            setDependentList(response.data || []);
          }
        });
      }

      setDeleteDependentPopUp(false);
    });
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCell sx={{ width: "40%" }}>
        <InfoLabel>{label}</InfoLabel>
      </TableCell>
      <TableCell sx={{ width: "60%" }}>
        <Tooltip title={value}>
          <InfoValue isPending={value === "pending"}>{value}</InfoValue>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  return (
    <PageWrapper>
      <ProfileContainer
        sx={{
          // marginBottom: "10vh",
          boxShadow: "none",
        }}
        elevation={0}
      >
        <Grid
          container
          spacing={2}
          sx={{ borderRadius: "10px", background: "white" }}
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems={"center"}
            sx={{ zIndex: "999" }}
          >
            <Grid item container sx={{ width: 0 }} className="eventImageBox">
              <div
                style={{
                  position: "relative",
                  // overflow: "hidden",
                  // top: "-8%",
                }}
              >
                <img
                  style={{
                    position: "absolute",
                    top: "-45px",
                    width: "100vw",
                    height: "35vh",
                  }}
                  src={icon}
                />
              </div>
            </Grid>

            <Grid
              item
              container
              mb={"25px"}
              style={{ zIndex: "9999999", marginTop: "20px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <IoIosArrowBack
                color="#A9A9A9"
                fontSize={"24px"}
                style={{ margin: "0px 20px 0px 10px" }}
              />
              <Typography variant="body1" fontSize={"18px"} fontWeight={500}>
                Edit Profile
              </Typography>
            </Grid>

            {userImage ? (
              <ProfileImage
                src={`data:image/jpeg;base64,${userImage}`}
                alt="Profile"
              />
            ) : (
              <EmptyImgIcon src={emptyImg} />
            )}

            <Grid container justifyContent={"center"} mt={2.5}>
              <Typography variant="body1" fontSize={"18px"} fontWeight={900}>
                {`${visitorData.firstName ? visitorData.firstName : ""}${
                  visitorData.lastName ? visitorData.lastName : ""
                }`}
              </Typography>
            </Grid>

            {userImage && (
              <Grid container justifyContent={"center"} mt={2.5}>
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    border: "1px solid #0000001A",
                    borderRadius: "40px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "6px 3px",
                    alignItems: "center",
                  }}
                >
                  <Fragment>
                    <img src={verifiedIcon} width={"17px"} height={"17px"} />
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "12px" }}
                      fontWeight={700}
                    >
                      Verified
                    </Typography>
                  </Fragment>
                </Grid>
              </Grid>
            )}

            {!userImage && (
              <Grid container justifyContent={"center"} mt={2.5}>
                <Grid
                  item
                  xs={3.5}
                  sx={{
                    border: "1px solid #0000001A",
                    borderRadius: "40px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "6px 3px",
                    alignItems: "center",
                  }}
                >
                  <PrivacyTipIcon
                    style={{ fontSize: "17px", color: "rgb(219 11 11)" }}
                  />

                  <Typography
                    variant="body1"
                    sx={{ fontSize: "12px" }}
                    fontWeight={700}
                  >
                    Not Verified
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* <Fragment>
            {!userImage && (
              <Grid
                item
                container
                sx={{
                  border: "0.6px solid #0000001A",
                  borderRadius: "16px",
                  padding: "15px !important",
                  backgroundColor: "#ffd0d0",
                  backgroundImage:
                    "linear-gradient(164deg, #ffd0d0 3%, #ffffff 31%)",
                  margin: "20px",
                }}
              >
                <Grid item container alignItems={"center"}>
                  <ErrorIcon sx={{ color: "red", marginRight: "20px" }} />
                  <Typography variant="body1" fontWeight={"700"}>
                    Profile not verified yet
                  </Typography>
                </Grid>
                <Grid item container m={"15px 0px"}>
                  <Typography
                    variant="body1"
                    fontSize={"14px"}
                    fontWeight={"400"}
                    color={"#00000080"}
                  >
                    VERIFICATION ENSURES
                  </Typography>
                </Grid>

                <Grid item container>
                  <img src={face} style={{ marginRight: "20px" }}></img>
                  <Typography variant="body1">
                    Seamless Facial Recognition Entry
                  </Typography>
                </Grid>

                <Grid item container m={"15px 0px"}>
                  <img src={location} style={{ marginRight: "20px" }}></img>
                  <Typography variant="body1">Faster Check-Ins</Typography>
                </Grid>

                <Grid item container>
                  <img src={verify} style={{ marginRight: "20px" }}></img>
                  <Typography variant="body1">
                    Enhanced Security for Your Profile
                  </Typography>
                </Grid>

                <Grid item container mt={"25px"}>
                  <HeaderButton
                    variant="outlined"
                    fullWidth
                    onClick={() => handleVerifyProfile(visitorData.ID)}
                  >
                    Verify Profile
                  </HeaderButton>
                </Grid>
              </Grid>
            )}
          </Fragment> */}

          <Grid
            container
            sx={{
              border: "0.6px solid #0000001A",
              borderRadius: "16px",
              margin: "20px",
              position: "relative",
            }}
          >
            <img
              src={UserBackGround}
              style={{ position: "absolute", right: 0 }}
            ></img>
            <Grid item xs={12} sx={{ padding: "15px !important" }}>
              {!!visitorData.gender && (
                <Grid item container>
                  <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={"bold"}>
                      Gender
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {visitorData.gender}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {!!visitorData.phoneNumber && (
                <Grid item container m={"15px 0px"}>
                  <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={"bold"}>
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {visitorData.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {!!visitorData.emailID && (
                <Grid item container>
                  <Grid item xs={6}>
                    <Typography variant="body1" fontWeight={"bold"}>
                      Email ID
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      {visitorData.emailID}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* <Grid item xs={12}>
            <Grid item xs={12} padding={"5px 10px"}>
              <Typography variant="body1" fontWeight={600} mb={2}>
                Your Name
              </Typography>
              <TextField
                size="small"
                // label="Enter Your Name"
                value={visitorData.firstName}
              />
            </Grid>

            {visitorData.gender && (
              <Grid item xs={12} padding={"5px 10px"}>
                <Typography variant="body1" fontWeight={600} mb={2}>
                  Gender
                </Typography>
                <TextField
                  size="small"
                  // label="Enter Your Name"
                  value={visitorData.gender}
                />
              </Grid>
            )}

            {visitorData.phoneNumber && (
              <Grid item xs={12} padding={"5px 10px"}>
                <Typography variant="body1" fontWeight={600} mb={2}>
                  Phone Number
                </Typography>
                <TextField size="small" value={visitorData.phoneNumber} />
              </Grid>
            )}

            {visitorData.emailID && (
              <Grid item xs={12} padding={"5px 10px"}>
                <Typography variant="body1" fontWeight={600} mb={2}>
                  Email ID
                </Typography>
                <TextField size="small" value={visitorData.emailID} />
              </Grid>
            )}
          </Grid> */}

          <Grid
            container
            sx={{
              border: "0.6px solid #0000001A",
              borderRadius: "16px",
              margin: "0px 20px",
              padding: "15px 0px 10px",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 900, marginBottom: "5px" }}
            >
              Verification Status
            </Typography>
            <StageStepper userData={userData} />
          </Grid>

          {Object.keys(userData?.student_details || []).length > 0 && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, rgb(232 232 255) 3%, rgb(255, 255, 255) 31%)",
                margin: "20px",
              }}
            >
              <Grid item container m={"0px 0px 15px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={"#00000080"}
                >
                  STUDENT PASS
                </Typography>
              </Grid>

              <Grid item container>
                <Typography variant="body1">University Name</Typography> &ensp;
                : &nbsp;
                <Typography variant="body1">
                  {userData?.student_details?.university_name}
                </Typography>
              </Grid>

              <Grid item container m={"15px 0px"}>
                <Typography variant="body1">Campus Name</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {userData?.student_details?.campus_name}
                </Typography>
              </Grid>

              <Grid item container>
                <Typography variant="body1">Course</Typography>&ensp; : &nbsp;
                <Typography variant="body1">
                  {userData?.student_details?.course}
                </Typography>
              </Grid>

              <Grid item container mt={"25px"}>
                <HeaderButton
                  variant="outlined"
                  fullWidth
                  // onClick={() => handleVerifyProfile(visitorData.ID)}
                >
                  View
                </HeaderButton>
              </Grid>
            </Grid>
          )}

          {Object.keys(userData?.employee_details || []).length > 0 && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, rgb(232 232 255) 3%, rgb(255, 255, 255) 31%)",
                margin: "20px",
              }}
            >
              <Grid item container alignItems={"center"}></Grid>
              <Grid item container m={"0px 0px 15px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={"#00000080"}
                >
                  EMPLOYEE PASS
                </Typography>
              </Grid>

              <Grid item container>
                <Typography variant="body1">University Name</Typography> &ensp;
                : &nbsp;
                <Typography variant="body1">
                  {userData?.employee_details?.university_name}
                </Typography>
              </Grid>

              <Grid item container m={"15px 0px"}>
                <Typography variant="body1">Designation</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {userData?.employee_details?.designation}
                </Typography>
              </Grid>

              <Grid item container>
                <Typography variant="body1">Employee ID</Typography>&ensp; :
                &nbsp;
                <Typography variant="body1">
                  {userData?.employee_details?.employee_id}
                </Typography>
              </Grid>

              <Grid item container mt={"25px"}>
                <HeaderButton
                  variant="outlined"
                  fullWidth
                  // onClick={() => handleVerifyProfile(visitorData.ID)}
                >
                  View
                </HeaderButton>
              </Grid>
            </Grid>
          )}

          {parkingDetailsList.length > 0 && (
            <Grid
              item
              container
              sx={{
                border: "0.6px solid #0000001A",
                borderRadius: "16px",
                padding: "15px !important",
                backgroundColor: "#ffd0d0",
                backgroundImage:
                  "linear-gradient(164deg, rgb(232 232 255) 3%, rgb(255, 255, 255) 31%)",
                margin: "20px",
              }}
            >
              <Grid item container m={"0px 0px 15px"}>
                <Typography
                  variant="body1"
                  fontSize={"14px"}
                  fontWeight={"900"}
                  color={"#00000080"}
                >
                  Parking PASS
                </Typography>
              </Grid>
              {parkingDetailsList?.map((item, index) => {
                return (
                  <Fragment>
                    <Grid item container>
                      <Typography variant="body1">
                        {`${index + 1} `}Vehicle
                      </Typography>
                      &ensp; : &nbsp;
                      <Typography variant="body1">
                        {item?.vehicle_number} {item?.vehicle_type}
                      </Typography>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          )}
        </Grid>

        {Boolean(dependentList.length) && (
          <Fragment>
            <Grid container padding={"15px 28px"}>
              <Typography variant="h6" fontWeight={"bolder"} fontSize={"17px"}>
                Dependants {`( ${dependentList.length} )`}
              </Typography>

              {dependentList?.map((item, index) => {
                return (
                  <Fragment>
                    <Grid
                      item
                      container
                      spacing={2}
                      sx={{
                        border: "0.6px solid #0000001A",
                        borderRadius: "8px",
                        marginTop: "7px",
                        background: "white",
                        marginTop: "15px",
                      }}
                    >
                      <Grid item container p={"7.5px 0px"}>
                        <Grid item container p={"4.5px 12.5px 5px 4.5px"}>
                          <Grid
                            item
                            xs={2}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              alignSelf: "center",
                            }}
                          >
                            <img
                              width={"20px"}
                              src={
                                "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/2c3a1f49ab5f419da2b5542744edc32f/1740720646322_No_Image_Available.jpg"
                              }
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "45%",
                              }}
                            ></img>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              variant="body1"
                              fontWeight={"bolder"}
                              fontSize={"14px"}
                            >
                              {item.first_name + item.last_name}
                            </Typography>

                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={"12px"}
                            >
                              {item.phone_number}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            sx={{
                              display: "flex",
                              justifyContent: "end",
                              borderRadius: "4px",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setDeleteDependentPopUp(true);
                              setSelectDependentId(item._id);
                            }}
                          >
                            <RiDeleteBin6Line
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#919191",
                              }}
                            />
                          </Grid>
                          {/* <Grid
                            item
                            xs={1.5}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setOpenDependent(true);
                            }}
                          >
                            <img
                              src={EditButton}
                              width={"20px"}
                              height={"20px"}
                            ></img>
                          </Grid> */}
                        </Grid>

                        <Grid
                          item
                          container
                          justifyContent={"center"}
                          m={"5px 0px"}
                        >
                          <Divider
                            sx={{
                              background: "#0000000F",
                              width: "90.5%",
                            }}
                          />
                        </Grid>

                        <Grid item container justifyContent={"center"}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 900,
                              marginTop: "5px",
                              marginBottom: "5px",
                              textAlign: "center",
                            }}
                          >
                            Verification Status
                          </Typography>
                        </Grid>

                        <StageStepper userData={item} />
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </Fragment>
        )}

        <Grid container padding={"0px 28px 28px 28px"}>
          <Typography variant="h6" fontWeight={600} sx={{ marginTop: "15px" }}>
            Actions
          </Typography>
          <Grid
            item
            container
            marginBottom={"15px"}
            sx={{
              border: "0.6px solid #0000001A",
              borderRadius: "8px",
              marginTop: "15px",
            }}
          >
            <Grid item container p={"5px"}>
              <Grid item container p={"5px 10px 10px 15px"}>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <MdOutlinePersonAddAlt1
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8.5}
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={() => {
                    setOpenDependent(true);
                  }}
                >
                  <Typography
                    variant="body1"
                    fontWeight={900}
                    fontSize={"14px"}
                  >
                    Add a Dependant
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={1.5}
                  sx={{
                    display: "flex",
                    justifyContent: "center",

                    borderRadius: "4px",
                    padding: "7px 0px",
                  }}
                  onClick={() => {
                    // window.location.replace(eventDetail?.address.map_link);
                  }}
                >
                  <IoIosArrowForward fontSize={"20px"} color="#919191" />
                </Grid>
              </Grid>

              {userImage && (
                <Fragment>
                  <Grid item container justifyContent={"center"}>
                    <Divider
                      sx={{
                        background: "#0000000F",
                        width: "90.5%",
                      }}
                    />
                  </Grid>

                  <Grid item container p={"5px 10px 10px 15px"}>
                    <Grid
                      item
                      xs={2}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <RiDeleteBin6Line
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={8.5}
                      sx={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        setDeleteDetailsPopUp(true);
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={900}
                        fontSize={"14px"}
                        color={"#CD0000"}
                        onClick={() => {
                          setDeleteDetailsPopUp(true);
                        }}
                      >
                        Delete All Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",

                        borderRadius: "4px",
                        padding: "7px 0px",
                      }}
                      onClick={() => {}}
                    >
                      <IoIosArrowForward fontSize={"20px"} color="#919191" />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Dependent
          openDependent={openDependent}
          setOpenDependent={setOpenDependent}
          setDependentList={setDependentList}
        />

        <DeleteDependentDialog
          handleDeleteDependent={handleDeleteDependent}
          deleteDependentPopUp={deleteDependentPopUp}
          setDeleteDependentPopUp={setDeleteDependentPopUp}
        />

        <DeleteDetailsDialog
          handleDeleteData={handleDeleteData}
          deleteDetailsPopUp={deleteDetailsPopUp}
          setDeleteDetailsPopUp={setDeleteDetailsPopUp}
        />
      </ProfileContainer>
    </PageWrapper>
  );
}
