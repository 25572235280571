import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Box, fontWeight } from "@mui/system";
import { Formik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TextField from "./../../components/TextField";
import FileUpload from "./../../components/FileUpload";
import SubheaderSection from "./../../components/SubheaderSection";
import { useAxios } from "../../hooks";
import { get } from "lodash";
import { convertDate } from "../../utils";
import { DatePicker } from "@mui/x-date-pickers";
import DateTimePicker from "../../components/DateTimePicker";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from ".././../constants/pages";
import calender from "./assests/calender.svg";
import backImg from "./assests/backgroundImg.svg";
import upload from "./assests/upload.svg";
import uploadBlue from "./assests/upload-blue.svg";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import success from "./assests/success.svg";
import deleteIcon from "./assests/delete.svg";

const OuterBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // min-height: 75vh;
  width: [100%, 100%, 100%, 800px];
  // margin: 10% 0;
  padding: 0px 17px;
  box-sizing: border-box;
  background: #ffffff;
  position: relative;
`;

const OuterPaper = styled(Paper)`
  width: 100%;
  // padding: 20px;
  min-height: 60vh;
  background: rgba(255, 255, 255, 0.5);
  align-content: center;
  border-radius: 15px;
`;

const UploadCard = styled(Box)`
  padding: 16px;
  background: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3936EF",
    ...theme.applyStyles("dark", {
      backgroundColor: "#3936EF",
    }),
  },
}));

export default function AddHotelBooking() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [formData, setFormData] = useState({
    ota: "",
    bookingNumber: "",
    otaPDF: "",
    checkOutDateTime: "",
    checkInDateTime: "",
    hotelID: "",
  });
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPDFLink] = useState(undefined);
  const [pdfName, setPDFName] = useState(null);
  const [pdfData, setPDFData] = useState(null);
  const inputRef = useRef(null);
  const [fileUpload, setFileUpload] = useState(false);
  const [checkFaceData, setCheckFaceData] = useState(false);
  const [checkContactDetails, setCheckContactDetails] = useState(false);

  useEffect(() => {
    if (typeof pdfLink == "object") {
      setUploadProgress(60);
      setLoading(true);
      const Form = new FormData();

      Form.append("booking_pdf", pdfLink);
      axios({
        url: "hotel/booking/ocr",
        method: "POST",
        data: Form,
        disableError: true,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setPDFData(response.data);
          setFormData((prev) => ({
            ...prev,
            otaPDF: pdfName,
            ota: response.data?.hotel_name,
            bookingNumber: response.data?.booking_id,
            checkOutDateTime: convertDate(response.data?.checkin_datetime),
            checkInDateTime: convertDate(response.data?.checkout_datetime),
            hotelID: response.data?.hotel?._id,
            guestsCount: response.data?.guests_count,
            guestName: response.data.guest_name,
          }));
        }
        setUploadProgress(100);
        setLoading(false);
      });
    }
  }, [pdfLink]);

  function handleRemoveFile() {
    setFormData({
      ota: "",
      bookingNumber: "",
      otaPDF: "",
      checkOutDateTime: "",
      checkInDateTime: "",
      hotelID: "",
    });
    setFileUpload(false);
    setLoading(false);
    setPDFLink(undefined);
    setPDFName(null);
    setPDFData(null);

    setUploadProgress(0);
  }

  return (
    <>
      <OuterBox>
        <OuterPaper>
          <Grid
            item
            xs={12}
            padding={"45px 5px"}
            // style={{ backgroundImage: "url(./assests/backImg.svg)" }}
          >
            <img
              src={backImg}
              style={{
                width: "230px",
                position: "absolute",
                top: "-35px",
                left: "0px",
              }}
            />
            <img
              src={calender}
              style={{
                position: "relative",
                marginBottom: "12.5px",
                zIndex: "999",
              }}
            />
            <SubheaderSection
              textAlign="start"
              title="Add Hotel Booking"
              content="some text, we will match your live image with your Aadhaar records."
              titleProps={{ fontWeight: 900, zIndex: "999" }}
              contentStyle={{ zIndex: "999" }}
            />
          </Grid>

          <Formik
            initialValues={formData}
            enableReinitialize
            onSubmit={(formData, { setSubmitting }) => {
              // if (
              //   localStorage.getItem(window.btoa(visitorData.ID)) == "false"
              // ) {
              //   localStorage.setItem("windowClosed", false);
              //   window.open(
              //     pages.InviteUserPage.path +
              //       "/" +
              //       formData?.bookingNumber +
              //       "/" +
              //       visitorData.ID +
              //       "?type=hotel&hotelid=" +
              //       formData?.hotelID +
              //       "&guestno=" +
              //       formData?.guestsCount,
              //     "",
              //     "width=400,height=600"
              //   );
              // }

              let localStorageData = localStorage;

              const digipassDataKey = Object.keys(localStorageData).filter(
                (item) => {
                  return (
                    item.includes(visitorData.ID + "_face&docs") &&
                    !item.includes("userPayload")
                  );
                }
              );

              let docs = {};
              Object.keys(localStorage).forEach((item) => {
                if (
                  item.includes(visitorData.ID) &&
                  item.includes("userPayload")
                ) {
                  docs = JSON.parse(localStorageData.getItem(item));
                }
              });

              axios({
                url: "hotel/booking",
                method: "POST",
                data: {
                  booking_id: formData?.bookingNumber,
                  guests_count: formData?.guestsCount,
                  checkin_datetime:
                    new Date(formData?.checkInDateTime).getTime() / 1000,
                  checkout_datetime:
                    new Date(formData?.checkOutDateTime).getTime() / 1000,
                  hotel_id: formData?.hotelID,
                  guest_name: formData.guestName,
                  visitor_data: { visitor_id: visitorData.ID, ...docs },
                },
              }).then((response) => {
                if (response.status) {
                  let data = [];

                  if (checkFaceData) {
                    if (!data.includes("face")) {
                      data.push("face");
                    }
                  } else {
                    const faceIndex = data.indexOf("face");
                    if (faceIndex !== -1) {
                      data.splice(faceIndex, 1);
                    }
                  }

                  if (checkContactDetails) {
                    if (!data.includes("contact-details")) {
                      data.push("contact-details");
                    }
                  } else {
                    const contactIndex = data.indexOf("contact-details");
                    if (contactIndex !== -1) {
                      data.splice(contactIndex, 1);
                    }
                  }

                  let faceData = digipassDataKey.filter((item) => {
                    return localStorage.getItem(item);
                  });

                  setLoading(true);
                  axios({
                    url: "event/visitor/consent",
                    method: "POST",
                    data: {
                      hotel_id: formData?.hotelID,
                      hotel_booking_id: response.data.booking_ref_id,
                      face_data: localStorageData[faceData[0]],
                      consent_for: data,
                    },
                    disableRedirect: true,
                  }).then((resp) => {
                    if (resp.status) {
                      navigate(
                        pages.InviteUserPage.path +
                          "/" +
                          response.data.booking_ref_id +
                          "/" +
                          visitorData.ID +
                          "?type=hotel&hotelid=" +
                          formData?.hotelID +
                          "&guestno=" +
                          formData?.guestsCount
                      );
                    }
                  });
                }
              });
            }}
          >
            {(formikProps) => {
              if (formikProps.values.otaPDF) {
                setPDFLink(formikProps.values.otaPDF);
              }

              return (
                <Fragment>
                  <Grid item xs={12} mb={"25px"}>
                    {/* <FileUpload
                      name="otaPDF"
                      label="Upload OTA PDF"
                      formikProps={formikProps}
                      onChange={({ target }) => {
                        setUploadProgress(10);
                        const file = get(target, "files[0]", "");
                        formikProps.setFieldValue("otaPDF", file.name);

                        setPDFName(file.name);
                        setUploadProgress(20);

                        setPDFLink(file);
                      }}
                      loading={loading}
                      allowedExtension=".pdf,.png,.jpg,.jpeg"
                      folderPath={
                        "mpsedc/digipass/events" + new Date().getTime() + "_"
                      }
                    /> */}
                    <Typography
                      sx={{
                        fontWeight: 700,
                        marginBottom: "7.5px",
                      }}
                    >
                      Upload OTA <span style={{ color: "red" }}>*</span>
                    </Typography>

                    <Fragment>
                      <UploadCard
                        onClick={() => {
                          !fileUpload && inputRef.current.click();
                          !fileUpload && setFileUpload(true);
                        }}
                        sx={{ flexDirection: fileUpload && "row !important" }}
                      >
                        <TextField
                          type="file"
                          inputRef={inputRef}
                          // onClick={handleFileClick}
                          onChange={({ target }) => {
                            setUploadProgress(10);
                            const file = get(target, "files[0]", "");
                            formikProps.setFieldValue("otaPDF", file.name);

                            setPDFName(file.name);
                            setUploadProgress(20);

                            setPDFLink(file);
                          }}
                          style={{ display: "none" }}
                          inputProps={{
                            accept: "*",
                          }}
                        />

                        {!fileUpload && uploadProgress < 1 && (
                          <Fragment>
                            <img
                              src={upload}
                              width={"40px"}
                              style={{ alignSelf: "center" }}
                            ></img>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                color: "#3936EF",
                                textAlign: "center",
                              }}
                            >
                              Click to upload
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontWeight: 500,
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              PDF, PNG or JPG (max. 5 MB)
                            </Typography>
                          </Fragment>
                        )}
                        {console.log(fileUpload, "fileUpload", uploadProgress)}

                        {fileUpload && uploadProgress < 100 && (
                          <Fragment>
                            <Grid item xs={3}>
                              <img
                                src={uploadBlue}
                                width={"25px"}
                                style={{ alignSelf: "center" }}
                              ></img>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography
                                sx={{
                                  fontWeight: 700,
                                }}
                              >
                                Uploading ‘{pdfName}’
                              </Typography>
                              <BorderLinearProgress
                                variant="determinate"
                                value={uploadProgress}
                                style={{ margin: "6px 0px" }}
                              />
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 500, fontSize: "12px" }}
                              >
                                {uploadProgress}% Uploaded...
                              </Typography>
                            </Grid>
                          </Fragment>
                        )}

                        {uploadProgress === 100 && (
                          <Fragment>
                            <Grid item container xs={11}>
                              {" "}
                              <Grid item xs={1.25}>
                                <img
                                  src={success}
                                  width={"25px"}
                                  style={{ alignSelf: "center" }}
                                ></img>
                              </Grid>
                              <Grid
                                item
                                xs={10.75}
                                sx={{ alignSelf: "center" }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                  }}
                                >
                                  Uploaded ‘{pdfName}’
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item xs={1}>
                              <img
                                src={deleteIcon}
                                width={"25px"}
                                style={{ alignSelf: "center" }}
                                onClick={() => {
                                  handleRemoveFile();
                                }}
                              ></img>
                            </Grid>
                          </Fragment>
                        )}
                      </UploadCard>
                    </Fragment>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      Guest Name <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      name="guestName"
                      label="Guest Name"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                      size="small"
                    ></TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      OTA <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      name="ota"
                      label="OTA"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                      size="small"
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      Guests Count <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      name="guestsCount"
                      label="Guests Count"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                      size="small"
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      Booking Number <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      name="bookingNumber"
                      label="Booking Number"
                      formikProps={formikProps}
                      disabled={pdfData === null}
                      size="small"
                    ></TextField>
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      Check-In Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <DateTimePicker
                      name="checkInDateTime"
                      label={"Check-In Date"}
                      formikProps={formikProps}
                      minDate={new Date().getTime()}
                      editState={!(pdfData === null)}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} m={"25px 0px"}>
                    <Typography sx={{ fontWeight: 700, marginBottom: "7.5px" }}>
                      Check-Out Date <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <DateTimePicker
                      name="checkOutDateTime"
                      label={"Check-Out Date"}
                      formikProps={formikProps}
                      editState={!(pdfData === null)}
                      size="small"
                    />
                  </Grid>

                  <List>
                    <ListItem sx={{ padding: "2px" }}>
                      <Checkbox
                        checked={checkFaceData}
                        onClick={() => setCheckFaceData(!checkFaceData)}
                      />
                      <ListItemText
                        primary={
                          <Typography sx={{ fontWeight: "700" }}>
                            I agree to all consent terms.
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ padding: "2px" }}>
                      <Checkbox
                        checked={checkContactDetails}
                        style={{ alignSelf: "baseline" }}
                        onClick={() =>
                          setCheckContactDetails(!checkContactDetails)
                        }
                      />
                      <ListItemText
                        primary={
                          <Typography sx={{ fontWeight: "700" }}>
                            I consent to share my contact details with event
                            exhibitors.
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={formikProps.handleSubmit}
                      disabled={
                        pdfLink === null ||
                        !Boolean(
                          Math.abs(
                            Date.now() -
                              new Date(
                                formikProps.values.checkInDateTime
                              ).getTime()
                          ) <=
                            24 * 60 * 60 * 1000
                        ) ||
                        !(
                          formikProps.values.checkInDateTime &&
                          formikProps.values.checkOutDateTime
                        )
                      }
                    >
                      Submit
                    </Button>
                  </Grid>
                </Fragment>
              );
            }}
          </Formik>
        </OuterPaper>
      </OuterBox>
    </>
  );
}
