import styled from "styled-components/macro";
import { CssBaseline } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import GlobalStyle from "../components/GlobalStyle";
import MainContent from "./application layout components/MainContent";
import Navbar from "./application layout components/Navbar";
import WebsiteLoader from "./application layout components/WebsiteLoader";
import Footer from "./application layout components/Footer";
import { useLocation } from "react-router-dom";
import MenuNavbar from "./application layout components/navbar components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { signInMember } from "../redux/slices/memberSlice";
import PoweredBy from "./application layout components/PoweredBy";

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  background: rgb(255, 255, 255);
`;

const MainContentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export default function ApplicationLayout() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { authToken, visitorData } = useSelector(
    (state) => state.memberDetails
  );
  const [mainContentLoaded, setMainContentLoaded] = useState(false);

  const sessionStorageToken = sessionStorage.getItem("authToken");

  useEffect(() => {
    if (!authToken && sessionStorageToken) {
      dispatch(
        signInMember({
          authToken: sessionStorageToken,
        })
      );
    }
  }, [authToken, sessionStorageToken]);

  const showNavbar = ["/event-booking/", "/"].filter((item) =>
    location.pathname.includes(item)
  );

  const showFooter = ["/sign-in"].filter((item) =>
    location.pathname.includes(item)
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMainContentLoaded(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  const showMenuNavbar = [
    "/event/consent",
    "/sign-in",
    "/main-gate-entry-back",
    "/profile",
  ].filter((item) => location.pathname.includes(item));

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />
      {!mainContentLoaded ? <WebsiteLoader /> : null}
      <ContentWrapper>
        <MainContentWrapper>
          {!Boolean(showNavbar.length) && <Navbar />}
          {!Boolean(showMenuNavbar.length) && <MenuNavbar />}
          {mainContentLoaded ? <MainContent /> : null}
          {/* {!Boolean(showFooter.length) && <Footer />} */}
          <PoweredBy />
        </MainContentWrapper>
      </ContentWrapper>
    </Fragment>
  );
}
