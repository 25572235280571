import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import { SubheaderSection } from "../../components";
import SuccessLogo from ".././../layouts/application layout components/assets/success.svg";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: "15px",
    // boxShadow: "rgb(31, 200, 127, 0.6) 0px 6px 10px 1px",
    background: "rgb(255, 255, 255)",
    [theme.breakpoints.up("sm")]: {
      width: "85%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
}));

const ContentBox = styled(Box)({
  padding: "30px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
});

const EventBookingSuccess = ({
  open,
  setOpen,
  SuccessMessage = "Process Completed Successfully",
  handleOnClick,
  buttonContent = "Next Step",
  CloseDialog,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={CloseDialog && (() => setOpen(false))}
      fullWidth
      maxWidth="md"
    >
      <DialogContent style={{ padding: "0px 24px" }}>
        <ContentBox>
          {/* <img src={SuccessLogo} style={{ width: "95px" }} /> */}
          <CheckCircleOutlineIcon
            style={{ fontSize: "65px", color: "#1FC87F" }}
          />
          {!SuccessMessage && (
            <SubheaderSection
              textAlign="center"
              title="Success !!"
              titleColor="#1FC87F"
            />
          )}
          {SuccessMessage && (
            <Typography
              variant="h4"
              fontWeight={700}
              style={{
                fontSize: {
                  xs: "1.25rem",
                  sm: "1.25rem",
                  md: "1.4rem",
                },
                color: "#1FC87F",
              }}
            >
              {SuccessMessage + " !!"}
            </Typography>
          )}

          {Boolean(buttonContent !== "Next Step") && (
            <Grid item sx={{ marginTop: "25px" }}>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "15px",
                  fontSize: { xs: "12px", sm: "20px" },
                  background: "#1FC87F",
                }}
                onClick={handleOnClick}
              >
                {buttonContent.toUpperCase()}{" "}
              </Button>
            </Grid>
          )}
        </ContentBox>
      </DialogContent>
    </StyledDialog>
  );
};

export default EventBookingSuccess;
