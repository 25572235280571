import styled from "styled-components";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { Grid, Typography } from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Autocomplete,
  LoadingButton,
  MandatoryNote,
  SelectField,
} from "../../../../components";
import { useAlert, useAxios } from "../../../../hooks";

const TextFieldWithCSS = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.1);

    border-radius: 12.5px;
  }

  .MuiOutlinedInput-root {
    font-size: 15px;
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .MuiInputLabel-root {
    color: rgba(0, 0, 0, 0.38);
  }

  .Mui-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
    background-color: #f5f5f5;
    font-size: 15px;
    border-radius: 12.5px;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
  }

  .MuiInputLabel-asterisk {
    color: ${(props) => (Boolean(props.required && !props.value) ? "red" : "")};
  }
`;

function ScheduleTime({ formik, setStepperLevel }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();

  const [timeOptions, setTimeOptions] = useState([]);
  const [disabledDate, setDisabledDate] = useState([]);

  const fetchDates = async (newValue) => {
    try {
      const response = await axios({
        url: `/appointment/appointment/get-time-slots?department_id=${formik.values.departmentID}&date=${newValue}&officer_id=${formik.values.officer}`,
        method: "GET",
      });

      if (response.status && response.data.length > 0) {
        const dates = response.data;
        setTimeOptions(dates);
      } else {
        dispatch(
          alert({
            type: "error",
            message: "Booking dates not available",
          })
        );
      }
    } catch (error) {
      console.error("Error in fetching available dates", error);
    }
  };

  const handleDateChange = (newValue) => {
    formik.setFieldValue("time", "");
    setTimeOptions([]);

    let time = new Date(newValue);

    fetchDates(time.getTime() / 1000);

    formik.setFieldValue("date", newValue);
  };

  useEffect(() => {
    if (formik.values.time.length > 0) {
      let arr = timeOptions.filter((time) => {
        return (
          time.slot_id === formik.values.time[0] ||
          time.slot_id === formik.values.time[formik.values.time.length - 1]
        );
      });

      formik.setFieldValue("slot1", arr[0]?.slot_time?.split(" ")[0]);
      formik.setFieldValue("slot2", arr[1]?.slot_time?.split(" ")[0]);
    }
  }, []);
  return (
    <Fragment>
      <Grid
        container
        spacing={2}
        sx={{
          marginTop: "17.5px",
        }}
      >
        {formik.values.subDepartment && (
          <Fragment>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "900", marginBottom: "15px" }}
              >
                When are you visiting?{"  "}
                <span
                  style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}
                >
                  *
                </span>
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl fullWidth variant="outlined">
                  <DatePicker
                    label={
                      <>
                        Select Date
                        <span
                          style={{
                            color: "red",
                            float: "right",
                            paddingLeft: "5px",
                          }}
                        >
                          *
                        </span>
                      </>
                    }
                    autoComplete="off"
                    placeholder="Select Date"
                    value={formik.values.date}
                    onBlur={formik.handleBlur}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextFieldWithCSS
                        {...params}
                        name={"date"}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.date && Boolean(formik.errors.date)
                        }
                        value={formik.values.date}
                      />
                    )}
                    shouldDisableDate={(date) => {
                      console.log(date, new Date());
                      return (
                        disabledDate.filter((item, index) => {
                          return date == item;
                        }).length !== 0 || date < new Date()
                      );
                    }}
                    inputFormat="dd/MM/yyyy"
                  />
                </FormControl>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "900", marginBottom: "15px" }}
              >
                Select Time Slot{"  "}
                <span
                  style={{ color: "red", "-webkit-text-stroke-width": ".5px" }}
                >
                  *
                </span>
              </Typography>
              {console.log(formik.values, "formik.values")}

              <SelectField
                label="Select Time Slot"
                name="time"
                value={formik.values.time || []}
                options={timeOptions.map((time) => ({
                  id: time.slot_id,
                  name: time.slot_time,
                }))}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.time && Boolean(formik.errors.time)}
                disabled={timeOptions.length === 0}
                multiple={true}
                xs={12}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                sx={{ fontWeight: "900", marginBottom: "15px" }}
              >
                Purpose of visit
              </Typography>

              <SelectField
                xs={12}
                label="Purpose of visit"
                name="PurposeVisit"
                value={formik.values.PurposeVisit}
                options={[
                  { id: "official", name: "Official" },
                  { id: "personal", name: "Personal" },
                  { id: "other", name: "Other" },
                ]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.PurposeVisit &&
                  Boolean(formik.errors.PurposeVisit)
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextFieldWithCSS
                fullWidth
                variant="outlined"
                label={
                  <Fragment>
                    Please fill in the reason for your appointment
                    {formik.values.PurposeVisit === "other" && (
                      <span
                        style={{
                          color: "red",
                          float: "right",
                          paddingLeft: "5px",
                        }}
                      >
                        *
                      </span>
                    )}
                  </Fragment>
                }
                placeholder="Please fill in the reason for your appointment"
                name="reason"
                value={formik.values.reason}
                onChange={(event) => {
                  if (event.target.value.length <= 250) {
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.reason && Boolean(formik.errors.reason)}
                multiline={true}
                rows={6}
                helperText={`${formik.values.reason.length} / 250 characters`}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>

      {formik.values.subDepartment && (
        <Fragment>
          <MandatoryNote />

          <LoadingButton
            onClick={() => {
              setStepperLevel(2);
            }}
          >
            Save & Proceed
          </LoadingButton>
        </Fragment>
      )}
    </Fragment>
  );
}

export default ScheduleTime;
